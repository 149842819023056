import { Avatar, Box, Flex, Image, IconButton } from "@chakra-ui/react";
import { WriterConversationMessage } from "../../types/Writer/Conversation";
import { useAuth0 } from "@auth0/auth0-react";
import ConversationMarkdown from "../TaskViewer/ConversationMarkdown";
import { copyToClipboard } from "../../utils/copyTextToClipboard.ts";

interface Props {
	message: WriterConversationMessage;
	outlineNodeName: string;
	handleMessageClick: () => void;
	handleReEditMessage: (msg: string) => void;
	handleQuoteMessage: (msg: WriterConversationMessage) => void;
}

const PaperEditorConversationMessage = ({ message, handleReEditMessage, handleMessageClick, handleQuoteMessage }: Props) => {
	const { user } = useAuth0();

	return (
		<Box
			py={message.sender === "user" ? "1" : "2"}
			borderRadius={5}
		>
			<Flex flexDir={'row'} alignItems={"flex-start"} w={"full"}>
				<Flex flexDir={"row"} alignItems={"flex-start"}>
					{message.sender !== "user" && (
						<Avatar name="RA profile" src={"/static/doenba-ico-blue.png"} w={'30px'} h={'30px'} mr={2} />
					)}
				</Flex>
				<Box flex={'1 auto'} w={'95%'}>
					<Box
						onClick={handleMessageClick}
						px={message.sender === 'user' ? 2 : 0 }
						py={'1px'}
						ml={message.sender === 'user' ? 5 : null }
						mr={message.sender === 'user' ? 2 : 5}
						borderRadius={ message.sender === 'user' ? '10px 0 10px 10px' : null}
						bg={ message.sender === 'user' ? '#c4e1ff' : null}
					>
						<ConversationMarkdown content={message.message} />
					</Box>
					{ message.sender === 'user' ? 
						<IconButton mt={1} ml={5} aria-label="edit" variant={'ghost'} icon={
							<Image
								w={"16px"}
								objectFit="cover"
								src="/static/editor/edit-2.svg"
								alt="re-edit"
							/>
						} size={'xs'} onClick={() => handleReEditMessage(message.message)}/> : 
						<Flex flexDir={'row'}>
							<IconButton mr={2} aria-label="edit" variant={'ghost'} icon={
								<Image
									w={"16px"}
									objectFit="cover"
									src="/static/editor/document-copy.svg"
									alt="copy"
								/>
							} size={'xs'} onClick={() => copyToClipboard(message.message)}/>
							<IconButton aria-label="edit" variant={'ghost'} icon={
								<Image
									w={"20px"}
									objectFit="cover"
									src="/static/editor/link-square.svg"
									alt="link"
								/>
							} size={'xs'} onClick={() => handleQuoteMessage(message)}/>
						</Flex>
					}
				</Box>
				<Flex flexDir={"row"} alignItems={"flex-start"}>
					{message.sender === "user" && (
						<Avatar name={user.name} src={user.picture} w={'30px'} h={'30px'} mr={2} />
					)}
				</Flex>
			</Flex>
		</Box>
	);
};

export default PaperEditorConversationMessage;



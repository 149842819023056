/**
 * find current edit node index
 */
import {PaperOutlineNode} from "../types/Writer/OutlineNode.ts";

export const getSelectNodeIndex = (nodeList: PaperOutlineNode[], editDom: HTMLElement | Element) => {
  const dataId = editDom?.getAttribute('data-id');
  return nodeList.findIndex(
    child => `${child.id.toString()}-${child.level.toString()}` === dataId
  );
}
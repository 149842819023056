import {PaperOutlineNode} from "../../types/Writer/OutlineNode.ts";
import {useState} from "react";
import {RephraseRequest} from "../../types/Writer/Paper.ts";
import {getSelectNodeIndex} from "../../utils/editor.ts";
/**
 * editor mouse event handler
 */

export const useEditorMouseEvent = () => {
  const [rephraseBody, setRephraseBody] = useState<RephraseRequest>(null)

  // select multi content
  const handlerSelection = (nodeList: PaperOutlineNode[]) => {
    const selection = window.getSelection();
    const selectString = selection.toString();
    const range = selection.rangeCount > 0 ? selection.getRangeAt(0) : null;
    let currentIndex = 0
    if (range) {
      const {startContainer, endContainer} = range
      const startIndex = getSelectNodeIndex(nodeList, startContainer.parentElement)
      currentIndex = startIndex
      if (selectString.length > 0 && startIndex !== -1) {
        const endIndex = getSelectNodeIndex(nodeList, endContainer.parentElement)

        const selectContents = []
        const texts = selectString.split(/\n+/)
        for (let i = startIndex; i <= endIndex; i++) {
          selectContents.push({
            id: nodeList[i].id,
            select_content: texts[i - startIndex]
          })
        }
        setRephraseBody({
          sectionNode: nodeList[0],
          selectContents,
        })
      }
    }

    return{
      selectString,
      currentIndex: currentIndex
    }
  }

  return {
    rephraseBody,
    setRephraseBody,
    handlerSelection
  }
}
import { Box, Heading, Flex, Button, Image, Text, OrderedList, IconButton, ListItem } from '@chakra-ui/react';
import React from 'react';
import { FaCheckCircle } from 'react-icons/fa';
import DonebaSpinner from '../DoenbaSpinner/DoenbaSpinner';
import { BibContent } from '../../types/Writer/BibContent';
import { DeleteIcon } from '@chakra-ui/icons';

type ResourcesUploadCardProps = {
    handleFileUploadInputElementForResourcesOnClick: () => void;
    resources: BibContent[];
    isResourcesUploading: boolean;
    inputResourcesRef: React.RefObject<HTMLInputElement>;
    handleResourcesUpload: (event: React.ChangeEvent<HTMLInputElement>) => void;
    removeResource: (index: number) => void;
}

const ResourcesUploadCard: React.FC<ResourcesUploadCardProps> = ({
    handleFileUploadInputElementForResourcesOnClick,
    resources,
    isResourcesUploading,
    inputResourcesRef,
    handleResourcesUpload,
    removeResource
}) => {
    return (
        <Box h={'350px'}>
            <Heading size={'md'} mb={1}>Upload Citation Resources</Heading>
            <Text mt={4} color={'gray'}>Upload your resource materials to Doenba Editor to help us better understand your draft. You can upload <Text as='b'>pdf. txt.</Text> and <Text as='b'>bib.</Text> file. We will add your resources to your library.</Text>
            <Flex flexDir={'row'} mt={5}>
                <Box w={'200px'} mr={5}>
                    <Box
                        as="button"
                        onClick={handleFileUploadInputElementForResourcesOnClick}
                        p={4}
                        border="1px solid"
                        borderColor="gray.300"
                        borderRadius="md"
                        textAlign="center"
                        cursor="pointer"
                        w={'160px'}
                        h={'182px'}
                        mb={5}
                    >
                        {resources.length === 0 && !isResourcesUploading &&
                            <Flex flexDir={'column'} alignItems={'center'} pb={10}>
                                <Box w={'40px'} mb={3}>
                                    <Image
                                        w={'40px'}
                                        objectFit='cover'
                                        src='/static/editor/export.png'
                                        alt='Doenba'
                                    />
                                </Box>
                                <Text color={'gray.500'}>Drag to Upload</Text>
                                <Text fontSize={12} color={'gray.400'}>Please upload bib.</Text>
                                <Button size={'sm'} bg='#1d8aff' w='80%' mt='4' color='white'>Select</Button>
                            </Flex>
                        }
                        {isResourcesUploading &&
                            <Flex flexDir={'column'} alignItems={'center'} pb={10}>
                                <Box pt={1}>
                                    <DonebaSpinner />
                                </Box>
                                <Text color={'gray.500'}>Drag to Upload</Text>
                                <Text fontSize={12} color={'gray.400'}>Please upload bib.</Text>
                                <Button size={'sm'} bg='#1d8aff' w='80%' mt='2' color='white'>Select</Button>
                            </Flex>
                        }
                        {resources.length > 0 && !isResourcesUploading &&
                            <Flex flexDir={'column'} alignItems={'center'} pb={10}>
                                <Box w={'40px'} mb={3}>
                                    <FaCheckCircle color='green' fontSize={'35px'} />
                                </Box>
                                <Text color={'gray.500'}>Drag to Upload</Text>
                                <Text fontSize={12} color={'gray.400'}>Please upload bib.</Text>
                                <Button size={'sm'} bg='#1d8aff' w='80%' mt='4' color='white'>Select</Button>
                            </Flex>
                        }
                        <input
                            ref={inputResourcesRef}
                            type="file"
                            onChange={handleResourcesUpload}
                            style={{ display: "none" }}
                        />
                    </Box>
                </Box>
                <Box w={'calc(100% - 100px)'} h={'220px'} overflow={'auto'}>
                    <OrderedList spacing={3}>
                        {resources.map((resource, index) => (
                            <ListItem key={resource.json_info.doi}>
                                <Box><Text as='b'>{resource.json_info.title} - {resource.json_info.year}</Text></Box>
                                <Flex flexDir={'row'} justifyContent={'space-between'} pr={5}>
                                    <Box><Text color={'gray.400'}>{resource.json_info.authors}</Text></Box>
                                    <Box><IconButton variant={'ghost'} size={'xs'} aria-label='remove' icon={<DeleteIcon />} onClick={() => removeResource(index)} /></Box>
                                </Flex>
                            </ListItem>
                        ))}
                    </OrderedList>
                </Box>
            </Flex>
        </Box>
    )
}

export default ResourcesUploadCard;
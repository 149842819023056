import React from 'react';
import {Text, List, ListItem, Flex, Image} from '@chakra-ui/react';
import {CheckIcon} from "@chakra-ui/icons";
import FileUpload from "./FileUpload.tsx";
import {PaperOutline, PaperOutlineNode} from "../../types/Writer/OutlineNode.ts";
import DonebaSpinner from "../DoenbaSpinner/DoenbaSpinner.tsx";

export interface StructureListItem {
  title: string
  list: PaperOutlineNode[]
  outline?: PaperOutline | object
}

export interface StructureListProps extends StructureListItem {
  index?: number
  currentIndex?: number
  createDefaultLoading?: boolean
  setCurrentIndex?: (index: number) => void
  setShowModalFooter?: (show: boolean) => void
  afterReferenceUpload?: (outline: PaperOutline) => void
}

export const StructureList: React.FC<StructureListProps> = (
  {
    title, list, currentIndex,
    index, setCurrentIndex,
    setShowModalFooter,
    afterReferenceUpload,
    createDefaultLoading
  }) => {
  const [refUpload, setRefUpload] = React.useState<boolean>(false);
  const [mockClick, setMockClick] = React.useState<boolean>(false);

  const clickHandler = () => {
    if (index <= 1 || (index === 2 && refUpload)) {
      setCurrentIndex(index)
    }
  }

  const afterUpload = (outline: PaperOutline) => {
    setRefUpload(true)
    setMockClick(false)
    afterReferenceUpload(outline)
  }
  return (
    <Flex flexDir={'column'} width={"100%"} height={'100%'} flex={1} alignItems={'start'}
          cursor={'pointer'}
          position={'relative'}
          onClick={clickHandler}>
      <Text fontSize={'16px'} fontWeight={'bold'} mb={4}>{title}</Text>
      {(index <= 1 || (index === 2 && refUpload)) &&
          <Flex width={"26px"} height={"26px"}
                alignItems={"center"} justifyContent={"center"}
                background={"transparent"}
                border={"1px solid #1D8AFF"}
                position={"absolute"}
                right={'10px'}
                top={0}
                borderRadius={6}>
              <CheckIcon color={currentIndex === index ? "#1D8AFF" : "transparent"} fontSize={12}/>
          </Flex>
      }
      {index === 2 && refUpload &&
          <Image
              position={'absolute'}
              right={'10px'}
              bottom={'10px'}
              w="22px"
              objectFit="cover"
              src="/static/editor/export.png"
              alt="Doenba"
              onClick={(event) => {
                // todo: reUpload
                event.stopPropagation()
                setMockClick(true)
                setRefUpload(false)
              }}
          />
      }
      <List width={'100%'} flex={1} gap={2} flexDir={'column'} display={'flex'}
            alignContent={'center'}
            maxHeight={'240px'}
            overflow={'auto'}
            css={{
              "&::-webkit-scrollbar": {
                width: "4px",
              },
              "&::-webkit-scrollbar-thumb": {
                background: "#9EC3E8",
                borderRadius: "2px",
              },
              "&::-webkit-scrollbar-thumb:hover": {
                background: "#2b6cb0",
              }
            }}
      >
        {index === 1 && createDefaultLoading &&
          <Flex flexDir={'column'}
                justifyContent={'center'}
                alignItems={'center'}>
              <DonebaSpinner/>
              <Text mt={5} color={'gray.400'}>Create default Structure</Text>
          </Flex>
        }
        {(index !== 2 || (index === 2 && refUpload)) && list.map((item, index) => (
          <ListItem width={'90%'} m={'auto'} key={index} textAlign="center" bg={"#E0EAFF"} p={2} borderRadius={8}>
            <Text fontWeight="medium">{item.name}</Text>
          </ListItem>
        ))}
        {index === 2 && !refUpload &&
            <FileUpload
                usage={'reference'}
                border={false}
                mockClick={mockClick}
                flexDir={'column'}
                uploadText={"Drag to Upload your reference article to see your reference structure"}
                afterUpload={afterUpload}
                setShowModalFooter={setShowModalFooter}/>}
      </List>
    </Flex>
  );
};

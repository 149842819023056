import React, { useState, useRef, useEffect } from "react";
import { Box, VStack, Spinner, Tooltip, Textarea, Flex, Image, IconButton, CloseButton } from "@chakra-ui/react";
import { WriterConversationMessage } from "../../types/Writer/Conversation";
import { WriterConversationService } from "../../services/writerConversationService";
import { ClientError } from "../../utils/clientError";
import PaperEditorConversationMessage from "./PaperEditorConversationMessage";
import {
	PaperOutlineNode
} from "../../types/Writer/OutlineNode";
import { useRecoilValue } from "recoil";
import { selectedPaperId } from "../../atoms/rootAtom";

interface PaperEditorToolPanelProps {
	outlineNodes: PaperOutlineNode[];
	selectedNodeId: number | null;
	setSelectedNodeId: (nodeId: number) => void;
}

enum TempMessageId {
	TEMP_REQUEST = -1,
	TEMP_RESPONSE = -2,
}

const PaperEditorConversationPanel: React.FC<PaperEditorToolPanelProps> = ({
	selectedNodeId,
	//outlineNodes,
	setSelectedNodeId,
}) => {
	const paperId = useRecoilValue(selectedPaperId);
	//console.log(paperId, outlineNodes, selectedNodeId)
	const [isLoading, setIsLoading] = useState(false);
	const [inputValue, setInputValue] = useState("");
	const [writerConversationMessages, setWriterConversationMessages] = useState<
		WriterConversationMessage[]
	>([]);
	// const selectedOTNodeId = useRecoilValue(selectedOutlineNodeId);
	const [quotedMsg, setQuotedMsg] = useState<WriterConversationMessage>(null)

	useEffect(() => {
		async function fetchWriterConversationMessages() {
			try {
				setWriterConversationMessages([]);
				setIsLoading(true);

				const response =
					await WriterConversationService.fetchWriterConversationMessagesForPaper(
						paperId
					);

				setWriterConversationMessages(response);
			} catch (error) {
				new ClientError(error).toast();
			} finally {
				setIsLoading(false);
			}
		}

		if (!paperId) {
			return;
		}

		fetchWriterConversationMessages();
	}, [paperId]);

	// Create a ref for the end of the messages container
	const messagesEndRef = useRef<HTMLDivElement | null>(null);

	const handlePartialBotResponse = (partialResponse: string) => {
		try {
			setWriterConversationMessages((prevMessages) => {
				const existingNewMessage = prevMessages.find(
					(msg) => msg.id === TempMessageId.TEMP_RESPONSE
				);

				if (existingNewMessage) {
					return prevMessages.map((msg) => {
						if (msg.id === TempMessageId.TEMP_RESPONSE) {
							return {
								...msg,
								message: msg.message + partialResponse,
							};
						}
						return msg;
					});
				} else {
					const newMessage: WriterConversationMessage = {
						id: TempMessageId.TEMP_RESPONSE,
						nodeId: selectedNodeId,
						paperId: paperId,
						message: partialResponse,
						sender: "bot",
					};

					return [...prevMessages, newMessage];
				}
			});
		} catch (error) {
			new ClientError(error).toast();
		}
	};

	function handleWriterConversationMessagesResponse(
		conversationPieces: WriterConversationMessage[]
	) {
		setWriterConversationMessages([...writerConversationMessages, ...conversationPieces]);
	}

	const handleSendMessage = async () => {
		if (inputValue.trim() === "") return;

		setInputValue("");

		try {
			const tempMessage: WriterConversationMessage = {
				id: TempMessageId.TEMP_REQUEST,
				paperId: paperId,
				nodeId: selectedNodeId,
				sender: "user",
				message: inputValue,
			};

			setWriterConversationMessages([...writerConversationMessages, tempMessage]);

			await WriterConversationService.addToWriterConversation(
				paperId,
				selectedNodeId,
				inputValue,
				handlePartialBotResponse,
				handleWriterConversationMessagesResponse
			);

			setQuotedMsg(null);

		} catch (error) {
			new ClientError(error).toast();
		}
	};

	// Scroll to the bottom of the chat whenever a new message is added
	useEffect(() => {
		messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
	}, [writerConversationMessages]);

	const handleReEditMessage = (msg: string) => {
		setInputValue(msg)
	}

	const handleQuoteMessage = (msg: WriterConversationMessage) => {
		setQuotedMsg(msg);
	}

	return (
		<Box h={'full'}>
			{/* { outlineNodes &&
				<Heading size={"sm"}>{`Discussion on ${outlineNodes.find(node => node.id == selectedNodeId).name}`}</Heading>
			} */}
			<Box
				overflow="hidden"
				height="full"
				display="flex"
				flexDirection="column"
				justifyContent="space-between"
			>
				{isLoading ? (
					<Box mt={5}>
						<Spinner />
					</Box>
				) : (
					<>
						<VStack spacing={4} align="stretch" overflowY="auto" flex={1} height="35vh" css={{
							"&::-webkit-scrollbar": {
								width: "4px",
							},
							"&::-webkit-scrollbar-thumb": {
								background: "#9EC3E8",
								borderRadius: "2px",
							},
							"&::-webkit-scrollbar-thumb:hover": {
								background: "#2b6cb0",
							}
						}}>
							{writerConversationMessages.map((message) => {
								const outlineNodeName = 'Testing node'
								return (
									<PaperEditorConversationMessage
										key={message.id}
										message={message}
										outlineNodeName={outlineNodeName}
										handleMessageClick={() => setSelectedNodeId(message.nodeId)}
										handleReEditMessage={handleReEditMessage}
										handleQuoteMessage={handleQuoteMessage}
									/>
								);
							})}
							<div ref={messagesEndRef} />
						</VStack>
						<Flex flexDir={'row'} w={'full'} alignItems={'flex-end'}>
							<VStack
								mr={2}
								alignItems={'flex-end'}
								flex={'1 auto'}
								spacing={2}
								mt={4}
								border={'1px solid #ccc'}
								p={1}
								bg={'white'}
								borderRadius={'5px'}>
								{quotedMsg &&
									<Flex flexDir='row'
										justifyContent={'flex-start'}
										alignItems={'center'}
										w={'full'}
										borderRadius={'5px'}
										borderBottom={'1px solid #ccc'}
										bg={'#b4c1cf'}
										p={1}>
										<Image
											w={"20px"}
											objectFit="cover"
											src="/static/editor/link-square.svg"
											alt="link"
										/>
										<Box w={'100px'} flex={'1 auto'} fontSize={'12px'} whiteSpace="nowrap"
											overflow="hidden"
											textOverflow="ellipsis">{quotedMsg.message}</Box>
										<CloseButton w={'20px'} h={'20px'} onClick={() => setQuotedMsg(null)} />
									</Flex>
								}
								<Textarea
									rows={5}
									bg={'white'}
									border={'none'}
									_focusVisible={{ borderColor: 'none' }}
									css={{
										"&::-webkit-scrollbar": {
											width: "4px",
										},
										"&::-webkit-scrollbar-thumb": {
											background: "#9EC3E8",
											borderRadius: "2px",
										},
										"&::-webkit-scrollbar-thumb:hover": {
											background: "#2b6cb0",
										}
									}}
									placeholder="Type your message..."
									value={inputValue}
									onChange={(e) => setInputValue(e.target.value)}
									onKeyDown={(e) => e.key === "Enter" && selectedNodeId && handleSendMessage()}
								/>
							</VStack>
							<Box w={'40px'}>
								<Tooltip
									label="You must select a node id first..."
									isDisabled={!selectedNodeId}
								>
									<IconButton
										aria-label="send btn"
										size={'sm'}
										colorScheme="brand"
										onClick={handleSendMessage}
										isDisabled={!selectedNodeId}
										icon={<Image
											w={"20px"}
											objectFit="cover"
											src="/static/editor/msg-sending.svg"
											alt="link"
										/>}
									>
										Send
									</IconButton>
								</Tooltip>
							</Box>
						</Flex>

					</>
				)}
			</Box>
		</Box>
	);
};

export default PaperEditorConversationPanel;



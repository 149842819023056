import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverBody,
  Box,
  Text,
} from "@chakra-ui/react";
import { FC, ReactElement, useState } from "react";

const PopoverBox: FC<{
  text: string;
  children: ReactElement;
}> = (props) => {
  const [isPopOpen, setIsPopOpen] = useState<boolean>(false);
  return (
    <Popover isOpen={isPopOpen} placement="right" offset={[0, 30]}>
      <PopoverTrigger>
        <Box
          onMouseEnter={() => setIsPopOpen(true)}
          onMouseLeave={() => setIsPopOpen(false)}
        >
          {props.children}
        </Box>
      </PopoverTrigger>
      <PopoverContent zIndex={-1}>
        <PopoverArrow />
        <PopoverBody >
          <Text textColor="black">{props.text}</Text>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};
export default PopoverBox;

import {  Button, Center, HStack, IconButton, Image} from "@chakra-ui/react";
import { Function1 } from "lodash";
import { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { AIDataType } from "../../types/Writer/AIData";

const ToolAIBox: React.FC<{
  onClickTool: Function1<string, void>;
  setAIType: Dispatch<SetStateAction<AIDataType>>
  AIType:AIDataType
}> = (props) => {
  const { t } = useTranslation();
  const AIBtns = [
    { img: '/static/ToolAI/magicpen.png', label: t("editor-editormodel-AI-Rephrase"), type:AIDataType.rephrase },
    { img: '/static/ToolAI/clipboard-tick.png', label: t("editor-editormodel-AI-Suggestions") , type:AIDataType.suggestion},
    { img: '/static/ToolAI/layer.png', label: t("editor-editormodel-AI-Reorganize") , type:AIDataType.reorganize},
  ]

  return (
    <Center>
      <HStack
        spacing={4}
        border={"1px solid #ccc"}
        width={"770px"}
        height={"48px"}
        borderRadius={"10px"}
        m={2}
      >
        <HStack
          p={4}
          spacing={4}
          borderRight={"1px solid #ccc"}
          width={"271px"}
          height={"48px"}
        >
          <IconButton
            disabled={!props.onClickTool}
            size={"xs"}
            bgColor={"transparent"}
            _hover={{ bgcolor: "transparent" }}
            icon={
              <Image
                w={"26px"}
                objectFit="cover"
                src="/static/ToolAI/b.png"
                alt="Doenba"
              />
            }
            aria-label="Bold"
            onClick={() => props.onClickTool("Bold")}
          />
          <IconButton
            disabled={!props.onClickTool}
            size={"xs"}
            bgColor={"transparent"}
            _hover={{ bgcolor: "transparent" }}
            icon={
              <Image
                w={"26px"}
                objectFit="cover"
                src="/static/ToolAI/u.png"
                alt="Doenba"
              />
            }
            aria-label="Bold"
            onClick={() => props.onClickTool("U")}
          />
          <IconButton
            disabled={!props.onClickTool}
            size={"xs"}
            bgColor={"transparent"}
            _hover={{ bgcolor: "transparent" }}
            icon={
              <Image
                w={"26px"}
                objectFit="cover"
                src="/static/ToolAI/i.png"
                alt="Doenba"
              />
            }
            aria-label="Bold"
            onClick={() => props.onClickTool("I")}
          />
          <IconButton
            disabled={!props.onClickTool}
            size={"xs"}
            bgColor={"transparent"}
            _hover={{ bgcolor: "transparent" }}
            icon={
              <Image
                w={"26px"}
                objectFit="cover"
                src="/static/ToolAI/equation.png"
                alt="Doenba"
              />
            }
            aria-label="Bold"
            onClick={() => props.onClickTool("I")}
          />
          <IconButton
            disabled={!props.onClickTool}
            size={"xs"}
            bgColor={"transparent"}
            _hover={{ bgcolor: "transparent" }}
            icon={
              <Image
                w={"26px"}
                objectFit="cover"
                src="/static/ToolAI/table.png"
                alt="Doenba"
              />
            }
            aria-label="Bold"
            onClick={() => props.onClickTool("I")}
          />
          <IconButton
            disabled={!props.onClickTool}
            size={"xs"}
            bgColor={"transparent"}
            _hover={{ bgcolor: "transparent" }}
            icon={
              <Image
                w={"26px"}
                objectFit="cover"
                src="/static/ToolAI/image.png"
                alt="Doenba"
              />
            }
            aria-label="Bold"
            onClick={() => props.onClickTool("I")}
          />
        </HStack>
        <HStack spacing={4} ml = {4}>
          {AIBtns.map((btn) => (
            <Button
              key={btn.label}
              width={'131px'}
              height={'28px'}
              value={btn.label}
              bgColor={btn.type==props.AIType?"blackalpha.900":"transparent" }
              fontWeight="bold"
              textColor={"#333333"}
              _hover={{  bgcolor:"transparent" }}
              onClick={()=>{
                props.setAIType(btn.type==props.AIType?undefined:btn.type)
              }}
              leftIcon={
                <Image
                  w={"24px"}
                  objectFit="cover"
                  src={btn.img}
                  alt="Doenba"
                />
              }
            >
              {btn.label}
            </Button>
          ))}
        </HStack>
      </HStack>
    </Center>
  );
};
export default ToolAIBox;

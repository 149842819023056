import axios from "axios";
import { UserData } from "../types/UserData";

import { BASE_URL } from ".";
import { Settings } from "../types/Settings";

const URI = 'https://auth0.doenba.ai/api/v2/users';
const url = BASE_URL + "/userData";

export class UserDataService {
	public static async getUserData(): Promise<UserData> {
		const response = await axios.get(url);
		return response.data;
	}

	public static async updateUserData(note: string): Promise<UserData> {
		const response = await axios.put(url, { note });
		return response.data;
	}

	public static async getSettings(userId: string) {
		try {
			const data = await this.getUserMetadata(userId);
			this.setLanguageLocal(data?.user_metadata.lang);

			return data?.user_metadata;
		} catch (error) {
			console.error(error);
		}
	}

	public static async getUserMetadata(userId: string) {
		try {
			return await axios.get(`${URI}/${userId}`)
				.then(results => results.data);
		} catch (error) {
			console.error(error);
		}
	}

	public static getLanguageLocal() {
		return localStorage.getItem('lang') || 'en';
	}

	public static setLanguageLocal(lang?: string) {
		localStorage.setItem('lang', (lang || 'en'));
	}

	public static async updateUserDataLang(userId: string, settings: Settings) {
		try {
			this.setLanguageLocal(settings.lang);
			return await axios.patch(`${URI}/${userId}`, { user_metadata: { ...settings } })
				.then(results => results.data);
		} catch (error) {
			console.error(error);
		}
	}
}

import { Flex, Box, Link as ChakraLink, Text, Image, Heading, Button } from "@chakra-ui/react";
import {
	Link as RouterLink
} from "react-router-dom";
import VerticalScroller from "../components/VerticalScroller/VerticalScroller";
import useAuthFunctions from "../hooks/useAuthFunctions";
import { ExternalLinkIcon } from "@chakra-ui/icons";
import VideoCarousel from "../components/VideoCarousel/VideoCarousel";
import LangSelect from "../components/NavBar/LangSelect";
import { useTranslation } from "react-i18next";
import { useSetRecoilState } from "recoil";
import { leftDrawerOpenStatus } from "../atoms/rootAtom";

function Home() {
	const { handleLogin, handleSignup } = useAuthFunctions();
	const setDrawerOpenStatus = useSetRecoilState(leftDrawerOpenStatus);
    const { t } = useTranslation();

	const Title = [
		"title1",
		"title2",
		"title3",
		"title4"
	];

	const Description = [
		"description1",
		"description2",
		"description3",
		"description4"
	];

	const handleLoginFn = () => {
		handleLogin();
	}

	const handleSignUpFn = () => {
		handleSignup();
	}

	return (
		<Flex flexDir={'row'} w={'full'} h={'full'}>
			<Box flex={'1 auto'} w={'70%'} h={'full'} p={10} >
				<Box mb={12}>
					<Image
						w={'200px'}
						objectFit='cover'
						src='/static/doenba-logo.png'
						alt='Doenba'
					/>
				</Box>

				<Box mt={12} pl={5} py={2} w={'80%'} borderLeft={'5px solid #f7ba7c'}>
					<Box color={'gray.500'} dangerouslySetInnerHTML={{__html: t('doenba-intro')}}></Box>
					<Box mt={5}>
						<Button onClick={() => setDrawerOpenStatus(true)} colorScheme="yellow" rightIcon={<ExternalLinkIcon mx='2px' />}>{t('learn-more-btn')}</Button>
					</Box>
				</Box>
				<Box my={10}>
					<VideoCarousel />
				</Box>
				<Flex flexDir={'column'} h={'calc(100% - 700px)'} w={'full'} justifyContent={'center'}>
					<Box w={'100%'}>
						<VerticalScroller texts={Title} isTitle={true} />
						<VerticalScroller texts={Description} />
					</Box>
				</Flex>
			</Box>
			<Box
				bg={'#fffed7'}
				w={'30%'} h={'full'} >
				<Flex flexDir={'row'} justifyContent={'flex-end'} mt={5} px={5}>
					<LangSelect />
				</Flex>
				<Flex flexDir={'column'} justifyContent={'center'} alignItems={'center'} w={'full'} h={'calc(100% - 100px)'}>
					<Box mb='5'>
						<Heading as="h1" size="xl" mb={5}>
							{t('get-started-label')}
						</Heading>
						<Flex flexDir={'row'}>
							<Button onClick={handleLoginFn} colorScheme="yellow" size={'lg'} mr={'2'}>
								{t('login-label')}
							</Button>
							<Button onClick={handleSignUpFn} colorScheme="red" size={'lg'} mr={'2'}>
								{t('signup-label')}
							</Button>
						</Flex>
					</Box>
				</Flex>
				<Flex w={'full'} flexDir={'row'} justifyContent={'center'} alignItems={'center'}>
					<Box mr={'5'}>
						<Text>
							<ChakraLink as={RouterLink} to={"/terms-of-use"}>
								{t('terms-of-use-label')}
							</ChakraLink>
						</Text>
					</Box>
					<Box>
						<Text>
							<ChakraLink as={RouterLink} to={"/privacy-policy"}>
							{t('privacy-policy-label')}
							</ChakraLink>
						</Text>
					</Box>
				</Flex>
			</Box>
		</Flex>
	);
}

export default Home;

/* eslint-disable no-extra-boolean-cast */
import { useEffect, useState } from "react";
import "./App.css";
import { Loading } from "./components/Loading";
import { useInterceptor } from "./hooks/useInterceptor";
import MainWrapper from "./components/MainContentWrapper/MainWrapper";
import { useAuth0 } from "@auth0/auth0-react";
import useProcessingPoller from "./hooks/useProcessingPoller";
import FileIsReadyPopUp from "./components/OracleReaderComponent/FileIsReadyPopUp";
import { AiOutlineMenuUnfold } from "react-icons/ai";
import SupportButton from "./components/SupportButton/SupportButton";
import { Box, Tooltip, IconButton, Image, Drawer, DrawerBody, DrawerContent, DrawerHeader, DrawerOverlay } from "@chakra-ui/react";
import ArticlePreviewModal from "./components/ArticlePreviewModal/ArticlePreviewModal";
import VideoPlayerComponent from "./components/VideoPlayer/VideoPlayer";
import { useTranslation } from "react-i18next";
import CompanyInfoTabs from "./components/CompanyInfoTabs/CompanyInfoTabs";
import { useRecoilState } from "recoil";
import { leftDrawerOpenStatus } from "./atoms/rootAtom";
import{ UserDataService } from "./services/userDataService";

function App() {
	const [isLoading, setIsLoading] = useState(true);
	const { isLoading: isAuthLoading } = useAuth0();
	const setInterceptor = useInterceptor();
	const { i18n } = useTranslation();
	const { user } = useAuth0();
	useProcessingPoller();
	const { t } = useTranslation();
	const [ drawerOpenStatus, setDrawerOpenStatus ] = useRecoilState(leftDrawerOpenStatus);

	useEffect(() => {
		setInterceptor();
		setIsLoading(isAuthLoading);
	}, [isAuthLoading, setInterceptor, setIsLoading]);

	useEffect(() => {
		if (!user?.sub) {
			i18n.changeLanguage(UserDataService.getLanguageLocal());
			return;
		}

		async function setSettings() {
			if (user?.sub) { 
				const userSettings = await UserDataService.getSettings(user.sub);
				if (!!userSettings?.lang) {
					i18n.changeLanguage(userSettings.lang || 'en');
				} else if (UserDataService.getLanguageLocal()) {
					i18n.changeLanguage(UserDataService.getLanguageLocal() || 'en');
				} else {
					UserDataService.setLanguageLocal('en');
					i18n.changeLanguage('en');
				}
			}
		}

		setSettings();

	}, [i18n, user?.sub]);

	if (isLoading) {
		return <Loading isLoading />;
	}

	return (
		<>
			<MainWrapper />
			<Loading />
			<Box pos={'fixed'} bottom={'10px'} left={'70px'} >
				<Tooltip label={t('mini-nav-about-label')} placement='left'>
					<IconButton onClick={() => setDrawerOpenStatus(true)} size={'md'} border={'1px solid black'} aria-label='help center' icon={<AiOutlineMenuUnfold />} />
				</Tooltip>
			</Box>
			<SupportButton application='Doenba.ai' />
			<FileIsReadyPopUp />
			<ArticlePreviewModal />
			<VideoPlayerComponent />
			<Drawer placement={'left'} onClose={() => setDrawerOpenStatus(false)} isOpen={drawerOpenStatus} size={'xl'}>
				<DrawerOverlay />
				<DrawerContent w={'full'} h={'full'}>
					<DrawerHeader w={'calc(100vw - 400px)'} bg={'white'}>
						<Box>
							<Image
								w={'105px'}
								objectFit='cover'
								src='/static/doenba-logo.png'
								alt='Doenba'
							/>
						</Box>
					</DrawerHeader>
					<DrawerBody w={'calc(100vw - 400px)'} bg={'white'}>
						<CompanyInfoTabs />
					</DrawerBody>
				</DrawerContent>
			</Drawer>
		</>
	);
}

export default App;

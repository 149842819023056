import React from 'react';
import { Box, Image, Select } from '@chakra-ui/react';

interface DoenbaOptionType {
    label: string;
    value: string | number;
}

interface DoenbaSelectProps {
    options: DoenbaOptionType[];
    value: string | number;
    onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
    variant?: string;
    borderColor?: string;
    hoverBorderColor?: string;
}

const DoenbaSelect: React.FC<DoenbaSelectProps> = ({
    options,
    value,
    onChange,
    variant = 'outline',
    borderColor = 'gray.300',
    hoverBorderColor = 'gray.400',
}) => {
    return (
        <Select
            value={value}
            size={'xs'}
            onChange={onChange}
            variant={variant}
            w={'153px'}
            borderColor={borderColor}
            p={'2px 2px 2px 5px'}
            borderRadius={'8px'}
            _hover={{ borderColor: hoverBorderColor }}
            icon={
                <Box w={'20px'} p={'2px'}>
                    <Image
                        w={'20px'}
                        objectFit="cover"
                        src="/static/arrow-down.png"
                        alt="Doenba"
                    />
                </Box>
            }
        >
            {options.map((option, index) => (
                <option key={index} value={option.value}>
                    {option.label}
                </option>
            ))}
        </Select>
    );
};

export default DoenbaSelect;

import React from 'react';
import { Center, Image } from "@chakra-ui/react"

const DonebaSpinner: React.FC = () => {
    return (
        <Center w='60px' h={'60px'}>
            <Image
                mt={1}
                w={'full'}
                objectFit='cover'
                src='/static/loading-animate.svg'
                alt='Doenba'
                transform={'scale(2)'}
            />
        </Center>
    )    
}

export default DonebaSpinner;
import React, {useEffect, useRef, useState} from "react";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Center,
  Flex,
  HStack,
  IconButton,
  Image,
  Input,
  Link as ChakraLink,
  List,
  ListItem,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Popover,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
  Spacer,
  Text,
  useDisclosure,
  useToast,
  VStack
} from "@chakra-ui/react";
import {useNavigate, useParams} from "react-router-dom";
import {PaperService} from "../services/paperService";
import {ClientError} from "../utils/clientError";
import {Paper, UpdatePaperRequest} from "../types/Writer/Paper";
import {OutlineNode, OutlineNodeType, PaperOutline, PaperOutlineNode,} from "../types/Writer/OutlineNode";
import {useTranslation} from "react-i18next";
import NodeTree from "../components/OutlineNodeTree/NodeTree";
import {formatString, getNodeRoot, getTreeNodes,} from "../types/Writer/NodesFunc";
import ToolAIBox from "../components/PaperEditor/ToolAIBox";
import EditorModelRight from "./EditorModelRight";
import {LuHeading2, LuHeading3, LuHeading4, LuHeading5, LuHeading6, LuText,} from "react-icons/lu";
import {PiDotsSixVerticalBold} from "react-icons/pi";
import EditorContent from "../components/PaperEditor/EditorContent";
import {useSetRecoilState} from "recoil";
import {citationCount, paperResources, selectedPaperId} from "../atoms/rootAtom";
import {PaperNodeSuggestion} from "../types/Writer/PaperNodeSuggestion";
import {AIData, AIDataType, SectionData} from "../types/Writer/AIData";
import {CitationWithCount} from "../types/Writer/BibContent";
import {useEditorKeyboard} from "../hooks/editor/useEditorKeyboard.hook.ts";
import {useEditorMouseEvent} from "../hooks/editor/useEditorMouseEvent.ts";
import {useUpdateEditorHook} from "../hooks/editor/useUpdateEditor.hook.ts";
import {getSelectNodeIndex} from "../utils/editor.ts";
import {each, filter} from 'lodash'
import {CheckCircleIcon} from "@chakra-ui/icons";
import {ReorganizeSectionResultVersion, ReorganizeStructureRender} from "../types/Writer/ReorganizeSectionResult.ts";

const EditorModel: React.FC = () => {
  const {t} = useTranslation();
  const toast = useToast();
  const navigate = useNavigate();
  const listRef = useRef(null);
  //----------------------------------------------------
  const {paperId} = useParams();
  const [usePaperId, setUsePaperId] = useState<string>("");
  const [paper, setPaper] = useState<Paper>(null);
  const [sectionNode, setSectionNode] = useState<PaperOutlineNode>(undefined);
  const [nodeList, setNodeList] = useState<Array<PaperOutlineNode>>([]);
  const [paperName, setPaperName] = useState<string>("");
  const [outline, setOutline] = useState<PaperOutline>(undefined);
  const [refOutline, setRefOutline] = useState<string>("111111");
  const [refTone, setRefTone] = useState<string>("example123");
  const [referencePanelOpenStatus, setReferencePanelOpenStatus] =
    useState<boolean>(false);
  const {
    isOpen: isOpenDeleteConfirmPanel,
    onOpen: onOpenDeleteConfirmPanel,
    onClose: onCloseDeleteConfirmPanel,
  } = useDisclosure();
  const {
    isOpen: isReferenceUploadPanel,
    onOpen: onOpenReferenceUploadPanel,
    onClose: onCloseReferenceUploadPanel,
  } = useDisclosure();
  const {
    isOpen: isOpenTitleInputPanel,
    onOpen: onOpenTitleInputPanel,
    onClose: onCloseTitleInputPanel,
  } = useDisclosure();
  const [deleteTipStr, setDeleteTipStr] = useState<string>("");
  const [inputPanelPos, setInputPanelPos] = useState<[number, number]>([0, 0]);
  const [newTitle, setNewTitle] = useState<string>("");
  const [newTitleLabel, setNewTitleLabel] = useState<string>("");
  const [editNode, setEditNode] = useState<PaperOutlineNode>();
  const [nodeWords, setNodeWords] = useState<number>(0);
  const [totalWords, setTotalWords] = useState<number>(0);
  // const [currentEditor, setCurrentEditor] = useState<any>(null);
  const [aiDatas, setAIDatas] = useState<AIData>(undefined);
  const [aiType, setAiType] = useState<AIDataType>(undefined);
  const [aiData, setAIData] = useState<any[]>([]);
  const [aiDataCount, setAIDataCount] = useState<number>(0);
  const [isCalculated, setIsCalculated] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const aiTypeRef = useRef<AIDataType>(undefined);
  const idRef = useRef<number>(undefined);
  const setCitationCount = useSetRecoilState(citationCount);
  const setPaperId = useSetRecoilState(selectedPaperId);
  const setResources = useSetRecoilState(paperResources);
  const {handlerSelection, rephraseBody} = useEditorMouseEvent()
  const [openTool, setOpenTool] = useState<boolean>(false);
  const [mousePos, setMousePos] = useState([0, 0]);
  const {updateContent, sameNameNodes} = useUpdateEditorHook()
  const [lastEditIndex, setLastEditIndex] = useState<number>(0);

  const lengMap = [
    t("editor-editormodel-AI-RephraseLength-0"),
    t("editor-editormodel-AI-RephraseLength-1"),
    t("editor-editormodel-AI-RephraseLength-2"),
    t("editor-editormodel-AI-RephraseLength-3"),
    t("editor-editormodel-AI-RephraseLength-4"),
  ];
  const lengPositions = [50, 90, 110, 170, 210];
  const degreeMap = [
    t("editor-editormodel-AI-Rephrasedegree-0"),
    t("editor-editormodel-AI-Rephrasedegree-1"),
    t("editor-editormodel-AI-Rephrasedegree-2"),
    t("editor-editormodel-AI-Rephrasedegree-3"),
    t("editor-editormodel-AI-Rephrasedegree-4"),
  ];
  const degreePositions = [30, 70, 120, 140, 180];
  const SuggestionDegreeMap = [
    t("editor-editormodel-AI-SuggestionDegree-0"),
    t("editor-editormodel-AI-SuggestionDegree-1"),
    t("editor-editormodel-AI-SuggestionDegree-2"),
    t("editor-editormodel-AI-SuggestionDegree-3"),
    t("editor-editormodel-AI-SuggestionDegree-4"),
  ];
  const [rephraseValue, setRephraseValue] = useState<string>(lengMap[2]);
  const [rephrasedegreeValue, setRephraseDegreeValue] = useState<string>(
    degreeMap[2]
  );
  const [suggestionDegreeValue, setSuggestionDegreeValue] = useState<string>(
    SuggestionDegreeMap[2]
  );
  const [reorganiseValue, setReorganiseValue] = useState<string>(lengMap[2]);
  const [reorganisedegreeValue, setReorganiseDegreeValue] = useState<string>(
    degreeMap[2]
  );
  const [isPopOpen, setIsPopOpen] = useState<boolean>(false);
  const [newSectionStructure, setNewSectionStructure] = useState<PaperOutlineNode>(null);
  const [structureTemp, setStructureTemp] = useState<ReorganizeStructureRender>({
    title: '',
    desc: '',
    nodeList: []
  });
  const [nodeListTemp, setNodeListTemp] = useState<PaperOutlineNode[]>([]);
  const [isReWrite, setIsReWrite] = useState<boolean>(false);
  const [isReWriteLoading, setIsReWriteLoading] = useState<boolean>(false);

  const saveAIData = (id: number, aiType: number, aiData: SectionData) => {
    const newAIDatas = aiDatas;
    newAIDatas[id][aiType] = aiData;
    setAIDatas(newAIDatas);
    if (id == idRef.current && aiType == aiTypeRef.current) {
      setIsCalculated(aiData.isCalculated);
      if (isError == false) {
        setIsError(aiData.isError);
        setAIData(aiData.data);
        setAIDataCount(aiData.count);
      }
    }
  };

  /**
   * review suggestion init
   */
  const reviewSection = async () => {
    setIsCalculated(true);
    const newAIType: AIDataType = aiType;
    const id = nodeList[0].id;
    const aiData: SectionData = {
      isCalculated: false,
      isError: false,
      data: [],
      count: 0,
    };
    try {
      const newSuggestions = await PaperService.suggestion(
        nodeList[0],
        suggestionDegreeValue
      );
      if (newSuggestions?.review_aspect?.length) {
        const isOk = newSuggestions.review_aspect.every((item) => item.score === 3)
        if (isOk) {
          aiData.data = [newSuggestions.review_aspect[0]]
        } else {
          aiData.data = filter(newSuggestions.review_aspect, item => item.score !== 3);
          aiData.count = aiData.data.length;
        }
      }
      aiData.isCalculated = false;
      aiData.isError = false;
    } catch (error) {
      aiData.isCalculated = false;
      aiData.isError = true;
      new ClientError(error).toast();
    }
    saveAIData(id, newAIType, aiData);
  };

  /**
   * check suggestion
   */
  const checkSuggestion = async (index: number) => {
    aiData[index].checking = true
    setAIData([...aiData])
    try {
      aiData[index] = await PaperService.sectionSuggestion({
        sectionNode: nodeList[0],
        suggestion_data: aiData[index],
        suggestion_level: suggestionDegreeValue
      });
      aiData[index].checking = false
      setAIData([...aiData])
      setIsCalculated(false);
      setIsError(false);
    } catch (error) {
      setIsCalculated(false);
      setIsError(true);
      new ClientError(error).toast();
    }
  }

  /**
   * reorganize
   */
  const requestReorganise = async () => {
    setIsCalculated(true)
    try {
      const result = await PaperService.reorganizeSection(nodeList[0], reorganisedegreeValue, reorganiseValue);
      const aiData: SectionData = {
        isCalculated: false,
        isError: false,
        data: [],
        count: 0
      };
      aiData.data = [result.reorganizeSectionOptionsData?.reorganized_structure_version_1];
      setIsCalculated(false)
      setAIData(aiData.data)
    } catch (error) {
      setIsCalculated(false)
      new ClientError(error).toast();
    }
  }

  /**
   * show reorganize modal, new structure
   */

  const showNewStructure = (reorganize: ReorganizeSectionResultVersion, index: number) => {
    setStructureTemp(
      {
        title: `Recommend Structure ${index + 1}`,
        desc: reorganize.reorganize_strategy_and_advantage,
        nodeList: getTreeNodes([reorganize.reorganized_structure], 1),
        structure: reorganize.reorganized_structure
      }
    );
    setIsPopOpen(true)
  }

  const rewriteSectionReq = async () => {
    setIsReWriteLoading(true)
    try {
      const index = outline.nodes?.findIndex(item => item.id === nodeList[0].id)
      const result = await PaperService.rewriteSection(outline,
        structureTemp.structure, reorganisedegreeValue, reorganiseValue, index);
      setNewSectionStructure(result.sectionNode)
      const list = getTreeNodes([result.sectionNode], 1);
      setNodeListTemp(list);
      setIsReWrite(true)
      setIsReWriteLoading(false)
    } catch (error) {
      setIsReWriteLoading(false)
      new ClientError(error).toast();
    }
  }

  const replaceOrRewrite = async () => {
    if (isReWrite) {
      const index = outline.nodes?.findIndex(item => {
        return item.id === nodeList[0].id
      })
      outline.nodes[index] = newSectionStructure
      setSectionNode(newSectionStructure)
      setIsReWriteLoading(true)
      await updateOutline(outline)
      setIsPopOpen(false)
      setIsReWriteLoading(false)
    } else {
      rewriteSectionReq()
    }
  }

  const AIPanel = (value: AIDataType) => {
    switch (value) {
      case AIDataType.rephrase:
        return (
          <VStack width={"280px"} overflow={"hidden"}>
            <Box
              mt={2}
              width={"282px"}
              paddingLeft={lengPositions[lengMap.indexOf(rephraseValue)]}
            >
              <Text
                aria-multiline={false}
                display="inline-block"
                pl={1}
                pr={1}
                border={"1px solid #ccc"}
                borderRadius={"5px"}
              >
                {rephraseValue}
              </Text>
            </Box>
            <HStack
              border={"1px solid #ccc"}
              borderRadius={"10px"}
              paddingRight={1}
              bgColor={"#FBFCFF"}
            >
              <Box
                borderRight={"1px solid #ccc"}
                borderRadius={"10px"}
                bgColor={"#FBFCFF"}
              >
                <Image
                  m={2}
                  w={"24px"}
                  objectFit="cover"
                  src="/static/ToolAI/length.png"
                  alt="length"
                />
              </Box>
              {lengMap.map((item, index) => (
                <IconButton
                  key={index.toString() + "|" + item}
                  bg={item == rephraseValue ? "#3898FF61" : "transparent"}
                  size={"sm"}
                  border={
                    item == rephraseValue ? "1px solid #3898FF" : undefined
                  }
                  borderRadius={"10px"}
                  icon={
                    <Image
                      w={item == rephraseValue ? "24px" : "6px"}
                      objectFit="cover"
                      src={
                        "/static/ToolAI/" +
                        (item == rephraseValue ? "length.png" : "dot.png")
                      }
                    />
                  }
                  aria-label={""}
                  onClick={() => {
                    setRephraseValue(item);
                  }}
                />
              ))}
            </HStack>
            <Box
              mt={2}
              width={"100%"}
              paddingLeft={
                degreePositions[degreeMap.indexOf(rephrasedegreeValue)]
              }
            >
              <Text
                aria-multiline={false}
                display="inline-block"
                pl={1}
                pr={1}
                border={"1px solid #ccc"}
                borderRadius={"5px"}
              >
                {rephrasedegreeValue}
              </Text>
            </Box>
            <HStack
              border={"1px solid #ccc"}
              borderRadius={"10px"}
              paddingRight={1}
              bgColor={"#FBFCFF"}
            >
              <Box
                borderRight={"1px solid #ccc"}
                borderRadius={"10px"}
                bgColor={"#FBFCFF"}
              >
                <Image
                  m={2}
                  w={"24px"}
                  objectFit="cover"
                  src="/static/ToolAI/degree.png"
                />
              </Box>
              {degreeMap.map((item) => (
                <IconButton
                  key={item}
                  bg={item == rephrasedegreeValue ? "#3898FF61" : "transparent"}
                  size={"sm"}
                  border={
                    item == rephrasedegreeValue
                      ? "1px solid #3898FF"
                      : undefined
                  }
                  borderRadius={"10px"}
                  icon={
                    <Image
                      w={item == rephrasedegreeValue ? "24px" : "6px"}
                      objectFit="cover"
                      src={
                        "/static/ToolAI/" +
                        (item == rephrasedegreeValue ? "degree.png" : "dot.png")
                      }
                    />
                  }
                  aria-label={""}
                  onClick={() => {
                    setRephraseDegreeValue(item);
                  }}
                />
              ))}
            </HStack>
            <Button
              bg={"transparent"}
              border={"1px solid #3898FF"}
              textColor={"#3898FF"}
              borderRadius={"10px"}
              onClick={() => {
                getPhrase()
              }}
            >
              {t("editor-editormodel-AI-Rephrase")}
            </Button>
            {isCalculated ? (
              <VStack
                mt={2}
                width={"274px"}
                height={"180px"}
                border={"1px solid #D3D7E2"}
                borderRadius={"10px"}
                bgColor={"#FBFCFF"}
              >
                <Image
                  mt={4}
                  w={"58px"}
                  h={"17px"}
                  objectFit="cover"
                  src={"/static/doenba-logo-blue.png"}
                  mr={"170px"}
                />
                <Image
                  mt={4}
                  w={"32px"}
                  objectFit="cover"
                  src="/static/loading-animate.svg"
                  transform={"scale(2)"}
                />
                <Text mt={4}>{t("editor-editormodel-Rephrasing")}</Text>
                <IconButton
                  bg="transparent"
                  size={"xs"}
                  onClick={() => {
                    setIsCalculated(false);
                    setIsError(false);
                  }}
                  icon={
                    <Image
                      w={"14px"}
                      objectFit="cover"
                      src={
                        "/static/ToolAI/" +
                        (isError ? "recalculate.svg" : "cancelCal.svg")
                      }
                      alt="cancel"
                    />
                  }
                  aria-label="cancel"
                />
              </VStack>
            ) : (
              <Accordion
                allowMultiple
                defaultIndex={Array.from(
                  {length: aiData.length},
                  (_, i) => i
                )}
              >
                {aiData.map((suggestItem, index) => (
                  <AccordionItem
                    mt={2}
                    width={"272px"}
                    key={index.toString() + "|" + suggestItem.id}
                    border={"1px solid #D3D7E2"}
                    borderRadius={"10px"}
                    p={2}
                    bgColor={"#FBFCFF"}
                  >
                    <VStack>
                      <HStack>
                        <Image
                          w={"58px"}
                          h={"17px"}
                          objectFit="cover"
                          src={"/static/doenba-logo-blue.png"}
                          mr={"130px"}
                        />
                        <AccordionButton>
                          <AccordionIcon/>
                        </AccordionButton>
                      </HStack>
                      <AccordionPanel>
                        <Text
                          width={"256px"}
                          aria-multiline={true}
                          fontSize={14}
                          fontWeight={400}
                        >
                          {suggestItem.select_content_rephrased_result}
                        </Text>
                        <Flex width={"256px"}
                              alignItems={"center"}
                              justifyContent={"start"}>
                          <IconButton
                            mr={"10px"}
                            size={"sm"}
                            bg="transparent"
                            icon={
                              <Image
                                w={"20px"}
                                objectFit="cover"
                                src={"/static/ToolAI/useContent.png"}
                              />
                            }
                            aria-label="replace"
                            onClick={() => {
                              // todo: replace过的给个ok的状态，不再replace
                              if (!suggestItem.replaced) {
                                each(nodeList, node => {
                                  if (node.id === suggestItem.id) {
                                    // node.content = node.content.replace(suggestItem.select_content,
                                    //   `<span class="replacedEffect">${suggestItem.select_content_rephrased_result}</span>`)
                                    node.content = node.content.replace(suggestItem.select_content, suggestItem.select_content_rephrased_result)
                                  }
                                })
                                suggestItem.replaced = true
                                setNodeList([...nodeList])
                                window.getSelection()?.removeAllRanges()
                              }
                            }}
                          />
                          {suggestItem.replaced && <CheckCircleIcon
                              sx={{
                                color: 'hsla(128, 71%, 50%, 0.8)'
                              }}/>}
                        </Flex>
                      </AccordionPanel>
                    </VStack>
                  </AccordionItem>
                ))}
              </Accordion>
            )}
          </VStack>
        );
      case AIDataType.suggestion:
        return (
          <VStack width={"280px"}>
            <HStack
              width={"274px"}
              height={"50px"}
              p={"8px"}
              border={"1px solid #D3D7E2"}
              borderRadius={"12px"}
              bgColor={"#FBFCFF"}
            >
              <Menu>
                <MenuButton
                  as={Button}
                  pl={1}
                  pr={1}
                  width={"156px"}
                  height={"34px"}
                  border={"1px solid #D3D7E2"}
                  fontSize={"16px"}
                  leftIcon={
                    <Image
                      w={"20px"}
                      objectFit="cover"
                      src="/static/ToolAI/degree2.svg"
                    />
                  }
                  rightIcon={
                    <Image
                      w={"20px"}
                      objectFit="cover"
                      src="/static/arrow-down.png"
                    />
                  }
                >
                  {suggestionDegreeValue}
                </MenuButton>
                <MenuList minW={"156px"}>
                  {SuggestionDegreeMap.map((item) => (
                    <MenuItem
                      as={Button}
                      key={item}
                      onClick={() => {
                        setSuggestionDegreeValue(item);
                      }}
                      fontSize={"16px"}
                      justifyContent={"flex-start"}
                      leftIcon={
                        <Image
                          position={"relative"}
                          w={"24px"}
                          height={"20px"}
                          objectFit="cover"
                          src="/static/ToolAI/degree2.svg"
                          color={"#1D8AFF"}
                        />
                      }
                    >
                      {item}
                    </MenuItem>
                  ))}
                </MenuList>
              </Menu>
              <Button
                marginLeft={"20px"}
                width={"78px"}
                height={"34px"}
                bgColor={"transparent"}
                border={"1px solid #1D8AFF"}
                textColor={"#1D8AFF"}
                fontSize={"14px"}
                onClick={reviewSection}
              >
                {t("editor-editormodel-AI-Review")}
              </Button>
            </HStack>
            {isCalculated ? (
              <VStack
                width={"272px"}
                height={"82px"}
                border={"1px solid #D3D7E2"}
                borderRadius={"12px"}
                bgColor={"#FBFCFF"}
              >
                <HStack paddingTop={"20px"}>
                  <Image
                    w={"28px"}
                    position={"relative"}
                    left={"-8px"}
                    objectFit="cover"
                    src="/static/loading-animate.svg"
                    transform={"scale(2)"}
                  />
                  <Text textColor={"#8E9ABC"} fontSize={"16px"}>
                    {t("editor-editormodel-AI-SuggestionsCalculate")}
                  </Text>
                </HStack>
                <IconButton
                  pl={"230px"}
                  bgColor={"transparent"}
                  _hover={{bgColor: "transparent"}}
                  icon={
                    <Image
                      w={"24px"}
                      objectFit="cover"
                      src={
                        "/static/ToolAI/" +
                        (isError ? "recalculate.svg" : "cancelCal.svg")
                      }
                      alt="cancel"
                    />
                  }
                  onClick={() => {
                    setIsCalculated(false);
                    setIsError(false);
                  }}
                  aria-label=""
                />
              </VStack>
            ) : (
              <Box>
                <Center
                  width={"274px"}
                  height={"64px"}
                  bgColor={"#FBFCFF"}
                  border={"1px solid #D3D7E2"}
                  borderRadius={"12px"}
                  textColor={"#8E9ABC"}
                >
                  {aiDataCount == 0
                    ? t("editor-editormodel-AI-SuggestionsTip")
                    : formatString(
                      t("editor-editormodel-AI-SuggestionsContents"),
                      aiDataCount - aiData.length,
                      aiDataCount
                    )}
                </Center>
                <Accordion
                  allowMultiple
                  defaultIndex={Array.from(
                    {length: aiData.length},
                    (_, i) => i
                  )}>
                  {aiData.map((content: PaperNodeSuggestion, index) => (
                    <AccordionItem
                      mt={2}
                      width={"272px"}
                      key={index.toString()}
                      border={"1px solid #D3D7E2"}
                      borderRadius={"12px"}
                      p={2}
                      bgColor={"#FBFCFF"}
                    >
                      <VStack>
                        <HStack>
                          <Image
                            w={"58px"}
                            h={"17px"}
                            objectFit="cover"
                            src={"/static/doenba-logo-blue.png"}
                            mr={"130px"}
                          />
                          <AccordionButton>
                            <AccordionIcon/>
                          </AccordionButton>
                        </HStack>
                        <AccordionPanel py="20px">
                          {!content.checking && content.score !== 3 && <>
                              <Text
                                  fontSize={"14px"}
                                  fontWeight={400}
                                  width={"256px"}
                                  aria-multiline={true}
                              >
                                {content.review}
                              </Text>
                              <Text
                                  fontSize={"14px"}
                                  fontWeight={700}
                                  width={"256px"}
                                  aria-multiline={true}
                              >
                                {content.suggestion}
                              </Text>
                              <HStack mt="20px">
                                  <IconButton
                                      size={"sm"}
                                      bg="transparent"
                                      icon={
                                        <Image
                                          w={"20px"}
                                          objectFit="cover"
                                          src={"/static/ToolAI/useContent.png"}
                                        />
                                      }
                                      aria-label="copy"
                                      onClick={() => {
                                        navigator.clipboard
                                          .writeText(content.suggestion)
                                          .then(() => {
                                            toast({
                                              title: "Document",
                                              description: t(
                                                "editor-editormodel-copytext"
                                              ),
                                              status: "success",
                                              duration: 1000,
                                            });
                                          })
                                          .catch((err) => {
                                            console.error("Failed to copy: ", err);
                                          });
                                      }}
                                  />
                                  <Button
                                      width={"100px"}
                                      height={"34px"}
                                      fontSize={14}
                                      textColor={"#1D8AFF"}
                                      border={"1px solid #1D8AFF"}
                                      bgColor={"transparent"}
                                      onClick={() => {
                                        checkSuggestion(index)
                                      }}
                                  >
                                    {t("editor-editormodel-AI-Suggestion-Check")}
                                  </Button>
                                  <Button
                                      width={"100px"}
                                      height={"34px"}
                                      bgColor={"#EA5050"}
                                      fontSize={14}
                                      textColor={"#FFFFFF"}
                                      _hover={{bgColor: "#EA5050"}}
                                      onClick={() => {
                                        const data = aiData.concat([]);
                                        data.splice(index, 1);
                                        setAIData(data);
                                      }}
                                  >
                                    {t("editor-editormodel-AI-Suggestion-Ignore")}
                                  </Button>
                              </HStack>
                          </>}
                          {content.checking && <>
                              <VStack paddingTop={"20px"}>
                                  <Image
                                      w={"100px"}
                                      objectFit="cover"
                                      src="/static/loading-animate.svg"
                                      transform={"scale(1.2)"}
                                  />
                                  <Text textColor={"#8E9ABC"} fontSize={"16px"}>
                                    {t("editor-editormodel-AI-Suggestion-Checking")}
                                  </Text>
                              </VStack>
                          </>}
                          {content.score === 3 && <>
                              <VStack paddingTop={"20px"}>
                                  <Image
                                      w={"80px"}
                                      objectFit="cover"
                                      src="/static/editor/check_ok.png"
                                  />
                                  <Text textColor={"#000"} fontSize={"16px"} whiteSpace="nowrap"
                                        transform={"scale(0.9)"}>
                                    {t("editor-editormodel-AI-Suggestion-Check-Ok")}
                                  </Text>
                              </VStack>
                          </>}
                        </AccordionPanel>
                      </VStack>
                    </AccordionItem>
                  ))}
                </Accordion>
                ,
              </Box>
            )}
          </VStack>
        );
      case AIDataType.reorganize:
        return (
          <VStack width={"280px"}>
            <Box
              mt={2}
              width={"282px"}
              paddingLeft={lengPositions[lengMap.indexOf(reorganiseValue)]}
            >
              <Text
                aria-multiline={false}
                display="inline-block"
                pl={1}
                pr={1}
                border={"1px solid #ccc"}
                borderRadius={"5px"}
              >
                {reorganiseValue}
              </Text>
            </Box>
            <HStack
              border={"1px solid #ccc"}
              borderRadius={"10px"}
              paddingRight={1}
              bgColor={"#FBFCFF"}
            >
              <Box
                borderRight={"1px solid #ccc"}
                borderRadius={"10px"}
                bgColor={"#FBFCFF"}
              >
                <Image
                  m={2}
                  w={"24px"}
                  objectFit="cover"
                  src="/static/ToolAI/length.png"
                  alt="length"
                />
              </Box>
              {lengMap.map((item, index) => (
                <IconButton
                  key={index.toString() + "|" + item}
                  bg={item == reorganiseValue ? "#3898FF61" : "transparent"}
                  size={"sm"}
                  border={
                    item == reorganiseValue ? "1px solid #3898FF" : undefined
                  }
                  borderRadius={"10px"}
                  icon={
                    <Image
                      w={item == reorganiseValue ? "24px" : "6px"}
                      objectFit="cover"
                      src={
                        "/static/ToolAI/" +
                        (item == reorganiseValue ? "length.png" : "dot.png")
                      }
                    />
                  }
                  aria-label={""}
                  onClick={() => {
                    setReorganiseValue(item);
                  }}
                />
              ))}
            </HStack>
            <Box
              mt={2}
              width={"100%"}
              paddingLeft={
                degreePositions[degreeMap.indexOf(reorganisedegreeValue)]
              }
            >
              <Text
                aria-multiline={false}
                display="inline-block"
                pl={1}
                pr={1}
                border={"1px solid #ccc"}
                borderRadius={"5px"}
              >
                {reorganisedegreeValue}
              </Text>
            </Box>
            <HStack
              border={"1px solid #ccc"}
              borderRadius={"10px"}
              paddingRight={1}
              bgColor={"#FBFCFF"}
            >
              <Box
                borderRight={"1px solid #ccc"}
                borderRadius={"10px"}
                bgColor={"#FBFCFF"}
              >
                <Image
                  m={2}
                  w={"24px"}
                  objectFit="cover"
                  src="/static/ToolAI/degree.png"
                />
              </Box>
              {degreeMap.map((item) => (
                <IconButton
                  key={item}
                  bg={item == reorganisedegreeValue ? "#3898FF61" : "transparent"}
                  size={"sm"}
                  border={
                    item == reorganisedegreeValue
                      ? "1px solid #3898FF"
                      : undefined
                  }
                  borderRadius={"10px"}
                  icon={
                    <Image
                      w={item == reorganisedegreeValue ? "24px" : "6px"}
                      objectFit="cover"
                      src={
                        "/static/ToolAI/" +
                        (item == reorganisedegreeValue ? "degree.png" : "dot.png")
                      }
                    />
                  }
                  aria-label={""}
                  onClick={() => {
                    setReorganiseDegreeValue(item);
                  }}
                />
              ))}
            </HStack>
            <Button
              bg={"transparent"}
              border={"1px solid #3898FF"}
              textColor={"#3898FF"}
              borderRadius={"10px"}
              onClick={requestReorganise}
            >
              {t("editor-editormodel-AI-Reorganize")}
            </Button>
            {isCalculated ? (
              <Flex
                mt={2}
                width={"274px"}
                height={"80px"}
                border={"1px solid #D3D7E2"}
                borderRadius={"10px"}
                bgColor={"#FBFCFF"}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <Image
                  w={"32px"}
                  objectFit="cover"
                  src="/static/loading-animate.svg"
                  transform={"scale(1.2)"}
                />
                <Text ml={2} color={"#888"}>{t("editor-editormodel-reorganize-loading")}</Text>
                <IconButton
                  bg="transparent"
                  size={"xs"}
                  icon={
                    <Image
                      w={"14px"}
                      objectFit="cover"
                      src={
                        "/static/ToolAI/" +
                        (isError ? "recalculate.svg" : "cancelCal.svg")
                      }
                      alt="cancel"
                    />
                  }
                  aria-label="cancel"
                />
              </Flex>
            ) : (
              <Accordion
                allowMultiple
                defaultIndex={[0]}
              >
                {aiData.map((reorganize: ReorganizeSectionResultVersion, index) => (
                  <AccordionItem
                    mt={2}
                    width={"272px"}
                    key={index.toString()}
                    border={"1px solid #D3D7E2"}
                    borderRadius={"10px"}
                    p={2}
                    bgColor={"#FBFCFF"}
                  >
                    <VStack>
                      <HStack>
                        <Image
                          w={"58px"}
                          h={"17px"}
                          objectFit="cover"
                          src={"/static/doenba-logo-blue.png"}
                          mr={"130px"}
                        />
                        <AccordionButton>
                          <AccordionIcon/>
                        </AccordionButton>
                      </HStack>
                      <AccordionPanel>
                        <Flex width={"256px"}
                              alignItems={"center"}
                              justifyContent={"start"}>
                          <Popover isOpen={isPopOpen} placement="left">
                            <PopoverTrigger>
                              <VStack  onClick={ () => showNewStructure(reorganize, index) } cursor={"pointer"}>
                                <Text
                                  width={"256px"}
                                  aria-multiline={true}
                                  fontSize={16}
                                  fontWeight={700}
                                >
                                  Recommend Structure {index + 1}
                                </Text>
                                <Text
                                  width={"256px"}
                                  aria-multiline={true}
                                  fontSize={14}
                                  fontWeight={400}
                                >
                                  {reorganize.reorganize_strategy_and_advantage}
                                </Text>
                              </VStack>
                            </PopoverTrigger>
                            <PopoverContent zIndex={-1} width={"600px"}>
                              <PopoverHeader>
                                <Image
                                  w={"144px"}
                                  h={"40px"}
                                  objectFit="cover"
                                  src={"/static/doenba-logo-blue.png"}
                                />
                              </PopoverHeader>
                              <PopoverCloseButton onClick={() => setIsPopOpen(false)}/>
                              <PopoverBody
                                maxH={"400px"}
                                minH={"100px"}
                                padding={"20px"}
                                overflow={'auto'}
                                css={{
                                  "&::-webkit-scrollbar": {
                                    width: "4px",
                                  },
                                  "&::-webkit-scrollbar-thumb": {
                                    background: "#9EC3E8",
                                    borderRadius: "2px",
                                  },
                                  "&::-webkit-scrollbar-thumb:hover": {
                                    background: "#2b6cb0",
                                  },
                                  "&::-webkit-scrollbar-track": {
                                    background: "#C9D8E7",
                                  },
                                }}
                              >
                                {!isReWrite ? (<VStack alignItems={"start"}>
                                    <Text fontSize={"20px"} fontWeight={"bold"} color={"#333333"}>
                                      {structureTemp.title}
                                    </Text>
                                    <Text fontSize={"14px"} my={"10px"}>
                                      {structureTemp.desc}
                                    </Text>
                                    <List>
                                      {structureTemp?.nodeList?.map((child, index) =>
                                        (
                                          <ListItem
                                            flex={"column"}
                                            key={index}
                                            my={"10px"}
                                          >
                                            <HStack
                                              alignItems={"flex-start"}
                                              pl={(child.level !== 2 ? (28 * (child.level -  2)).toString() : 0) + "px"}
                                            >
                                              {child.type === OutlineNodeType.Paragraph ? (
                                                <VStack alignItems={"start"}>
                                                  <Text
                                                    color={"#333333"}
                                                    fontWeight={"bold"}
                                                    fontSize={"18px"}>{'Paragraph' + child.paragraph_index}</Text>
                                                  <List
                                                    styleType={"disc"}
                                                    pl={(child.level !== 2 ? (28 * (child.level -  2)).toString() : 0) + "px"}>
                                                    {child.key_points?.map((key_point, point_index) => (
                                                      <ListItem key={point_index}>
                                                        <Text color={"#444"} fontSize={"14px"}>KeyPoint {key_point}</Text>
                                                      </ListItem>
                                                    ))}
                                                  </List>
                                                </VStack>
                                              ) : (
                                                <Text
                                                  textColor={"333333"}
                                                  width={'100%'}
                                                  minHeight={'20px'}
                                                  fontSize={"18px"}
                                                  fontWeight={"bold"}
                                                >
                                                  {child.name}
                                                </Text>)}
                                            </HStack>
                                          </ListItem>))
                                      }
                                    </List>
                                  </VStack>
                                ) : (
                                  <List>
                                    {nodeListTemp?.map((child, index) =>
                                      (
                                        <ListItem
                                          flex={"column"}
                                          key={child.id.toString() + child.name + index}
                                        >
                                          <HStack
                                            alignItems={"flex-start"}
                                            pl={(56 * (child.level - 1)).toString() + "px"}
                                          >
                                            {child.type == "paragraph" ? (
                                              <Text
                                                fontSize={"16px"}>{child.content}</Text>
                                            ) : (
                                              <Text
                                                textColor={
                                                  child.level == 1 ? "#1D8AFF" : "#8E9ABC"
                                                }
                                                width={'100%'}
                                                minHeight={'20px'}
                                                fontSize={"16px"}
                                                fontWeight={"bold"}
                                              >
                                                {child.name}
                                              </Text>)}
                                          </HStack>
                                        </ListItem>))
                                    }
                                  </List>
                                )}
                              </PopoverBody>
                              <PopoverFooter textAlign={"right"}>
                                <Button
                                  width={"100px"}
                                  height={"34px"}
                                  fontSize={14}
                                  isLoading={isReWriteLoading}
                                  textColor={"#FFFFFF"}
                                  border={"1px solid #1D8AFF"}
                                  bgColor={"#1D8AFF"}
                                  onClick={() => {
                                    replaceOrRewrite()
                                  }}
                                >
                                  {isReWrite ? t("editor-editormodel-reorganize-replace") :
                                    t("editor-editormodel-reorganize-rewrite")}
                                </Button>
                              </PopoverFooter>
                            </PopoverContent>
                          </Popover>
                        </Flex>
                      </AccordionPanel>
                    </VStack>
                  </AccordionItem>
                ))}
              </Accordion>
            )}
          </VStack>
        );
      default:
        return <p style={{color: "gray"}}>Unknown status</p>;
    }
  };

  const getPhrase = async () => {
    resetAIprogress(AIDataType.rephrase);
    setIsCalculated(true);
    const id = nodeList[0].id;
    const aiData: SectionData = {
      isCalculated: false,
      isError: false,
      data: [],
      count: 0,
    };
    let updateRephraseBody = rephraseBody
    if (!updateRephraseBody) { // default all
      updateRephraseBody = {
        sectionNode: nodeList[0],
        selectContents: [],
        writingLevel: rephrasedegreeValue,
        writingLength: rephraseValue
      }
    } else if (!rephraseBody.writingLevel) {
      updateRephraseBody = {
        ...rephraseBody,
        writingLevel: rephrasedegreeValue,
        writingLength: rephraseValue
      }
    }
    try {
      const newPhrases = await PaperService.rephraseSentences(updateRephraseBody);
      aiData.data = newPhrases.rephraseResult;
      aiData.count = aiData.data.length;
      aiData.isCalculated = false;
      aiData.isError = false;
    } catch (error) {
      aiData.isCalculated = false;
      aiData.isError = true;
      new ClientError(error).toast();
    }
    saveAIData(id, AIDataType.rephrase, aiData);
  };

  /**
 * 全量从DOM中读取文本并更新 outline 的内容
 * @param nodes 大纲节点数组
 */
function syncDOMToNodes(nodes: PaperOutlineNode[]) {
  nodes.forEach((node) => {
    // 在DOM中查找对应的 <Text> / <div> / <span> 等
    const domEl = document.querySelector(
      `[data-id="${node.id}-${node.level}"]`
    ) as HTMLElement | null;

    // 如果找到 DOM，回写文本内容
    if (domEl) {
      if (node.type === OutlineNodeType.Section) {
        node.name = domEl.textContent ?? "";
      } else if (node.type === OutlineNodeType.Paragraph) {
        node.content = domEl.textContent ?? "";
      }
    }

    // 如果还有 children，就继续递归
    if (node.children && node.children.length > 0) {
      syncDOMToNodes(node.children);
    }
  });
}

  /**
   * update editor block
   * @param newOutline
   */
  const updateOutline = async (newOutline: PaperOutline) => {
    if (newOutline == undefined) return;
    syncDOMToNodes(newOutline.nodes);

  // 2. 现在 newOutline 包含了最新的文本，才进行后续请求
  console.log("Updated Outline:", JSON.stringify(newOutline, null, 2));

    const updateRequest: UpdatePaperRequest = {
      name: paperName,
      outline: newOutline,
      authors: paper.authors,
      content: paper.content,
      abstract: paper.abstract,
      keywords: paper.keywords,
      draft: paper.draft,
      resources: paper.resources,
    };
    console.log(
      "Updated Paper:",
      JSON.stringify(newOutline, null, 2)
    );
    try {
      const newPaper = await PaperService.updatePaper(
        usePaperId,
        updateRequest
      );
      setOutline(newPaper?.outline);
      setPaperName(newPaper?.name);
      setPaper(newPaper);
      toast({
        title: "Document",
        description: "save",
        status: "success",
        duration: 1000,
      });
    } catch (error) {
      new ClientError(error).toast();
    }
  };

  const onClickTool = (btn: string) => {
    switch (btn) {
      case "Bold": { // 跨行加粗/倾斜
        const selection = window.getSelection();
        const range = selection?.getRangeAt(0);

        if (!range || range.collapsed) return;

        const selectedText = range.toString();
        const newText = `**${selectedText}**`;
        const {startContainer} = range
        const index = getSelectNodeIndex(nodeList, startContainer.parentElement)
        const oldContent = nodeList[index].content
        nodeList[index].content = oldContent.slice(0, range.startOffset) + newText + oldContent.slice(range.endOffset)
        setNodeList(nodeList)
      }
    }
  };

  const addNewNode = (
    node: PaperOutlineNode,
    index: number,
    type: OutlineNodeType,
    sameLevel?: boolean,
    content?: string
  ) => {
    const newNode: PaperOutlineNode = {
      id: outline.nextId,
      name: type === OutlineNodeType.Section ? (content ? content : ("new " + type)) : "",
      type: type,
      level:
        node.type == OutlineNodeType.Section && !sameLevel
          ? node.level + 1
          : node.level,
    };
    // 创建新para node时记录content
    if (type === OutlineNodeType.Paragraph) {
        newNode.content = content ?? "";
    }
    if (type === OutlineNodeType.Section) {
      newNode.label = newNode.name.toLowerCase()
      const sameLen = sameNameNodes(newNode.name, nodeList)
      sameLen && (newNode.label += `_${sameLen}`)
    }
    if (node.children == undefined) node.children = [];
    if (node.type == OutlineNodeType.Section && !sameLevel) {
      node.children.splice(0, 0, newNode);
    } else {
      let count = 0;
      for (let i = index - 1; i >= 0; i--) {
        if (nodeList[i].level == newNode.level) count += 1;
        if (
          nodeList[i].type == OutlineNodeType.Section &&
          nodeList[i].level < node.level
        ) {
          nodeList[i].children.splice(count + 1, 0, newNode);
          break;
        }
      }
    }
    return {
      nextId: newNode.id + 1,
      nodes: outline.nodes,
    };
  };

  const confirmDelete = (): PaperOutline => {
    for (let i = 0; i < nodeList.length; i++) {
      const index = nodeList[i].children?.indexOf(editNode);
      if (index > -1) {
        nodeList[i].children.splice(index, 1);
        return {
          nextId: outline.nextId,
          nodes: outline.nodes,
        };
      }
    }
    return undefined;
  };

  /**
   * watch input keyboard key
   */
  const useKeyboard = async (e: React.KeyboardEvent<HTMLElement>) => {
    if (e.key.toLowerCase() === 'v' && (e.ctrlKey || e.metaKey)) { // paste
      e.preventDefault();
      const clipboardText = await navigator.clipboard.readText();
      document.execCommand('insertText', false, clipboardText);
    }
    console.log('press',e.key)
    await useEditorKeyboard(e, nodeList, outline, {
      updateOutline,
      addNewNode,
      setEditNode,
      setNodeList
    })
  };

  const getSectionButton = (
    node: PaperOutlineNode,
    btnClick: () => void,
    samelevel?: boolean
  ) => {
    const level =
      node.type === OutlineNodeType.Section && !samelevel
        ? node.level + 1
        : node.level;
    if (level > 6) return undefined;
    let icon = undefined;
    switch (level) {
      case 2:
        icon = <LuHeading2/>;
        break;
      case 3:
        icon = <LuHeading3/>;
        break;
      case 4:
        icon = <LuHeading4/>;
        break;
      case 5:
        icon = <LuHeading5/>;
        break;
      case 6:
        icon = <LuHeading6/>;
        break;
      default:
        break;
    }
    return (
      <MenuItem
        onClick={btnClick}
        _hover={{bgColor: "#3898FF61"}}
        borderRadius={10}
      >
        {icon}
        <Text pl={"3px"} fontSize={14}>
          {t("editor-editormodel-midNewSubsection" + level)}
        </Text>
      </MenuItem>
    );
  };
  const getNodesWordsCount = (nodes: PaperOutlineNode[]) => {
    if (!nodes) return 0;
    let wordsCount = 0;
    nodes.forEach((node) => {
      if (!node) return;
      const str = node.type == "paragraph" ? node.content : node.name;
      if (str) {
        str.replace("\n", " ");
        wordsCount += str.split(" ").length;
      }
    });
    return wordsCount;
  };


  const resetAIprogress = (value: AIDataType) => {
    const newAIDatas = aiDatas;
    let data: SectionData = {
      isCalculated: isCalculated,
      isError: isError,
      data: aiData,
      count: aiDataCount,
    };
    newAIDatas[nodeList[0].id][aiType] = data;
    setAIDatas(newAIDatas);
    if (value) {
      // setRephraseValue(lengMap[2]);
      // setRephraseDegreeValue(degreeMap[2]);
      setSuggestionDegreeValue(SuggestionDegreeMap[2]);
      data = newAIDatas[nodeList[0].id][value];
      setIsCalculated(data.isCalculated);
      setIsError(data.isError);
      setAIData(data.data);
      setAIDataCount(data.count);
    }
    setAiType(value);
    aiTypeRef.current = value;
  };
  //----------------------------------------------------
  const leftBarWidth: string = "270px";
  //----------------------------------------------------

  // function updateCrossReferences(newNodes: PaperOutlineNode[], setCRef: (newState: CrossReference) => void) {
  //   const newCrossReferences = getCrossReferenceNodes(newNodes); // Assuming this function returns the grouped nodes
  //   setCRef({ ...newCrossReferences }); // Spread to create a new object
  // }

  useEffect(() => {
    async function fetchPaper() {
      try {
        const newPaper = await PaperService.getPaperById(usePaperId);
        setPaper(newPaper);
        setOutline(newPaper?.outline);
        setPaperName(newPaper?.name);
        setAIDatas({});
        setResources(newPaper?.resources);
        setPaperId(newPaper?.id);

        const citationCounts: CitationWithCount[] = newPaper.resources.map(resource => ({
          id: resource.id,
          count: 0
        }));

        setCitationCount(citationCounts);
      } catch (error) {
        new ClientError(error).toast();
      }
    }

    if (!usePaperId) return;
    fetchPaper();
  }, [usePaperId]);

  useEffect(() => {
    if (!outline) return;
    setTotalWords(getNodesWordsCount(getTreeNodes(outline?.nodes, 1)));
    const datas = aiDatas;
    outline.nodes.forEach((node) => {
      if (datas[node.id] == undefined) {
        datas[node.id] = [
          undefined,
          {isCalculated: false, isError: false, data: [], count: 0},
          {isCalculated: false, isError: false, data: [], count: 0},
          {isCalculated: false, isError: false, data: [], count: 0},
        ];
      }
    });
    setAIDatas(datas);
  }, [outline]);

  /**
   * when outline change, update nodeList
   */
  useEffect(() => {
    if (!outline) return;
    const list = getTreeNodes([getNodeRoot(outline?.nodes, sectionNode)], 1);
    if (nodeList.length > 0) {
      const id = nodeList[0].id;
      if (id != list[0].id) {
        resetAIprogress(undefined);
      }
    }
    idRef.current = list[0].id;
    setNodeList(list);
  }, [sectionNode]);

  useEffect(() => {
    if (!nodeList) return;
    setNodeWords(getNodesWordsCount(nodeList));
  }, [nodeList]);

  useEffect(() => {
    if (!paperId) return;
    setUsePaperId(paperId);
  }, [paperId]);

  //-------------------------------------------------------
  return (
    <>
      <HStack h={"100%"} w={"100%"} style={{overflow: "auto"}}>
        <Flex
          bg={"gray.100"}
          h={"100%"}
          flexDir={"column"}
          w={leftBarWidth}
          justifyContent={"space-between"}
          boxShadow={"md"}
          position={"relative"}
          transition={"all 0.5s ease"}
          bgColor={"#FBFCFF"}
        >
          <Flex flexDir={"column"}>
            <Center
              p={10}
              onClick={() => navigate("/editor")}
              cursor={"pointer"}
            >
              <Image
                w={"144px"}
                h={"40px"}
                objectFit="cover"
                src={"/static/doenba-logo-blue.png"}
              />
            </Center>
            <Flex
              flexDir={"column"}
              px={5}
              py={2}
              borderBottom={"1px solid #ccc"}
            >
              <Button
                width={"230px"}
                pl={5}
                pr={5}
                w={"full"}
                bgColor={referencePanelOpenStatus ? "#ADD2FC" : "transparent"}
                leftIcon={
                  <Image
                    w={"20px"}
                    objectFit="cover"
                    src="/static/editor/document-like.png"
                  />
                }
                _hover={{color: "#ADD2FC"}}
                rightIcon={
                  <Image
                    w={"20px"}
                    objectFit="cover"
                    src="/static/arrow-down.png"
                  />
                }
                onClick={() =>
                  setReferencePanelOpenStatus(!referencePanelOpenStatus)
                }
              >
                <Text m={1} as={"b"} color={"black"} fontSize={"20px"}>
                  {t("editor-editormodel-leftHeader")}
                </Text>
              </Button>
              {referencePanelOpenStatus && (
                <Box
                  px={2}
                  py={3}
                  height={"133px"}
                  mt={-1}
                  borderRight={"1px solid #ccc"}
                  borderLeft={"1px solid #ccc"}
                  borderBottom={"1px solid #ccc"}
                  borderRadius={"0 0 10px 10px"}
                  transition={"all 0.5s ease"}
                >
                  <Flex flexDir={"column"} justifyContent={"space-between"}>
                    <VStack flexDir={"row"} spacing={"10px"}>
                      <Image
                        w={"24px"}
                        objectFit="cover"
                        src="/static/editor/text.png"
                        alt="no draft"
                      />
                      <Text as={"b"}>
                        {t("editor-editormodel-leftHeaderRefOutline")}
                      </Text>
                    </VStack>
                    <HStack>
                      <Text ml={"30px"} width={"150px"} isTruncated={true}>
                        {refOutline}
                      </Text>
                      <IconButton
                        size={"xs"}
                        aria-label="replace"
                        bgColor={"whiteAlpha.50"}
                        icon={
                          <Image
                            w={"12px"}
                            objectFit="cover"
                            src="/static/editor/Property 33.png"
                            alt="no draft"
                          />
                        }
                        onClick={() => {
                          setDeleteTipStr("Referenced Structure");
                          onOpenReferenceUploadPanel();
                        }}
                      />
                      <IconButton
                        size={"xs"}
                        aria-label="remove"
                        bgColor={"whiteAlpha.50"}
                        icon={
                          <Image
                            w={"16px"}
                            objectFit="cover"
                            src="/static/editor/delete3.png"
                            alt="no draft"
                          />
                        }
                        onClick={() => {
                          setDeleteTipStr("Referenced Structure");
                          onOpenDeleteConfirmPanel();
                        }}
                      />
                    </HStack>
                    <VStack flexDir={"row"} spacing={"10px"}>
                      <Image
                        w={"24px"}
                        objectFit="cover"
                        src="/static/editor/text.png"
                        alt="no draft"
                      />
                      <Text as={"b"}>
                        {t("editor-editormodel-leftHeaderRefTone")}
                      </Text>
                    </VStack>
                    <HStack flexDir={"row"}>
                      <Text ml={"30px"} width={"150px"} isTruncated={true}>
                        {refTone}
                      </Text>
                      <IconButton
                        size={"xs"}
                        aria-label="replace"
                        bgColor={"whiteAlpha.50"}
                        icon={
                          <Image
                            w={"12px"}
                            objectFit="cover"
                            src="/static/editor/Property 33.png"
                            alt="no draft"
                          />
                        }
                        onClick={() => {
                          setDeleteTipStr("Referenced Tone");
                          onOpenReferenceUploadPanel();
                        }}
                      />
                      <IconButton
                        size={"xs"}
                        aria-label="remove"
                        bgColor={"whiteAlpha.50"}
                        icon={
                          <Image
                            w={"16px"}
                            objectFit="cover"
                            src="/static/editor/delete3.png"
                            alt="no draft"
                          />
                        }
                        onClick={() => {
                          setDeleteTipStr("Referenced Tone");
                          onOpenDeleteConfirmPanel();
                        }}
                      />
                    </HStack>
                  </Flex>
                </Box>
              )}
            </Flex>
            <Box
              mt={5}
              h={`calc(100vh - ${
                referencePanelOpenStatus ? "502px" : "373px"
              })`}
            >
              {outline && (
                <NodeTree
                  outline={outline}
                  updateOutline={updateOutline}
                  selectSection={sectionNode}
                  setSelectSection={setSectionNode}
                  height={`calc(100vh - ${
                    referencePanelOpenStatus ? "502px" : "373px"
                  })`}
                />
              )}
            </Box>
            <Button
              mt={3}
              w={"full"}
              leftIcon={
                <Image
                  w={"20px"}
                  objectFit="cover"
                  src="/static/editor/overview.png"
                  alt="no draft"
                />
              }
              bgColor={"#FBFCFF"}
              fontSize={18}
            >
              {t("editor-editormodel-leftOverview")}
            </Button>
            <Button
              bgColor={"#FBFCFF"}
              mt={3}
              w={"full"}
              fontSize={"18px"}
              leftIcon={
                <Image
                  w={"20px"}
                  objectFit="cover"
                  src="/static/editor/Export2.png"
                  alt="no draft"
                />
              }
            >
              {t("editor-editormodel-leftExport")}
            </Button>
            <Center mt={7}>
              <ChakraLink mb={5} variant="underline">
                {t("editor-editormodel-leftTutorials")}
              </ChakraLink>
            </Center>
          </Flex>
        </Flex>
        <Flex
          display={"flex"}
          flexDir={"column"}
          flex={"1 auto"}
          h={"100%"}
          overflow={"hidden"}
          maxW={`calc(100% - ${leftBarWidth} - 475px)`}
        >
          <Text h={"100px"} as={"b"} fontSize={39} paddingTop={3}>
            {paperName}
          </Text>
          <ToolAIBox
            onClickTool={onClickTool}
            setAIType={resetAIprogress}
            AIType={aiType}
          />
          <HStack
            justifyContent="flex-end"
            height={`calc(100% - 180px)`}
            overflow={"hidden"}
          >
            <Box
              height={"100%"}
              width={`calc(100% - ${aiType ? "292px" : "0px"})`}
              justifyContent="flex-start"
            >
              {sectionNode == undefined ? (
                <Box bgColor={"blackAlpha.300"} w={"100%"} h={`100%`}>
                  <Center h={"100%"} w={"100%"}>
                    <Flex
                      direction="column"
                      bgColor={"white"}
                      borderRadius={"10px"}
                      h={"304px"}
                      w={"512px"}
                      p={37}
                    >
                      <Text color={"#8E9ABC"} fontSize={"11px"}>
                        {t("editor-editormodel-noSectionTip1")}
                      </Text>
                      <Spacer/>
                      <Flex>
                        <Spacer/>
                        <Text color={"#333333"} fontSize={"11px"} mt={3}>
                          {t("editor-editormodel-noSectionTip2.1")}
                        </Text>
                        <Image
                          w={"40px"}
                          objectFit="cover"
                          src="/static/editor/add-square.png"
                        />
                        <Text color={"#333333"} fontSize={"11px"} mt={3}>
                          {t("editor-editormodel-noSectionTip2.2")}
                        </Text>
                        <Spacer/>
                      </Flex>
                      <Spacer/>

                      <Box
                        marginLeft={"100px"}
                        w={"50%"}
                        h={"50px"}
                        onClick={() => navigate("/editor")}
                        cursor={"pointer"}
                      >
                        <Image
                          objectFit="cover"
                          src={"/static/doenba-logo-blue.png"}
                        />
                      </Box>
                      <Spacer/>
                    </Flex>
                  </Center>
                </Box>
              ) : (
                <Flex
                  flexDir={"column"}
                  height={`100%`}
                  width={`calc(100%-${leftBarWidth}px-475px-${
                    aiType ? "292px" : "0px"
                  })`}
                  overflowY={"hidden"}
                >
                  <List
                    contentEditable
                    suppressContentEditableWarning={true}
                    ref={listRef}
                    height={`100%`}
                    borderRadius={"10px"}
                    overflowY={"auto"}
                    pr="16px"
                    onKeyDown={useKeyboard}
                    onMouseDown={(e) => {
                      const target = e.target as HTMLElement;
                      if (!target.classList.contains("editable-textarea")) {
                        e.preventDefault(); // prevent cursor placement
                      }
                      if (e.button !== 2) {
                        // window.getSelection()?.removeAllRanges();
                        setOpenTool(false)
                      }
                    }}
                    onMouseUp={(e) => {
                      const {selectString, currentIndex} = handlerSelection(nodeList);
                      if (selectString) { // select event
                        setOpenTool(true)
                        setMousePos([e.clientX + 20, e.clientY + 30]);
                        // setCurrentEditor(selectString)
                      }
                      console.log('lastEditIndex',lastEditIndex)
                      if (currentIndex !== lastEditIndex) {
                        // TODO: update last edit content
                        const node = nodeList[lastEditIndex]
                        const lastEditContent = document.querySelector(`[data-id="${node.id.toString() + '-' + node.level.toString()}"]`)?.textContent
                        if (node.type === OutlineNodeType.Section) {
                          if (node.name !== lastEditContent) {
                            node.name = lastEditContent
                          }
                        }
                        if (node.type === OutlineNodeType.Paragraph) {
                          if (node.content !== lastEditContent) {
                            node.content = lastEditContent
                          }
                        }
                        setNodeList([...nodeList])
                      }
                      if (currentIndex) {
                        setLastEditIndex(currentIndex)
                      }
                    }}
                    onBlur={() => {
                      const hasUpdate = updateContent(nodeList)
                      if (hasUpdate) {
                        setNodeList(nodeList)
                        updateOutline(outline)
                      }
                    }}
                    css={{
                      "&::-webkit-scrollbar": {
                        width: "4px",
                      },
                      "&::-webkit-scrollbar-thumb": {
                        background: "#9EC3E8",
                        borderRadius: "2px",
                      },
                      "&::-webkit-scrollbar-thumb:hover": {
                        background: "#2b6cb0",
                      },
                      "&::-webkit-scrollbar-track": {
                        background: "#C9D8E7",
                      },
                    }}
                    sx={{
                      border: "none",
                      outline: "none",
                      boxShadow: "none",
                    }}
                    _focus={{
                      border: "none",
                      boxShadow: "none",
                    }}
                  >
                    {nodeList?.map((child, index) =>
                      child == undefined ? undefined : (
                        <ListItem
                          flex={"column"}
                          key={child.id.toString() + child.name + index}
                        >
                          <HStack
                            alignItems={"flex-start"}
                            pl={(child.level !== 2 ? (28 * (child.level - 1)).toString() : 0) + "px"}
                          >
                            <HStack contentEditable={false} userSelect={"none"}>
                              <Menu matchWidth={true}>
                                <MenuButton
                                  as={IconButton}
                                  mt={8}
                                  size={"xs"}
                                  bgColor={"transparent"}
                                  _hover={{bgColor: "transparent"}}
                                  icon={
                                    <Image
                                      w={"16px"}
                                      userSelect={"none"}
                                      objectFit="cover"
                                      src="/static/editor/add.png"
                                    />
                                  }
                                />
                                <MenuList
                                  border={"1px solid #cccccc"}
                                  minWidth={109}
                                >
                                  <MenuItem
                                    _hover={{bgColor: "#3898FF61"}}
                                    borderRadius={10}
                                    onClick={() => {
                                      updateOutline(
                                        addNewNode(
                                          child,
                                          index,
                                          OutlineNodeType.Paragraph
                                        )
                                      );
                                    }}
                                  >
                                    <LuText/>
                                    <Text pl={"3px"} fontSize={14}>
                                      {t("editor-editormodel-midNewParagraph")}
                                    </Text>
                                  </MenuItem>
                                  {child.level != 1 &&
                                    child.type == "section" &&
                                    getSectionButton(
                                      child,
                                      () => {
                                        updateOutline(
                                          addNewNode(
                                            child,
                                            index,
                                            OutlineNodeType.Section,
                                            true
                                          )
                                        );
                                      },
                                      true
                                    )}
                                  {getSectionButton(child, () => {
                                    updateOutline(
                                      addNewNode(
                                        child,
                                        index,
                                        OutlineNodeType.Section
                                      )
                                    );
                                  })}
                                </MenuList>
                              </Menu>
                            </HStack>
                            <HStack
                              mt={1}
                              pt={7}
                              alignItems={"flex-start"}
                              width={"100%"}
                            >
                              {child.level != 1 && (
                                <IconButton
                                  size={"xs"}
                                  color={"#8E9ABC"}
                                  bgColor={"transparent"}
                                  _hover={{borderRadius: "15px"}}
                                  icon={
                                    <PiDotsSixVerticalBold
                                      style={{width: "20px", height: "20px"}}
                                    />
                                  }
                                  aria-label={""}
                                />
                              )}
                              {child.type == "paragraph" ? (
                                <EditorContent
                                  content={child.content}
                                  dataId={child.id.toString() + '-' + child.level.toString()}
                                />
                              ) : (
                                <Text
                                  data-id={child.id.toString() + '-' + child.level.toString()}
                                  className={'section-type editable-textarea'}
                                  bgColor={"transparent"}
                                  _hover={{bgColor: "transparent"}}
                                  textColor={
                                    child.level == 1 ? "#1D8AFF" : "#8E9ABC"
                                  }
                                  width={'100%'}
                                  minHeight={'20px'}
                                  fontSize={"16px"}
                                  fontWeight={"bold"}
                                >
                                  {child.name}
                                </Text>
                              )}
                            </HStack>
                            {child.type != "paragraph" &&
                              (child.level === 1 && (
                                <IconButton
                                  mt={8}
                                  ml={3}
                                  size={"xs"}
                                  bgColor={"#1D8AFF"}
                                  _hover={{bgColor: "#1D8AFF"}}
                                  icon={
                                    <Image
                                      w={"24px"}
                                      objectFit="cover"
                                      src="/static/editor/edit2.png"
                                      alt="no draft"
                                    />
                                  }
                                  onClick={(e) => {
                                    const {x, y} = (
                                      e.target as HTMLDivElement
                                    ).getBoundingClientRect();
                                    setInputPanelPos([y - 60, x - 810]);
                                    onOpenTitleInputPanel();
                                    setNewTitle(child.name);
                                    setNewTitleLabel(child.label);
                                    setEditNode(child);
                                  }}
                                  aria-label={""}
                                />
                              ))}
                          </HStack>
                        </ListItem>
                      )
                    )}
                    <Box height={`calc(100% - 180px)`}/>
                  </List>

                  {openTool && (<HStack
                    position={"fixed"}
                    left={mousePos[0]}
                    top={mousePos[1]}>
                    <VStack
                      width={"238px"}
                      height={"35px"}
                    >
                      <Button
                        border={"1px solid #3898FF"}
                        bg={"#B4D8FF"}
                        borderRadius={"10px"}
                        leftIcon={
                          <Image
                            w={"24px"}
                            objectFit="cover"
                            src="/static/ToolAI/flash.png"
                            alt="Doenba"
                          />
                        }
                        onClick={() => {
                          setOpenTool(false)
                          // setAiType(AIDataType.rephrase)
                          aiTypeRef.current = AIDataType.rephrase
                          getPhrase()
                        }}
                      >
                        {t("editor-editormodel-phraseBtn")}
                      </Button>
                    </VStack>
                  </HStack>)}
                  <Center>
                    <Center
                      border={"1px solid #ccc"}
                      borderRadius={"10px"}
                      width={"143px"}
                      height={"25px"}
                    >
                      {formatString("{0}/{1} words", nodeWords, totalWords)}
                    </Center>
                  </Center>
                </Flex>
              )}
            </Box>
            {aiType && (
              <VStack
                width={"296px"}
                height={"100%"}
                overflowY={"auto"}
                overflowX={"hidden"}
                pr={"14px"}
                css={{
                  "&::-webkit-scrollbar": {
                    width: "4px",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    background: "#9EC3E8",
                    borderRadius: "2px",
                  },
                  "&::-webkit-scrollbar-thumb:hover": {
                    background: "#2b6cb0",
                  },
                  "&::-webkit-scrollbar-track": {
                    background: "#C9D8E7",
                  },
                }}
              >
                {AIPanel(aiType)}
              </VStack>
            )}
          </HStack>
        </Flex>
        <EditorModelRight
          outline={outline}
          onAddLabel={(node: OutlineNode[]) => {
            return console.log(node);
          }}
        />
      </HStack>
      <Modal
        isOpen={isOpenDeleteConfirmPanel}
        onClose={onCloseDeleteConfirmPanel}
        closeOnOverlayClick={false}
        size={"6xl"}
      >
        <ModalOverlay
          background={"blackAlpha.100"}
          backdropFilter={"blur(1px)"}
        />
        <ModalContent
          width={551}
          height={345}
          top={200}
          border={"1px solid #ccc"}
        >
          <ModalBody p={5}>
            <Flex flexDir={"column"}>
              <Center mt={5}>
                <Image
                  w={"100px"}
                  objectFit="cover"
                  src="/static/editor/delete2.png"
                />
              </Center>
              <Center mt={30}>
                <Text textColor={"black"}>
                  {t("editor-editormodel-leftDeletePanel1")}
                  <span style={{color: "#1D8AFF"}}>{deleteTipStr}</span>
                  {t("editor-editormodel-leftDeletePanel2")}
                </Text>
              </Center>
              <Center>
                <Text textColor={"black"}>
                  {t("editor-editormodel-leftDeletePanel3")}
                </Text>
              </Center>
              <HStack p={10} spacing={150}>
                <Button
                  bgColor={"#9399AB"}
                  width={"139px"}
                  height={"48px"}
                  textColor={"white"}
                  _hover={{bgColor: "#1D8AFF", textColor: "white"}}
                  onClick={() => {
                    onCloseDeleteConfirmPanel();
                  }}
                >
                  {t("editor-editormodel-leftBtnCancel")}
                </Button>
                <Button
                  bgColor={"#9399AB"}
                  width={"139px"}
                  height={"48px"}
                  textColor={"white"}
                  _hover={{ bgColor: "#1D8AFF", textColor: "white" }}
                  onClick={() => {
                    updateOutline(confirmDelete());
                    onCloseDeleteConfirmPanel();
                  }}
                >
                  {t("editor-editormodel-leftBtnDelete")}
                </Button>
              </HStack>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
      <Modal
        isOpen={isReferenceUploadPanel}
        onClose={onCloseReferenceUploadPanel}
        closeOnOverlayClick={false}
        size={"6xl"}
      >
        <ModalOverlay
          background={"blackAlpha.100"}
          backdropFilter={"blur(1px)"}
        />
        <ModalContent
          width={730}
          height={404}
          top={200}
          border={"1px solid #ccc"}
        >
          <ModalBody p={5}>
            <Flex flexDir={"column"}>
              <Text as="b" fontSize={"16px"}>
                {t("editor-editormodel-leftHeader")}
              </Text>
              <Text textColor={"#8E9ABC"}>
                {t("editor-editormodel-leftUploadRef")}
              </Text>
              <Center m={5}>
                <Flex
                  width={"316px"}
                  height={"192px"}
                  flexDir={"column"}
                  border={"1px solid #ccc"}
                >
                  <Center mt={3}>
                    <Image
                      w={"40px"}
                      objectFit="cover"
                      src="/static/editor/export.png"
                    />
                  </Center>
                  <Center>
                    <Text as="b" textColor={"#8E9ABC"}>
                      {t("editor-editormodel-leftTipUpload")}
                    </Text>
                  </Center>
                  <Center>
                    <Text textColor={"#8E9ABC"}>
                      {t("editor-editormodel-leftTipUploadFile")}
                    </Text>
                  </Center>
                  <Center>
                    <Button
                      m={3}
                      w="100px"
                      h="34px"
                      bgColor={"#1D8AFF"}
                      textColor={"white"}
                      _hover={{ color: "#1D8AFF", textColor: "white" }}
                    >
                      {t("editor-editormodel-leftBtnSelect")}
                    </Button>
                  </Center>
                </Flex>
              </Center>
              <Button
                variant="outline"
                ml={550}
                mt={3}
                w="139px"
                h="48px"
                color={"#1D8AFF"}
                borderColor={"#1D8AFF"}
                onClick={() => {
                  setRefOutline("111");
                  setRefTone("112");
                  onCloseReferenceUploadPanel();
                }}
              >
                {t("editor-editormodel-leftBtnApply")}
              </Button>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
      <Modal
        isOpen={isOpenTitleInputPanel}
        onClose={onCloseTitleInputPanel}
        closeOnOverlayClick={false}
        size={"6xl"}
      >
        <ModalOverlay background="transparent" />
        <ModalContent
          width={373}
          height={135}
          top={inputPanelPos[0]}
          left={inputPanelPos[1]}
          border={"1px solid #ccc"}
        >
          <ModalBody p={1}>
            <VStack p={2}>
              <Flex width={"100%"}>
                <Text width={"60px"} fontSize={16} align={"left"} as="b">
                  Title:
                </Text>
                <Input
                  width={"283px"}
                  height={"27px"}
                  placeholder={"New Section New Section"}
                  defaultValue={newTitle}
                  borderColor={newTitle.length > 0 ? undefined : "red.300"}
                  onBlur={(e) => setNewTitle(e.target.value)}
                />
              </Flex>
              <Flex width={"100%"}>
                <Text width={"60px"} fontSize={16} align={"left"} as="b">
                  Label:
                </Text>
                <Input
                  width={"283px"}
                  height={"27px"}
                  placeholder={"Use in Latex"}
                  defaultValue={newTitleLabel}
                  onBlur={(e) => setNewTitleLabel(e.target.value)}
                />
              </Flex>
              <HStack pt={3} spacing={170}>
                <Button
                  border={"3px solid #1D8AFF"}
                  width={"70px"}
                  height={"25px"}
                  fontSize={14}
                  textColor={"#1D8AFF"}
                  onClick={() => {
                    onCloseTitleInputPanel();
                  }}
                >
                  {t("editor-editormodel-treeInputCancel")}
                </Button>
                <Button
                  border={"3px solid #1D8AFF"}
                  width={"70px"}
                  height={"25px"}
                  fontSize={14}
                  textColor={"#1D8AFF"}
                  onClick={() => {
                    if (newTitle.length > 0) {
                      onCloseTitleInputPanel();
                      editNode.name = newTitle;
                      editNode.label = newTitleLabel;
                      updateOutline(outline);
                    }
                  }}
                >
                  {t("editor-editormodel-treeInputSave")}
                </Button>
              </HStack>
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default EditorModel;

// Writer.tsx
import {
  Box,
  Flex,
  Text,
  Wrap,
  WrapItem,
  Heading,
  Modal,
  Image,
  useDisclosure,
  Button,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
  Card,
  CardBody,
  Center,
  Container,
  HStack,
  VStack, FormControl, FormLabel, Textarea
} from '@chakra-ui/react';
import React, {useEffect, useState} from 'react';
import PaperCard from '../components/PaperCard/PaperCard';
import {useNavigate} from 'react-router-dom';
import {BASE_URL, OutlineService} from '../services';
import {ClientError} from '../utils/clientError';
import {PaperService} from '../services/paperService';
import {useAuth0} from "@auth0/auth0-react";
import {Paper} from '../types/Writer/Paper';
import {EditIcon, CheckIcon} from '@chakra-ui/icons';
import SideNav from '../components/NavBar/SideNav';
import {
  PaperOutline,
} from '../types/Writer/OutlineNode';
// import { MathJax } from 'better-react-mathjax';
// import { OutlineNode } from '../types/Writer/OutlineNode';
import SearchBar from '../components/SearchBar/SearchBar';
import LangSelectV2 from '../components/NavBar/LangSelectV2';
import UserMenu from '../components/NavBar/Components/UserMenu';
import TemplateService from '../services/TemplateService';
import PreviewModalPanel from '../components/PreviewModalPanel/PreviewModalPanel';
import DoenbaSelect from '../components/DoenbaSelect/DoenbaSelect';
import DocumentService from '../services/DocumentService';
import FileUpload from "../components/PaperEditor/FileUpload.tsx";
import {StructureList, StructureListItem} from "../components/PaperEditor/StructureList.tsx";

interface DocumentWithThumbnail {
  thumbnailUrl?: string;
}

export enum NewIntent {
  NORMAL_DRAFT = 'NORMAL_DRAFT',
  NO_DRAFT = 'NO_DRAFT'
}

interface DocumentExtended extends Paper, DocumentWithThumbnail {
}

const Writer: React.FC = () => {
  const {isOpen: isPaperViewerOpen, onOpen: onPaperViewerOpen, onClose: onPaperViewerClose} = useDisclosure();
  const navigate = useNavigate();
  // const [_outlines, setOutlines] = useState<Outline[]>([]);
  const [papers, setPapers] = useState<Paper[]>([]);
  const [selectedPaper, setSelectedPaper] = useState<Paper>(null);
  const toast = useToast();
  const {isOpen: isNewDocumentOpen, onOpen: onNewDocumentOpen, onClose: onNewDocumentClose} = useDisclosure();
  const [newIntent, setNewIntent] = useState<NewIntent>(NewIntent.NO_DRAFT);
  const [paperTitle, setPaperTitle] = useState<string>('');
  const [writingArea, setWritingArea] = useState<string>('');
  const {user, isAuthenticated} = useAuth0();
  const [sortOption, setSortOption] = useState<string>("modifiedDate");

  const [templateIndex, setTemplateIndex] = useState<number>(-1);
  const [templates, setTemplates] = useState<DocumentExtended[]>([]);
  const {isOpen: isOpenPreviewPanel, onOpen: onOpenPreviewPanel, onClose: onClosePreviewPanel} = useDisclosure();
  const [currentStep, setCurrentStep] = useState<number>(1)
  const [structures, setStructures] = useState<StructureListItem[]>([
    {
      title: 'Your Structure',
      list: [],
      outline: {}
    },
    {
      title: 'Default Structure by  Doenba',
      list: [],
      outline: {}
    },
    {
      title: 'Reference Structure',
      list: [],
      outline: {}
    }
  ])
  const [currentStructure, setCurrentStructure] = useState<number>(0)
  const [showModalFooter, setShowModalFooter] = useState<boolean>(true)
  const [createDefaultLoading, setCreateDefaultLoading] = useState<boolean>(true)
  // next step disabled
  const [ nextDisabled, setNextDisabled ] = useState<boolean>(true)
  const [ uploadSuccess, setUploadSuccess ] = useState<boolean>(false)
  const [ startLoading, setStartLoading ] = useState<boolean>(false)
  // first step draft outline
  const [ draftOutline, setDraftOutline ] = useState<PaperOutline>(null)

  // useEffect(() => {
  //   async function getOutlines() {
  //     try {
  //       const outlines = await OutlineService.getOutlines();
  //       setOutlines(outlines);
  //     } catch (error) {
  //       new ClientError(error).toast();
  //     }
  //   }
  //
  //   getOutlines();
  // }, [])

  useEffect(() => {
    async function getPapers() {
      try {
        const papers = await PaperService.getPapers();
        setPapers(papers);
      } catch (error) {
        new ClientError(error).toast();
      }
    }

    getPapers();
  }, [])

  useEffect(() => {
    async function getTemplates() {
      try {
        const newTemplates: DocumentExtended[] = await TemplateService.getAllTemplates();
        setTemplates(newTemplates);

        for (const template of newTemplates) {
          if (!template.thumbnailS3Path) {
            continue;
          }

          const thumbnail = await DocumentService.getThumbnailById(template.id).catch(() => null);
          if (!thumbnail) {
            continue;
          }

          template.thumbnailUrl = URL.createObjectURL(thumbnail);
        }
        setTemplates(newTemplates);
      } catch (error) {
        new ClientError(error).toast();
      }
    }

    getTemplates();
  }, [])

  /**
   * create paper
   * @param outline
   */
  const createPaperByOutline = async (outline: PaperOutline) => {
    try {
      const newPaper = await PaperService.createPaper({
        name: paperTitle ? paperTitle : 'Untitled',
        authors: user.user_metadata.first_name && user.user_metadata.last_name ? `${user.user_metadata.first_name} ${user.user_metadata.last_name}` : user.name,
        outline: outline,
        abstract: '',
        keywords: '',
        draft: '',
        resources: [],
        toneReference: {}
      });
      navigate(`/editor/${newPaper.id}`);
    } catch (error) {
      new ClientError(error).toast();
    }
  }

  const continueEditing = (paper_id) => {
    console.log('continue to editing ', paper_id);
    navigate(`/editor/${paper_id}`);
  }

  const deletePaper = async (paperId) => {
    const originalPapers = papers;
    try {
      const newPapers = papers.filter(paper => paper.id !== paperId);
      setPapers(newPapers);
      await PaperService.deletePaper(paperId);
      toast({
        title: `Ppaer has been deleted.`,
        description: ``,
        status: "success",
        duration: 3000,
      })
    } catch (error) {
      new ClientError(error).toast();
      setPapers(originalPapers);
    }
  }

  const viewPaper = async (paperId: string) => {
    const filteredPaper = papers.find(paper => paper.id === paperId);
    setSelectedPaper(filteredPaper);
    onPaperViewerOpen();
  }

  const newDocumentOnClick = () => {
    onNewDocumentOpen();
    setNewIntent(NewIntent.NO_DRAFT);
  }

  const onNewDocumentOnClose = () => {
    onNewDocumentClose();
    setPaperTitle('');
    setWritingArea('');
  }

  const onDraftFinish = (outline: PaperOutline): void => {
    setNextDisabled(false)
    setDraftOutline(outline)
    setUploadSuccess(true)
    // Promise.resolve().then(() => {
    //   nextHandler()
    // })
  }

  const handleSortingChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedOption = event.target.value;
    setSortOption(selectedOption);

    const sortedPapers = [...papers].sort((a, b) => {
      if (selectedOption === "updatedAt") {
        return new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime();
      } else if (selectedOption === "createdAt") {
        return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
      } else if (selectedOption === "name") {
        return a.name.localeCompare(b.name);
      }
      return 0;
    });

    setPapers(sortedPapers);
  };

  const intentOnClick = (indent: NewIntent) => {
    setNewIntent(indent);
  }

 const updateStructureItem = (index: number, outline?: PaperOutline) => {
   const updatedStructures = [...structures];
   updatedStructures[index].list = outline?.nodes || []
   updatedStructures[index].outline = outline || {}
   setStructures([...updatedStructures])
 }
  /**
   * next handler
   */
  const nextHandler = async () => {
    if (currentStep === 1) {
      updateStructureItem(1)
      setCurrentStep(2)
      setCurrentStructure(0)
      if (newIntent === NewIntent.NO_DRAFT) {
        try {
          setCreateDefaultLoading(true)
          const res = await OutlineService.createDefaultOutlineByWrite({writingArea})
          updateStructureItem(1, res.outline)
          setCreateDefaultLoading(false)
        } catch (error) {
          setCreateDefaultLoading(false)
          new ClientError(error).toast();
        }
      } else {
        updateStructureItem(0, draftOutline)
        try {
          setCreateDefaultLoading(true)
          const res = await OutlineService.createDefaultOutlineByDraft({outline: draftOutline})
          updateStructureItem(1, res.outline)
          setCreateDefaultLoading(false)
        } catch (error) {
          setCreateDefaultLoading(false)
          new ClientError(error).toast();
        }
      }
    } else {
      // start
      try {
        setStartLoading(true)
        const res = await OutlineService.mergeOutline({
          outline: draftOutline || {},
          selectedOutline: structures[currentStructure].outline as PaperOutline
        })
        createPaperByOutline(res.outline)
      } catch (error) {
        setStartLoading(false)
        new ClientError(error).toast();
      }
    }
  }

  /**
   * after upload reference, update structure[2]
   */
  const afterReferenceUpload = (outline: PaperOutline) => {
    updateStructureItem(2, outline)
  }

  return (
    <Flex flexDir={'row'} w={'full'} h={'full'} className='main-bg'>
      <SideNav/>
      <Flex flexDir={'column'} w={'full'} h={'full'}>
        <Flex flexDir={'row'} w={'full'} h={'90px'} justify="flex-end" align='center' px={5}>
          <Box mr={'30px'}>
            <LangSelectV2/>
          </Box>
          {isAuthenticated && user && (
            <UserMenu user={user}/>
          )}
        </Flex>
        <Container minW={'75%'}>
          <SearchBar/>
          <Flex flexDir={'column'} h={'full'} px={10} overflow={'auto'} w={'full'}>
            <Box bgColor={'rgba(255, 255, 255, 0.6)'} borderRadius={'25px'} w={'full'} border={'1px solid white'}>
              <Heading p={5} size={'md'} mb={3}>Start a new document</Heading>
              <Wrap my={5} overflow={'auto'} w={'full'} p={5} pt={0} display={'flex'}
                    justifyContent={papers.length <= 0 ? 'center' : 'flex-start'}>
                <WrapItem display={'flex'} flexDir={'column'} px={6}>
                  <Card w={'128px'} h={'157px'} cursor={'pointer'} _hover={{boxShadow: '2px 2px 5px #d3d7e2'}}
                        border={'1px solid #d3d7e2'}>
                    <CardBody onClick={newDocumentOnClick}>
                      <Center w={'full'} h={'full'} display={'flex'} flexDir={'column'}>
                        <Box>
                          <Image
                            w={'55px'}
                            objectFit='cover'
                            src='/static/doenba-ico-blue.png'
                            alt='Doenba'
                          />
                        </Box>
                      </Center>
                    </CardBody>
                  </Card>
                  <Box mt={2} w={'full'} textAlign={'center'}>
                    <Text fontSize={14}>
                      Create
                    </Text>
                  </Box>
                </WrapItem>
                {papers.length > 0 && templates.map((template, i) => (
                  <WrapItem key={template.id} display={'flex'} flexDir={'column'} px={6}>
                    <Card w={'128px'} h={'157px'} cursor={'pointer'} _hover={{boxShadow: '2px 2px 5px #d3d7e2'}}
                          border={'1px solid #d3d7e2'}>
                      <CardBody onClick={() => {
                        setTemplateIndex(i);
                        onOpenPreviewPanel()
                      }}>
                        <Center w={'full'} h={'full'} display={'flex'} flexDir={'column'}>
                          <Image
                            w={'55px'}
                            objectFit='cover'
                            src={template.thumbnailUrl || '/media/paper_preview.png'}
                            alt='template draft'
                          />
                        </Center>
                      </CardBody>
                    </Card>
                    <Box mt={2} w={'full'} textAlign={'center'}>
                      <Text fontSize={14}>
                        {template.name}
                      </Text>
                    </Box>
                  </WrapItem>
                ))}
              </Wrap>
              {papers.length <= 0 &&
                  <Wrap borderTop={'1px solid #d3d7e2'} my={5} overflow={'auto'} w={'full'} p={5}>
                      <WrapItem>
                          <HStack spacing='21px'>
                            {templates.map((template, i) => (
                              <Card key={template.id} w={'150px'} h={'200px'} cursor={'pointer'}
                                    _hover={{boxShadow: '2px 2px 5px #d3d7e2'}} border={'1px solid #d3d7e2'}>
                                <CardBody onClick={() => {
                                  setTemplateIndex(i);
                                  onOpenPreviewPanel()
                                }}>
                                  <Center w={'full'} h={'full'} display={'flex'} flexDir={'column'}>
                                    <Image
                                      w={'55px'}
                                      objectFit='cover'
                                      src={BASE_URL + template['snapshotS3Path']}
                                      alt='template draft'
                                    />
                                  </Center>
                                </CardBody>
                                <Box mt={2} w={'full'} textAlign={'center'}>
                                  <Text fontSize={14}>
                                    {template.name}
                                  </Text>
                                </Box>
                              </Card>
                            ))}
                          </HStack>
                      </WrapItem>
                  </Wrap>
              }
            </Box>

            {papers.length > 0 &&
                <Box mt={5} bgColor={'rgba(255, 255, 255, 0.6)'} p={5} borderRadius={'25px'} pb={12} w={'full'}
                     border={'1px solid white'}>
                    <Flex flexDir={'row'} justifyContent={'space-between'} alignItems={'center'} pb={3}>
                        <Heading size={'md'} flex={'1 auto'}>Recent Documents</Heading>
                        <DoenbaSelect
                            options={[
                              {label: 'Modified Date', value: 'updatedAt'},
                              {label: 'Opened Date', value: 'createdAt'},
                              {label: 'Title', value: 'name'},
                            ]}
                            value={sortOption}
                            onChange={handleSortingChange}
                            variant="outline"
                            borderColor="gray.300"
                            hoverBorderColor="gray.400"
                        />
                    </Flex>
                    <Wrap mt={5} w={'full'} overflow={'auto'} h={'35vh'}>
                      {papers.map(paper => (
                        <WrapItem key={paper.id} px={4} pb={6}>
                          <PaperCard
                            paper={paper}
                            viewPaper={() => viewPaper(paper.id)}
                            continueEditing={() => continueEditing(paper.id)}
                            discardPaper={() => deletePaper(paper.id)}
                          />
                        </WrapItem>
                      ))}
                    </Wrap>
                </Box>
            }
          </Flex>
        </Container>
      </Flex>
      { /** create new document */}
      <Modal isOpen={isNewDocumentOpen} onClose={onNewDocumentOnClose} size={'lg'} closeOnOverlayClick={false}>
        <ModalOverlay bg={'whiteAlpha.700'}/>
        <ModalContent minW={'900px'} minHeight={"720px"} bg={'#fbfcff'} top={'10%'}>
          <Heading size={'md'} ml={6} mt={4} mb={1}>New Document</Heading>
          <ModalCloseButton/>
          <ModalBody display={'flex'} p={8} bg={'#FBFCFF'} alignItems={'end'}>
            <Flex alignItems={'center'} width={'100%'}>
              { /** steps */}
              <Flex width={"150px"} height={'380px'} mt={12} flexDir={'column'} justify={'space-between'}
                    alignItems={'center'} gap={4}>
                <VStack alignItems={"center"}>
                  <Flex width={"20px"} height={"20px"} alignItems={"center"} justifyContent={"center"}
                        background={"#1D8AFF"} borderRadius={4}>
                    <CheckIcon color={"#FFFFFF"} fontSize={12}/>
                  </Flex>
                  <Text fontWeight={"bold"} fontSize={14} color={"#333"}>
                    Step 1
                  </Text>
                  <Text fontSize={"12px"} color={"#333"}>
                    Choose States
                  </Text>
                </VStack>
                <Box width={"2px"} flex={"1"} background={"#1D8AFF"}></Box>
                <VStack alignItems={"center"}>
                  <Flex width={"20px"} height={"20px"} alignItems={"center"} justifyContent={"center"}
                        background={"#1D8AFF"} borderRadius={4}>
                    {currentStep === 2 ? <CheckIcon color={"#FFFFFF"} fontSize={12}/>
                      : <Box bg={"#FFFFFF"} width={'10px'} height={'10px'} borderRadius={6}/>
                    }
                  </Flex>
                  <Text fontWeight={"bold"} fontSize={14} color={"#333"}>
                    Step 2
                  </Text>
                  <Text fontSize={"12px"} color={"#333"}>
                    Select Structure
                  </Text>
                </VStack>
              </Flex>
              {currentStep === 1 &&
                  <Flex height={"450px"} flexDir={'column'} flex={1}
                        justifyContent={'space-around'}>
                      <HStack justifyContent={"space-between"} alignItems={'center'} mb={10}>
                          <Center bg={newIntent === NewIntent.NO_DRAFT ? 'rgba(56,152,255,0.38)' : 'white'}
                                  border={'1px solid #d3d7e2'} borderRadius={'12px'} mr={2} w={'45%'} cursor={'pointer'}
                                  h={'176px'}
                                  position={"relative"}
                                  _hover={{bg: 'rgba(56, 152, 255, 0.53)'}}
                                  onClick={() => intentOnClick(NewIntent.NO_DRAFT)} fontSize={'14px'}>
                            {newIntent === NewIntent.NO_DRAFT &&
                                <Flex width={"26px"} height={"26px"}
                                      alignItems={"center"} justifyContent={"center"}
                                      background={"transparent"}
                                      border={"1px solid #1D8AFF"}
                                      position={"absolute"}
                                      right={'10px'}
                                      top={'10px'}
                                      borderRadius={6}>
                                    <CheckIcon color={"#1D8AFF"} fontSize={12}/>
                                </Flex>
                            }
                              <Flex flexDir={'column'} alignItems={'center'}>
                                  <Box mb={5}>
                                      <Image
                                          w={'100px'}
                                          objectFit='cover'
                                          src='/static/editor/edit.png'
                                          alt='no draft'
                                      />
                                  </Box>
                                  Start a New Document
                              </Flex>
                          </Center>
                          <Center
                              bg={newIntent === NewIntent.NORMAL_DRAFT ? 'rgba(56,152,255,0.38)' : 'white'}
                              border={'1px solid #d3d7e2'} borderRadius={'12px'} w={'45%'} cursor={'pointer'}
                              h={'176px'} position={'relative'}
                              _hover={{bg: 'rgba(56, 152, 255, 0.38)'}}
                              onClick={() => intentOnClick(NewIntent.NORMAL_DRAFT)} fontSize={'14px'}>
                            {newIntent === NewIntent.NORMAL_DRAFT &&
                                <Flex width={"26px"} height={"26px"}
                                      alignItems={"center"} justifyContent={"center"}
                                      background={"transparent"}
                                      border={"1px solid #1D8AFF"}
                                      position={"absolute"}
                                      right={'10px'}
                                      top={'10px'}
                                      borderRadius={6}>
                                    <CheckIcon color={"#1D8AFF"} fontSize={12}/>
                                </Flex>
                            }
                              <Flex flexDir={'column'} alignItems={'center'}>
                                  <Box mb={5}>
                                      <Image
                                          w={'100px'}
                                          objectFit='cover'
                                          src='/static/editor/document-text.png'
                                          alt='well strucutred draft'
                                      />
                                  </Box>
                                  import an Existing Draft
                              </Flex>
                          </Center>
                      </HStack>
                    {newIntent === NewIntent.NO_DRAFT && (
                      <FormControl>
                        <FormLabel>
                          <Flex flexDir={'row'} alignItems={'center'}>
                            What is your writing area? <Text ml={1} color='red'>*</Text>
                          </Flex>
                        </FormLabel>
                        <Textarea
                          rows={8}
                          bg={'white'}
                          value={writingArea}
                          borderRadius={'12px'}
                          onChange={(e) => {
                            setWritingArea(e.target.value)
                            if (e.target.value) {
                              setNextDisabled(false)
                            }
                          }}
                        />
                      </FormControl>
                    )}
                    {newIntent === NewIntent.NORMAL_DRAFT && (
                      uploadSuccess ?  <Flex borderRadius={8}
                                             py={"48px"}
                                             border={ "1px solid #C9D8E7" }
                                             background={"#FFFFFF"}
                                             flexDir={'column'}
                                             justifyContent={"center"}
                                             alignItems="center">
                        <Image
                          w={"80px"}
                          objectFit="cover"
                          src="/static/editor/check_ok.png"
                        />
                          <Text
                            mt={2}
                            fontSize={12}
                            color="gray.400"
                            lineHeight="12px"
                            textAlign="center"
                          >
                            Please Click Next to Continue
                          </Text>
                        </Flex>:
                      <FileUpload usage={'draft'} border={true} afterUpload={onDraftFinish} />
                      )
                    }
                  </Flex>
              }
              {currentStep === 2 &&
                  <Flex width={'100%'} height={"740px"} flexDir={'column'}
                        justifyContent={'space-around'} gap={2}>
                    {structures.map((structure, index) => {
                      return (
                        <Box key={index}
                             height={'33%'}
                             bg={currentStructure === index ? 'rgba(56, 152, 255, 0.38)' : '#FFFFFF'}
                             border={'1px solid'}
                             borderColor={currentStructure === index ? 'rgba(56,152,255,0.38)' : '#d3d7e2'}
                             borderRadius={'12px'} p={4}>
                          <StructureList
                            title={`Option${index + 1}: ${structure.title}`}
                            list={structure.list}
                            index={index}
                            currentIndex={currentStructure}
                            createDefaultLoading={createDefaultLoading}
                            setCurrentIndex={setCurrentStructure}
                            setShowModalFooter={setShowModalFooter}
                            afterReferenceUpload={afterReferenceUpload}
                          ></StructureList>
                        </Box>
                      )
                    })}
                  </Flex>
              }
            </Flex>
          </ModalBody>
          {showModalFooter && <ModalFooter justifyContent={'space-around'}>
              <Button
                  w="120px"
                  mt={4}
                  disabled={nextDisabled}
                  onClick={() => setCurrentStep(1)}>
                Back
              </Button>
              <Button
                w="120px"
                mt={4} colorScheme="brand"
                disabled={nextDisabled}
                isLoading={startLoading}
                onClick={nextHandler}>
                { currentStep === 1 ? 'Next' : 'Start' }
              </Button>
          </ModalFooter>
          }
        </ModalContent>
      </Modal>
      {selectedPaper &&
          <Modal isOpen={isPaperViewerOpen} onClose={onPaperViewerClose} size={'full'}>
              <ModalOverlay/>
              <ModalContent w={'90%'} h={'90vh'}>
                  <ModalHeader>{selectedPaper.name}</ModalHeader>
                  <ModalCloseButton/>
                  <ModalBody overflow={'auto'}>
                    {JSON.stringify(selectedPaper.content)}
                  </ModalBody>

                  <ModalFooter>
                      <Button colorScheme='yellow' size={'sm'} leftIcon={<EditIcon/>} mr={3}
                              onClick={() => continueEditing(selectedPaper.id)}>
                          Edit
                      </Button>
                  </ModalFooter>
              </ModalContent>
          </Modal>
      }
      <Modal
        isOpen={isOpenPreviewPanel}
        onClose={onClosePreviewPanel}
        scrollBehavior="inside"
      >
        <ModalOverlay background={'whiteAlpha.200'} backdropFilter={'blur(2px)'}/>
        <ModalContent minW="672px" minHeight="880px" top="-30px">
          <ModalCloseButton/>
          <ModalBody p={5}>
            <PreviewModalPanel templateIndex={templateIndex} templates={templates}/>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Flex>
  );
};

export default Writer;

import React, {useRef, useState} from 'react';
import {Flex, Box, Image, Text, Button, VStack} from '@chakra-ui/react';
import DonebaSpinner from "../DoenbaSpinner/DoenbaSpinner.tsx";
import {OutlineService} from "../../services";
import {ClientError} from "../../utils/clientError.ts";
import {PaperOutline} from "../../types/Writer/OutlineNode.ts";


interface FileUploadProps {
  flexDir?: 'row' | 'column'
  usage?: 'draft' | 'reference'
  border?: boolean
  uploadText?: string
  mockClick?: boolean
  afterUpload: (outline: PaperOutline) => void;
  setShowModalFooter?: (show: boolean) => void
}

export const FileUpload: React.FC<FileUploadProps> = ({
                                                        border,
                                                        usage,
                                                        uploadText,
                                                        flexDir,
                                                        mockClick,
                                                        afterUpload,
                                                        setShowModalFooter
                                                      }) => {

  const [uploading, setUploading] = useState<boolean>(false)
  const inputRef = useRef<HTMLInputElement | null>(null);


  const handleFileUploadOnClick = () => {
    if (uploading) {
      setUploading(false)
    }
    if (inputRef.current) {
      inputRef.current.click();
    }
  };
  if (mockClick) {
    Promise.resolve().then(() => {
      handleFileUploadOnClick()
    })
  }
  /**
   * upload file adn extract outline
   * @param event
   */
  const handlerUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setUploading(true);
      setShowModalFooter && setShowModalFooter(false)
      try {
        // usage draft reference
        OutlineService.extractOutline(file, usage).then(
          res => {
            afterUpload(res.outline);
            setUploading(false)
            setShowModalFooter && setShowModalFooter(true)
          }
        )
      } catch (error) {
        setUploading(false)
        new ClientError(error).toast();
      }
    } else {
      setUploading(false);
      alert('Please upload a valid article file');
    }
  };

  return (
    <Flex borderRadius={8}
          border={border ? "1px solid #C9D8E7" : ''} background={"#FFFFFF"} flexDir={flexDir} alignItems="center">
      <Box
        as="button"
        onClick={handleFileUploadOnClick}
        cursor="pointer"
        w={'80%'}
        padding={border ? 10 : 0}
      >
        {!uploading &&
            <VStack w={"full"}>
                <Box w="40px" mb={2}>
                    <Image
                        w="40px"
                        objectFit="cover"
                        src="/static/editor/export.png"
                        alt="Doenba"
                    />
                </Box>
                <Text color="gray.500">
                  {uploadText || 'Drag to Upload your saved content' }</Text>
                <Text
                    mt={2}
                    fontSize={12}
                    color="gray.400"
                    lineHeight="12px"
                    textAlign="center"
                >
                    Please upload pdf. words. txt. files
                </Text>
            </VStack>
        }
        {uploading &&
            <Flex flexDir={'column'}
                  justifyContent={'center'}
                  alignItems={'center'}>
                <DonebaSpinner/>
                <Text mt={5} color={'gray.400'}>File Uploading</Text>
            </Flex>
        }
        <input
          ref={inputRef}
          type="file"
          onChange={handlerUpload}
          style={{display: "none"}}
        />
      </Box>

      {!uploading && <Button
        size="sm"
        width="120px"
        mt={4}
        colorScheme="brand"
        onClick={handleFileUploadOnClick}
      >
        Select
      </Button>}
    </Flex>
  );
};

export default FileUpload;

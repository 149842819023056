import React from "react";
import {OutlineNodeType, PaperOutline, PaperOutlineNode} from "../../types/Writer/OutlineNode.ts";
import {getSelectNodeIndex} from "../../utils/editor.ts";

/**
 * watch input keyboard key
 * @param e
 * @param nodeList editor content json
 * @param outline
 * @param callbacks
 */
export const useEditorKeyboard = async (
    e: React.KeyboardEvent<HTMLElement>,
    nodeList: PaperOutlineNode[],
    outline: PaperOutline,
    callbacks: {
        updateOutline: (outline: PaperOutline) => Promise<void>,
        addNewNode: (node: PaperOutlineNode,
                       index: number,
                       type: OutlineNodeType,
                       sameLevel?: boolean,
                       content?: string
                     ) => PaperOutline,
        setEditNode: (editNode: PaperOutlineNode) => void,
        setNodeList: (nodeList: PaperOutlineNode[]) => void
    }
) => {
    const selection = window.getSelection();
    const range = selection.rangeCount > 0 ? selection.getRangeAt(0) : null;
    if (range) {
        const {startContainer, startOffset} = range;
        switch (e.key) {
          // add new node in next cursor in nodeList
            case 'Enter': {
                if (range) { // todo: if cursor in center
                    e.preventDefault();
                    let editDom = null
                    const parentDom = startContainer.parentNode as HTMLElement
                    if ((startContainer as HTMLElement).classList?.contains('section-type')
                      || (startContainer as HTMLElement).classList?.contains('paragraph-type')) {
                        editDom = startContainer
                    } else if (parentDom?.classList?.contains('section-type')
                      || parentDom?.classList?.contains('paragraph-type')) {
                        editDom = startContainer.parentNode as Element
                    }
                    if (editDom) {
                        const index = getSelectNodeIndex(nodeList, editDom)
                        const type = editDom.classList?.contains('section-type')
                          ? OutlineNodeType.Section : OutlineNodeType.Paragraph;
                        const currentContent = nodeList[index].content
                        let newText = ''
                        if (startOffset < currentContent?.length) {
                            if (type === OutlineNodeType.Section) {
                                nodeList[index].name = currentContent.slice(0, startOffset)
                            } else {
                                nodeList[index].content = currentContent.slice(0, startOffset)
                            }
                            newText = currentContent.slice(startOffset)
                        }
                        // 点击回车新建一个Paragraph
                        const addType= OutlineNodeType.Paragraph
                        await callbacks.updateOutline(
                          callbacks.addNewNode(
                            nodeList[index],
                            index,
                            addType,
                            false,
                            newText
                          )
                        );
                        // TODO: how to nextTick
                        setTimeout(() => {
                            const nextLi = findSiblingPDom(editDom.closest('li'), 'nextSibling');
                            if (nextLi) {
                                setCursorPosition(nextLi)
                            }
                        }, 200)
                    }
                }
                break
            }
            case 'Backspace':
            case 'Delete': {
                if (range) {
                    const selectString = selection.toString();
                    if (selectString.length === 0) { // no select text
                        if ((startContainer.nodeType === Node.ELEMENT_NODE && isEmptyNode(startContainer as HTMLElement))
                          || (startContainer.nodeType === Node.TEXT_NODE && startContainer.textContent === '')
                        ) {
                            e.preventDefault();
                            const index = getSelectNodeIndex(nodeList, startContainer as Element)
                            if (index > -1) {
                                const removeNode = nodeList[index]
                                callbacks.setEditNode(removeNode)
                                nodeList.splice(index, 1)
                                callbacks.setNodeList(nodeList)
                                // todo
                                const pDom = findSiblingPDom((startContainer as Element).closest('li'), 'previousSibling')
                                deleteNode(nodeList, removeNode, outline)
                                callbacks.updateOutline(outline)
                                if (pDom) {
                                    setCursorPosition(pDom)
                                }
                            }
                        }

                        if (Object.prototype.toString.call(startContainer) === '[object Text]' && startContainer.textContent
                          && range.startOffset === 0) {
                            e.preventDefault()
                            // if p and pre is p, current text append to previous p  delete current
                            const currentIndex = getSelectNodeIndex(nodeList, startContainer.parentElement)
                            const preIndex = currentIndex - 1
                            // if no pre p, don't handler
                            if (nodeList[preIndex]?.type === OutlineNodeType.Section) {
                                return
                            } else  {
                                if (preIndex > -1) { // insert before
                                    nodeList[preIndex].content += startContainer.textContent;
                                }
                                if (currentIndex > -1) { // delete current
                                    const removeNode = nodeList[currentIndex]
                                    callbacks.setEditNode(removeNode)
                                    nodeList.splice(currentIndex, 1)
                                    const pDom = findSiblingPDom(startContainer.parentElement.closest('li'), 'previousSibling')

                                    deleteNode(nodeList, removeNode, outline)
                                    if (pDom) {
                                        setCursorPosition(pDom)
                                    }
                                }
                                callbacks.setNodeList(nodeList)
                                callbacks.updateOutline(outline);
                            }
                        }
                    }
                    else {
                        const {startContainer, endContainer} = range
                        const startIndex = getSelectNodeIndex(nodeList, startContainer.parentElement)
                        const endIndex = getSelectNodeIndex(nodeList, endContainer.parentElement)
                        const texts = selectString.split(/\n+/)
                        console.log(startIndex, endIndex, texts)

                        if (startIndex !== endIndex) { // 跨多个节点
                            const willRemoveList= []
                            for(let i = endIndex; i >= startIndex; i--) {
                                // const node = nodeList[i]
                                if (i === startIndex) {
                                    // 判断是否为section，如果为section
                                } else if (i === endIndex) {
                                    // delete end node
                                    if (nodeList[endIndex].content === texts[endIndex]) { // all
                                        willRemoveList.push(i)
                                    }
                                    // if delete section, move extra paragraph to closest upLevel section
                                    const level = nodeList[endIndex].level
                                    for (let i = endIndex + 1; i < nodeList.length; i++) {
                                        if (level === nodeList[i].level) {
                                            // find closest upLevel section
                                            for (let j = startIndex; j > 0; j--) {
                                                if (nodeList[j].level > level) {
                                                    nodeList[j]
                                                }
                                            }
                                        } else if (level < nodeList[i].level) {
                                            break
                                        }
                                    }
                                } else {
                                    willRemoveList.push(i)
                                }
                            }
                            // 查看startIndex-1的level和startIndex的level, 如果相同，正常删除；

                            const newNodeList = nodeList.filter((_item: PaperOutlineNode, index: number) => {
                                return index <= startIndex || index > endIndex
                            })
                            willRemoveList.forEach(index => {
                                deleteNode(nodeList, nodeList[index], outline)
                            })
                            callbacks.setNodeList(newNodeList)
                            // callbacks.updateOutline(outline)
                        }
                    }
                }
                break
            }
        }
    }
};
export const isEmptyNode = (node: HTMLElement) => {
    return node.innerHTML.replace(/<br\s*\/?>/gi, '').trim() === ''
}

// todo: ?
export const deleteNode = (nodeList: PaperOutlineNode[], removeNode: PaperOutlineNode, outline: PaperOutline): PaperOutline | undefined => {
    for (let i = 0; i < nodeList.length; i++) {
      const index = nodeList[i].children?.indexOf(removeNode);
      if (index > -1) {
        nodeList[i].children.splice(index, 1);
        return {
          nextId: outline.nextId,
          nodes: outline.nodes,
        };
      }
    }
    return undefined;
  };


export const setCursorPosition = (node: Node) => {
    const range = document.createRange();
    const selection = window.getSelection();
    range.selectNodeContents(node);
    range.collapse(false);
    selection.removeAllRanges();
    selection.addRange(range);
}

export const findSiblingPDom = (node: Node, dir: 'previousSibling' | 'nextSibling')=> {
    return node[dir]?.childNodes[0].childNodes[1].childNodes[1]
}

/**
 * 判断当前编辑的为section / paragraph
 */
export const isEditingSection = (node: Node, type: OutlineNodeType) => {
    const nodeTemp = (node as HTMLElement).classList?.contains(`${type}-type`)
    const parenTemp = node.parentElement.classList?.contains(`${type}-type`)
    if (nodeTemp || parenTemp) {
        return {
            type,
            node: nodeTemp ? (node as HTMLElement) : node.parentElement
        }
    }
    return undefined
}
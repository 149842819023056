import {
  Box,
} from "@chakra-ui/react";
// TODO: consider xss attack
const EditorContent: React.FC<{
  content: string;
  dataId: string
  type?: 'default' | 'table' | 'equation' | 'image'
}> = (props) => {
  return (
    <>
      <Box
        className={'paragraph-type editable-textarea'}
        data-id={props.dataId}
        fontFamily='"Times New Roman", Times, serif'
        sx={{
          width: "100%",
          minHeight: "20px",
        }}
        dangerouslySetInnerHTML={{__html: props.content}}
      >
      </Box>
    </>
  );
};
export default EditorContent;

import React, { useEffect, useState } from 'react';
import { Box, AspectRatio, Card, CardBody, Heading, Stack, Image } from '@chakra-ui/react';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useTranslation } from 'react-i18next';
import { useSetRecoilState } from 'recoil';
import { globalVideoPlayerOpenStatus, videoResource } from '../../atoms/rootAtom';
import { VideoType, videos_cn, videos_en } from './video.utils';

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 5
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};



const VideoCarousel: React.FC = () => {
  const { i18n, t } = useTranslation();
  const [videos, setVideos] = useState<VideoType[]>(videos_en);
  const showVideo = useSetRecoilState<boolean>(globalVideoPlayerOpenStatus);
  const setVideo = useSetRecoilState<VideoType>(videoResource);

  const openVideoPlayer = (video: VideoType) => {
    showVideo(true);
    setVideo(video);
  }

  useEffect(() => {
    if (i18n.language == 'en') {
      setVideos(videos_en);
    } else {
      setVideos(videos_cn);
    }
  }, [i18n.language])

  return (
    <Box mb={10}>
      <Carousel
        responsive={responsive} 
        infinite={false}
        autoPlay={false}>
        {videos.map((video) => (
          <Box
            key={video.title}
            flex="0 0 50%"
            paddingX="5px"
            boxSizing="border-box"
            mb={10}
            cursor={'pointer'}
          >
            <AspectRatio ratio={16 / 9}  borderRadius="10px" h={'250px'}>
              <Card maxW='sm' onClick={() => openVideoPlayer(video)}>
                <CardBody>
                  <Image
                    src={`/static/${video.title}.jpg`}
                    alt={video.title}
                    borderRadius='lg'
                  />
                  <Stack my='3' spacing='3' textAlign={'center'}>
                    <Heading size='md'>{t(video.title)}</Heading>
                  </Stack>
                </CardBody>
              </Card>
            </AspectRatio>
          </Box>
        ))}
      </Carousel>
    </Box>
  );
};

export default VideoCarousel;

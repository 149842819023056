import {
  OutlineNodeType,
  PaperOutline,
  PaperOutlineNode,
} from "../../types/Writer/OutlineNode";
import {
  Text,
  Box,
  Center,
  List,
  ListItem,
  HStack,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  VStack,
  Button,
  useDisclosure,
  Flex,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Image,
} from "@chakra-ui/react";
import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  alignStringLen,
  getNodeRoot,
  getTreeNodes,
} from "../../types/Writer/NodesFunc";
import { Function1 } from "lodash";
import { PiDotsThreeVerticalBold, PiDotsSixVerticalBold } from "react-icons/pi";
import PopoverBox from "../PaperEditor/PopoverBox";

const btnColor: string = "#1D8AFF";
const NodeTree: React.FC<{
  height?: string;
  outline: PaperOutline;
  updateOutline: Function1<PaperOutline, void>;
  selectSection: PaperOutlineNode;
  setSelectSection: Dispatch<SetStateAction<PaperOutlineNode>>;
}> = (props) => {
  const { t } = useTranslation();
  const listRef = useRef(null);

  const [sectionRoot, setSectionRoot] = useState<PaperOutlineNode>(undefined);
  const [nodeList, setNodeList] = useState<Array<PaperOutlineNode>>([]);
  const [isNodeDrag, setIsNodeDrag] = useState<boolean>(false);
  const [dragIndex, setDragIndex] = useState<number>(-1);
  const [dragDesIndex, setDragDesIndex] = useState<number>(-1);
  const [dragPosition, setDragPosition] = useState<number>(0);
  const [newTitle, setNewTitle] = useState<string>("");
  const [newTitleLabel, setNewTitleLabel] = useState<string>("");
  const [inputPanelFunc, setInputPanelFunc] = useState<number>(1);

  const {
    isOpen: isOpenDeleteConfirmPanel,
    onOpen: onOpenDeleteConfirmPanel,
    onClose: onCloseDeleteConfirmPanel,
  } = useDisclosure();
  const {
    isOpen: isOpenTitleInputPanel,
    onOpen: onOpenTitleInputPanel,
    onClose: onCloseTitleInputPanel,
  } = useDisclosure();
  const [inputPositionY, setInputPositionY] = useState<number>(0);
  const [nodePosArr, setNodePosArr] = useState<Array<number>>([]);

  const moveNode = (from: number, to: number): PaperOutline => {
    if (from == to) return;
    const newOutLine = props.outline.nodes;
    const newNode = newOutLine.splice(from, 1);
    if (to > from) to -= 1;
    newOutLine.splice(to, 0, newNode[0]);
    return {
      nextId: props.outline.nextId,
      nodes: newOutLine,
    };
  };
  const addNewNode = (title: string, label: string): PaperOutline => {
    if (title.length == 0) return;
    const newNode: PaperOutlineNode = {
      id: props.outline.nextId,
      type: OutlineNodeType.Section,
      name: title,
      level: 1,
      label: label,
      children: [],
    };

    const newOutLine = props.outline.nodes;
    let newNodes = [];
    if (newOutLine.length > 0) {
      const insertIndex = newOutLine.indexOf(sectionRoot, 0);
      if (inputPanelFunc == 1) {
        newNodes = [
          ...newOutLine.splice(insertIndex, 0, newNode),
          ...newOutLine,
        ];
      } else {
        if (newOutLine[insertIndex]?.name == title) return;
        newOutLine[insertIndex].name = title;
        newNodes = newOutLine;
      }
    } else {
      newNodes.push(newNode);
    }
    return {
      nextId: props.outline.nextId + 1,
      nodes: newNodes,
    };
  };

  const deleteNode = (node: PaperOutlineNode): PaperOutline => {
    const newOutLine = props.outline.nodes;
    const insertIndex = newOutLine.indexOf(node, 0);
    if (insertIndex > -1) {
      newOutLine.splice(newOutLine.indexOf(sectionRoot, 0), 1);
      return {
        nextId: props.outline.nextId + 1,
        nodes: newOutLine,
      };
    }
    return undefined;
  };

  const dragStart = (node) => {
    setIsNodeDrag(true);
    const Itempos = [];
    const childNodes: Array<any> = listRef.current.childNodes;
    childNodes.forEach((element) => {
      if (element.offsetHeight > 27) {
        Itempos.push(element.offsetTop + 30);
      }
    });
    Itempos.push(
      childNodes[childNodes.length - 1].offsetTop +
        childNodes[childNodes.length - 1].offsetHeight +
        30
    );
    setNodePosArr(Itempos);
    props.outline.nodes.indexOf(node);
    setDragIndex(props.outline.nodes.indexOf(node));
  };

  const dragOver = (e) => {
    if (
      isNodeDrag &&
      (e.target["nodeName"] == "B" || e.target["nodeName"] == "DIV") &&
      !isNaN(e.target["offsetTop"])
    ) {
      for (let i = 0; i < nodePosArr.length; i++) {
        if (e.target["offsetTop"] < nodePosArr[i]) {
          const pos =
            nodePosArr[i] -
            listRef.current.scrollTop -
            listRef.current.offsetHeight -
            (props.height.indexOf("502") > -1 ? 360 : 230);
          if (pos != dragPosition) {
            setDragDesIndex(i);
            setDragPosition(pos);
          }
          break;
        }
      }
    }
  };

  useEffect(() => {
    //setSectionRoot(getNodeRoot(nodeList, props.selectSection));
  }, [props.selectSection]);

  useEffect(() => {
    const newNodeList = getTreeNodes(
      props.outline.nodes,
      1,
      OutlineNodeType.Section
    );
    setNodeList(newNodeList);
    if (props.selectSection) {
      const rootSelection = getNodeRoot(
        props.outline.nodes,
        props.selectSection
      );
      if (rootSelection) {
        setSectionRoot(rootSelection);
        props.setSelectSection(rootSelection);
        return;
      }
    }
    setSectionRoot(newNodeList[0]);
    props.setSelectSection(newNodeList[0]);
  }, [props.outline]);
  return (
    <>
      {nodeList.length == 0 ? (
        <Center>
          <IconButton
            aria-label={""}
            onClick={(e) => {
              setNewTitle("New Section");
              setNewTitleLabel("");
              setInputPanelFunc(1);
              onOpenTitleInputPanel();
              setInputPositionY(e.pageY - 87);
            }}
            icon={
              <Image
                w={"40px"}
                objectFit="cover"
                src="/static/editor/add-square.png"
                alt="Doenba"
              />
            }
          />
        </Center>
      ) : (
        <Box h={"100%"} overflow={"hidden"}>
          <Center>
            <Box
              position={"relative"}
              top={dragPosition}
              bgColor={isNodeDrag ? "#D3D7E2" : "transparent"}
              w={"212px"}
              h={"2px"}
            />
          </Center>
          <List
            h={"100%"}
            overflowY={"auto"}
            spacing={4}
            //border={"1px solid #ccc"}
            ref={listRef}
            overflowX={"hidden"}
            css={{
              "&::-webkit-scrollbar": {
                width: "4px",
              },
              "&::-webkit-scrollbar-thumb": {
                background: "#9EC3E8",
                borderRadius: "2px",
              },
              "&::-webkit-scrollbar-thumb:hover": {
                background: "#2b6cb0",
              },
              "&::-webkit-scrollbar-track": {
                background: "#C9D8E7",
              },
            }}
          >
            {nodeList.map(
              (
                node: PaperOutlineNode,
                index: number,
                array: PaperOutlineNode[]
              ) => {
                return node.level != 1 && node.level != 2 ? undefined : (
                  <ListItem
                    key={node.id.toString() + "|level" + node.level.toString()}
                    height={
                      (node.level == 1
                        ? 42 + (sectionRoot?.id === node.id ? 50 : 0)
                        : 27) + "px"
                    }
                    onClick={() => {
                      for (let i = index; i >= 0; i--) {
                        if (array[i].level === 1) {
                          setSectionRoot(array[i]);
                          break;
                        }
                      }
                      props.setSelectSection(node);
                    }}
                  >
                    <VStack
                      onDragOver={dragOver}
                      onDragEnd={() => {
                        setIsNodeDrag(false);
                        setDragPosition(0);
                        props.updateOutline(moveNode(dragIndex, dragDesIndex));
                      }}
                    >
                      {sectionRoot?.id === node.id && (
                        <VStack outline={"sm"}>
                          <Box
                            w={"200px"}
                            h={"1px"}
                            bgColor={"#ccc"}
                            position={"relative"}
                            top={"23px"}
                            zIndex={0}
                          />
                          <IconButton
                            size={"xs"}
                            icon={
                              <Image
                                w={"40px"}
                                objectFit="cover"
                                src="/static/editor/add-square.png"
                                alt="Doenba"
                              />
                            }
                            onClick={(e) => {
                              setNewTitle("New Section");
                              setNewTitleLabel("");
                              setInputPanelFunc(1);
                              onOpenTitleInputPanel();
                              setInputPositionY(e.pageY - 70);
                            }}
                            aria-label={""}
                          />
                        </VStack>
                      )}
                      <Center
                        width={"100%"}
                        borderLeft={
                          node.level === 1 && sectionRoot?.id === node.id
                            ? `5px solid ${btnColor}`
                            : null
                        }
                      >
                        <HStack
                          alignItems="Center"
                          bgColor={
                            node.type === "section" &&
                            sectionRoot?.id === node.id
                              ? btnColor
                              : null
                          }
                          height={node.level == 1 ? "42px" : "24px"}
                          width={node.level == 1 ? "192px" : "120px"}
                          borderRadius={node.level == 1 ? "10px" : undefined}
                          borderBottom={
                            node.level != 1 && props.selectSection.id == node.id
                              ? `3px solid ${btnColor}`
                              : null
                          }
                        >
                          <HStack
                            _hover={{
                              textColor:
                                sectionRoot?.id === node.id
                                  ? "white"
                                  : btnColor,
                            }}
                            textColor={
                              node.level === 1 && sectionRoot?.id === node.id
                                ? "white"
                                : "#8E9ABC"
                            }
                            draggable
                          >
                            {node.level === 1 && (
                              <IconButton
                                bgColor={"transparent"}
                                _hover={{ borderRadius: "15px" }}
                                size={"xs"}
                                icon={
                                  <PiDotsSixVerticalBold
                                    style={{ width: "20px", height: "20px" }}
                                    color={
                                      node.level === 1 &&
                                      sectionRoot?.id === node.id
                                        ? "white"
                                        : "#8E9ABC"
                                    }
                                    cursor="grab"
                                    onMouseDown={() => dragStart(node)}
                                  />
                                }
                                aria-label={""}
                              />
                            )}
                            <PopoverBox
                              text={node.name}
                              children={
                                <Center
                                  as="b"
                                  fontSize={"16px"}
                                  ml={-2}
                                  fontFamily={"'Lato', serif"}
                                  width={node.level == 1 ? "140px" : "120px"}
                                  aria-multiline={false}
                                  isTruncated={true}
                                >
                                  {alignStringLen(node.name, 9)}
                                </Center>
                              }
                            />
                          </HStack>
                          {node.level === 1 && (
                            <Menu matchWidth={true} offset={[24, -39]}>
                              <MenuButton height={"100%"} width={"24px"} pl={0}>
                                <PiDotsThreeVerticalBold
                                  color={
                                    node.type === "section" &&
                                    sectionRoot?.id === node.id
                                      ? "white"
                                      : "#8E9ABC"
                                  }
                                />
                              </MenuButton>
                              <MenuList
                                border={"1px solid #cccccc"}
                                minWidth={93}
                                bgSize={"xs"}
                                maxBlockSize={"100px"}
                                backgroundSize={"sm"}
                              >
                                <MenuItem
                                  as={Button}
                                  _hover={{ bgColor: "#3898FF61" }}
                                  borderRadius={10}
                                  justifyContent="flex-start"
                                  textAlign="left"
                                  pl={0.5}
                                  onClick={(e) => {
                                    setInputPanelFunc(2);
                                    onOpenTitleInputPanel();
                                    setNewTitle(sectionRoot.name);
                                    setNewTitleLabel(sectionRoot.content);
                                    setInputPositionY(e.pageY - 95);
                                  }}
                                  leftIcon={
                                    <Image
                                      sizes="xs"
                                      w={"24px"}
                                      objectFit="cover"
                                      src="/static/editor/edit2.png"
                                      alt="no draft"
                                    />
                                  }
                                  fontSize={14}
                                  textColor={"black"}
                                >
                                  {t("editor-editormodel-treeRename")}
                                </MenuItem>
                                <MenuItem
                                  as={Button}
                                  pl={0.5}
                                  _hover={{ bgColor: "#3898FF61" }}
                                  borderRadius={10}
                                  onClick={onOpenDeleteConfirmPanel}
                                  justifyContent="flex-start"
                                  textAlign="left"
                                  leftIcon={
                                    <Image
                                      w={"24px"}
                                      objectFit="cover"
                                      src="/static/editor/delete.png"
                                      alt="no draft"
                                    />
                                  }
                                  textColor={"black"}
                                  fontSize={14}
                                >
                                  {t("editor-editormodel-treeDelete")}
                                </MenuItem>
                              </MenuList>
                            </Menu>
                          )}
                        </HStack>
                      </Center>
                    </VStack>
                  </ListItem>
                );
              }
            )}
          </List>
        </Box>
      )}
      <Modal
        isOpen={isOpenDeleteConfirmPanel}
        onClose={onCloseDeleteConfirmPanel}
        closeOnOverlayClick={false}
        size={"6xl"}
      >
        <ModalOverlay
          background={"blackAlpha.100"}
          backdropFilter={"blur(1px)"}
        />
        <ModalContent
          width={551}
          height={345}
          top={200}
          border={"1px solid #ccc"}
        >
          <ModalBody p={5}>
            <Flex flexDir={"column"}>
              <Center mt={5}>
                <Image
                  w={"100px"}
                  objectFit="cover"
                  src="/static/editor/delete2.png"
                  alt="Doenba"
                />
              </Center>
              <Center mt={30}>
                <Text textColor={"black"}>
                  {t("editor-editormodel-leftDeletePanel1")}
                  <span style={{ color: "#1D8AFF" }}>{sectionRoot?.name}</span>
                  {t("editor-editormodel-leftDeletePanel2")}
                </Text>
              </Center>
              <Center>
                <Text textColor={"black"}>
                  {t("editor-editormodel-leftDeletePanel3")}
                </Text>
              </Center>
              <HStack p={10} spacing={150}>
                <Button
                  bgColor={"#9399AB"}
                  width={"139px"}
                  height={"48px"}
                  textColor={"white"}
                  _hover={{ bgColor: "#1D8AFF", textColor: "white" }}
                  onClick={() => {
                    onCloseDeleteConfirmPanel();
                  }}
                >
                  {t("editor-editormodel-leftBtnCancel")}
                </Button>
                <Button
                  bgColor={"#9399AB"}
                  width={"139px"}
                  height={"48px"}
                  textColor={"white"}
                  _hover={{ bgColor: "#1D8AFF", textColor: "white" }}
                  onClick={() => {
                    props.updateOutline(deleteNode(sectionRoot));
                    onCloseDeleteConfirmPanel();
                  }}
                >
                  {t("editor-editormodel-leftBtnDelete")}
                </Button>
              </HStack>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
      <Modal
        isOpen={isOpenTitleInputPanel}
        onClose={onCloseTitleInputPanel}
        closeOnOverlayClick={false}
        size={"6xl"}
      >
        <ModalOverlay background="transparent" />
        <ModalContent
          width={373}
          height={135}
          top={inputPositionY}
          left={-(window.innerWidth / 2 - 460)}
          border={"1px solid #ccc"}
        >
          <ModalBody p={1}>
            <VStack p={2}>
              <Flex width={"100%"}>
                <Text width={"60px"} fontSize={16} align={"left"} as="b">
                  Title:
                </Text>
                <Input
                  width={"283px"}
                  height={"27px"}
                  placeholder={"New Section New Section"}
                  defaultValue={newTitle}
                  borderColor={newTitle.length > 0 ? undefined : "red.300"}
                  onBlur={(e) => setNewTitle(e.target.value)}
                />
              </Flex>
              <Flex width={"100%"}>
                <Text width={"60px"} fontSize={16} align={"left"} as="b">
                  Label:
                </Text>
                <Input
                  width={"283px"}
                  height={"27px"}
                  placeholder={"Use in Latex"}
                  defaultValue={newTitleLabel}
                  onBlur={(e) => setNewTitleLabel(e.target.value)}
                />
              </Flex>
              <HStack pt={3} spacing={170}>
                <Button
                  border={"3px solid #1D8AFF"}
                  width={"70px"}
                  height={"25px"}
                  fontSize={14}
                  textColor={"#1D8AFF"}
                  onClick={() => {
                    onCloseTitleInputPanel();
                  }}
                >
                  {t("editor-editormodel-treeInputCancel")}
                </Button>
                <Button
                  border={"3px solid #1D8AFF"}
                  width={"70px"}
                  height={"25px"}
                  fontSize={14}
                  textColor={"#1D8AFF"}
                  onClick={() => {
                    if (newTitle.length > 0) {
                      onCloseTitleInputPanel();
                      props.updateOutline(addNewNode(newTitle, newTitleLabel));
                    }
                  }}
                >
                  {t("editor-editormodel-treeInputSave")}
                </Button>
              </HStack>
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
export default NodeTree;

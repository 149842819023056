import { useState, useEffect, useMemo } from "react";
import { Box, Flex, Image, IconButton, Button, 
    // Menu, MenuButton, MenuItem, MenuList, 
    Modal, ModalBody, ModalCloseButton, ModalContent, ModalOverlay, Tooltip } from "@chakra-ui/react";
import { LuZoomIn, LuZoomOut } from 'react-icons/lu';
// import { TbZoomReplace } from "react-icons/tb";
// import { NotesService } from "../../services/addContextToNotes";
import { FileService } from "../../services";
import { useRecoilState, useSetRecoilState } from "recoil";
import { appLoadingState, appLoadingText, currentOpenedArticleIdState, quoteSentenceIndexesState, selectedArticlesState, selectedMessageBubbleId } from "../../atoms/rootAtom";
import MiniNav from "../NavBar/MiniNav";
import { AiOutlineFullscreen } from "react-icons/ai";
import { MdFavoriteBorder, MdFavorite } from "react-icons/md";
import { UserFileDataService } from "../../services/userFileDataService";
import { ClientError } from "../../utils/clientError";
import useUnprocessedFileHandler from "../../hooks/useUnprocessedFileHandler";
import { containsFeatures } from "../../services/articleFeatureService";
import { useTranslation } from "react-i18next";
import FeatureWrapper from "../FeatureWrapper/FeatureWrapper";
import { ArticleFeature } from "../../types/ArticleFeature";
import TopicSearch from "./TopicSearch";
import { Outlet } from "react-router-dom";
import { FaQuoteLeft } from "react-icons/fa";
import useHighlights, { HighlightType } from "../../hooks/useHighlights";
import { TbEraserOff } from "react-icons/tb";


const OracleReaderComponent = () => {
    const [floatingDivPosition, setFloatingDivPosition] = useState<{ top: number; left: number } | null>(null);
    const [selectionContext, setSelectedContext] = useState<string | null>(null);
    const [hasCompleteSelection, setHasCompleteSelection] = useState<boolean>(false);
    const [selectionSentenceIndexes, setSelectionSentenceIndexes] = useState<number[]>([]);
    const [htmlContent, setHtmlContent] = useState('');
    const [selectedArticles, setSelectedArticles] = useRecoilState(selectedArticlesState);
    const [currentOpenArticleId, setCurrentOpenArticleId] = useRecoilState(currentOpenedArticleIdState);
    const setQuoteSentenceIndexes = useSetRecoilState(quoteSentenceIndexesState);
    const [zoomLevel, setZoomLevel] = useState<number>(-2);
    // const [isTranslated, setIsTranslated] = useState<boolean>(false);
    // const [translatedLang, setTranslatedLang] = useState<string>('en');
    const [fullScreenMode, setFullScreenMode] = useState<boolean>(false);

    const setAppLoadingState = useSetRecoilState(appLoadingState);
    const setLoadingText = useSetRecoilState(appLoadingText);
    const { addNeededFilesToPoller, hasFileProcessedSuccessfully } = useUnprocessedFileHandler();
    // const [articleSentenceRank, setArticleSentenceRank] = useState<number[]>([]);
    const [isInReadingList, setIsInReadingList] = useState<boolean>(false);

    const { t } = useTranslation();
    const { clearHighlights, highlightSentences } = useHighlights();

    const setSelectedMessageId = useSetRecoilState(selectedMessageBubbleId);


    const currentlySelectedArticle = useMemo(() =>
        selectedArticles.find((article) => article.id === currentOpenArticleId),
        [currentOpenArticleId, selectedArticles]
    );

    const hasCurrentArticleProcessedSuccessfully = currentlySelectedArticle && hasFileProcessedSuccessfully(currentlySelectedArticle);

    useEffect(() => {
        if (currentlySelectedArticle) {
            addNeededFilesToPoller(currentlySelectedArticle);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentlySelectedArticle]);

    const fileOnSelect = async () => {
        setLoadingText(t('global-getting-article-label'));
        setAppLoadingState(true);
        try {
            const html = await FileService.downloadHtmlFile(currentOpenArticleId);
            setHtmlContent(html);

            setTimeout(() => {
                const targetSentence = document.querySelector(`div[id="page-container"]`);
                if (targetSentence instanceof HTMLElement) {
                    targetSentence.style.backgroundColor = `#ededed`;
                    targetSentence.style.backgroundImage = 'none';
                } else {
                    console.error('"targetSentence" not found.');
                }

                const articlePages = document.querySelectorAll('[id^="pf"]');

                articlePages.forEach(page => {
                    if (page instanceof HTMLElement) {
                        page.style.transform = `scale(1)`;
                        page.style.margin = `25px auto`;
                        page.style.boxShadow = `none`;
                        page.style.border = `1px solid #d6d6d6`;
                        page.style.borderRadius = `10px`;
                    } else {
                        console.error('"page" not found.');
                    }
                })

                const articleFirstPage = document.querySelector(`div[id="pf1"]`);
                if (articleFirstPage instanceof HTMLElement) {
                    articleFirstPage.style.margin = `25px auto 25px auto`;
                } else {
                    console.log('"articleFirstPage" not found.');
                }
            }, 100)

            setTimeout(() => {
                const targetSentence = document.querySelector(`div[id="page-container"]`);
                if (targetSentence instanceof HTMLElement) {
                    targetSentence.style.transform = `scale(1)`;
                } else {
                    console.error('"targetSentence" not found.');
                }
            }, 100)

            setAppLoadingState(false);
        } catch (err) {
            console.log('Fetching article error: ', err);
            setAppLoadingState(false);
        }
    }
    useEffect(() => {
        if (currentOpenArticleId !== '') {
            fileOnSelect();
        }
    }, [currentOpenArticleId])

    // Needed for if the file in selectedArticles changes, need to re-fetch the article
    useEffect(() => {
        if (selectedArticles.find(articles => articles.id === currentOpenArticleId)) {
            fileOnSelect();
        }
    }, [currentOpenArticleId, selectedArticles]);

    const handleMouseUp = () => {
        setHasCompleteSelection(true);
        const selection = window.getSelection();
        if (selection && selection.rangeCount > 0) {
            const selectedText = selection.toString();
            const range = selection.getRangeAt(0);
            const boundingRect = range.getBoundingClientRect();
    
            // Function to collect unique s_index values within the range
            const collectSIndexInRange = (range) => {
                const sIndexSet = new Set<number>();
                
                const treeWalker = document.createTreeWalker(
                    range.commonAncestorContainer,
                    NodeFilter.SHOW_ELEMENT,
                    {
                        acceptNode: (node) => {
                            const nodeRange = document.createRange();
                            nodeRange.selectNode(node);
                            return range.intersectsNode(node) ? NodeFilter.FILTER_ACCEPT : NodeFilter.FILTER_REJECT;
                        },
                    }
                );
    
                let currentNode = treeWalker.currentNode;
                while (currentNode) {
                    if (
                        currentNode.nodeType === Node.ELEMENT_NODE &&
                        (currentNode as Element).hasAttribute('s_index')
                    ) {
                        const sIndexValue = parseInt((currentNode as Element).getAttribute('s_index'), 10);
                        if (!isNaN(sIndexValue)) {
                            sIndexSet.add(sIndexValue);
                        }
                    }
                    currentNode = treeWalker.nextNode();
                }
    
                // Also check the start and end containers themselves
                const checkNode = (node) => {
                    if (node.nodeType === Node.ELEMENT_NODE && (node as Element).hasAttribute('s_index')) {
                        const sIndexValue = parseInt((node as Element).getAttribute('s_index'), 10);
                        if (!isNaN(sIndexValue)) {
                            sIndexSet.add(sIndexValue);
                        }
                    }
                };
                checkNode(range.startContainer.parentElement);
                checkNode(range.endContainer.parentElement);
    
                return Array.from(sIndexSet).sort((a, b) => a - b);
            };
    
            const uniqueSIndexArray = collectSIndexInRange(range);
    
            setFloatingDivPosition({
                top: boundingRect.top,
                left: boundingRect.left,
            });
    
            setSelectedContext(selectedText);
            setSelectionSentenceIndexes(uniqueSIndexArray);
        }
    };    

    useEffect(() => {
        const allPages = document.querySelectorAll('[id^="pf"]');
        const firstPage = document.querySelector(`div[id="pf1"]`);

        switch (zoomLevel) {
            case -3:
                allPages.forEach(page => {
                    if (page instanceof HTMLElement) {
                        page.style.transform = `scale(0.8)`;
                        page.style.margin = `-150px auto`;
                        page.style.boxShadow = `none`;
                        page.style.border = `1px solid #d6d6d6`;
                        page.style.borderRadius = `10px`;
                    } else {
                        console.log('"page" not found.');
                    }
                });

                if (firstPage instanceof HTMLElement) {
                    firstPage.style.margin = `-70px auto -150px auto`;
                } else {
                    console.log('"articleFirstPage" not found.');
                }
                break;
            case -2:
                allPages.forEach(page => {
                    if (page instanceof HTMLElement) {
                        page.style.transform = `scale(1)`;
                        page.style.margin = `25px auto`;
                        page.style.boxShadow = `none`;
                        page.style.border = `1px solid #d6d6d6`;
                        page.style.borderRadius = `10px`;
                    } else {
                        console.log('"page" not found.');
                    }
                });

                if (firstPage instanceof HTMLElement) {
                    firstPage.style.margin = `25px auto 25px auto`;
                } else {
                    console.log('"articleFirstPage" not found.');
                }
                break;
            case -1:
                allPages.forEach(page => {
                    if (page instanceof HTMLElement) {
                        page.style.transform = `scale(1.2)`;
                        page.style.margin = `190px auto`;
                        page.style.boxShadow = `none`;
                        page.style.border = `1px solid #d6d6d6`;
                        page.style.borderRadius = `10px`;
                    } else {
                        console.log('"page" not found.');
                    }
                });

                if (firstPage instanceof HTMLElement) {
                    firstPage.style.margin = `105px auto 190px auto`;
                } else {
                    console.log('"articleFirstPage" not found.');
                }
                break;
            case 0:
                allPages.forEach(page => {
                    if (page instanceof HTMLElement) {
                        page.style.transform = `scale(1.5)`;
                        page.style.margin = `450px auto`;
                        page.style.boxShadow = `none`;
                        page.style.border = `1px solid #d6d6d6`;
                        page.style.borderRadius = `10px`;
                    } else {
                        console.log('"page" not found.');
                    }
                });

                if (firstPage instanceof HTMLElement) {
                    firstPage.style.margin = `240px auto 450px auto`;
                } else {
                    console.log('"articleFirstPage" not found.');
                }
                break;
            case 1:
                allPages.forEach(page => {
                    if (page instanceof HTMLElement) {
                        page.style.transform = `scale(1.7)`;
                        page.style.margin = `630px auto`;
                        page.style.boxShadow = `none`;
                        page.style.border = `1px solid #d6d6d6`;
                        page.style.borderRadius = `10px`;
                    } else {
                        console.log('"page" not found.');
                    }
                });

                if (firstPage instanceof HTMLElement) {
                    firstPage.style.margin = `330px auto 630px auto`;
                } else {
                    console.log('"articleFirstPage" not found.');
                }
                break;
            case 2:
                allPages.forEach(page => {
                    if (page instanceof HTMLElement) {
                        page.style.transform = `scale(2)`;
                        page.style.margin = `900px auto`;
                        page.style.boxShadow = `none`;
                        page.style.border = `1px solid #d6d6d6`;
                        page.style.borderRadius = `10px`;
                    } else {
                        console.log('"page" not found.');
                    }
                });

                if (firstPage instanceof HTMLElement) {
                    firstPage.style.margin = `500px auto 900px auto`;
                } else {
                    console.log('"articleFirstPage" not found.');
                }
                break;
            default:
                allPages.forEach(page => {
                    if (page instanceof HTMLElement) {
                        page.style.transform = `scale(1.5)`;
                        page.style.margin = `450px auto`;
                        page.style.boxShadow = `none`;
                        page.style.border = `1px solid #d6d6d6`;
                        page.style.borderRadius = `10px`;
                    } else {
                        console.log('"page" not found.');
                    }
                });

                if (firstPage instanceof HTMLElement) {
                    firstPage.style.margin = `240px auto 450px auto`;
                } else {
                    console.log('"articleFirstPage" not found.');
                }
                break;
        }
    }, [zoomLevel])

    // useEffect(() => {
    //     // Handle updating the articleSentenceRank when the currentOpenArticleId changes

    //     // async function fetchArticleSentenceRank() {
    //     //     try {
    //     //         const response = await FileService.getSentenceRank(currentOpenArticleId);
    //     //         // setArticleSentenceRank(response);
    //     //     } catch (error) {
    //     //         new ClientError(error).toast();
    //     //     }
    //     // }

    //     if (!hasCurrentArticleProcessedSuccessfully) {
    //         // setArticleSentenceRank([]);
    //     } else {
    //         fetchArticleSentenceRank();
    //     }
    // }, [currentOpenArticleId, hasCurrentArticleProcessedSuccessfully]);


    //Deep linkning
    useEffect(() => {
        const updateUrl = (articleId) => {

            const url = new URL(window.location.href);

            url.searchParams.set('id', articleId);

            window.history.pushState({ path: url.toString() }, '', url.toString());
        };

        if (currentOpenArticleId) {
            updateUrl(currentOpenArticleId);
        }

    }, [currentOpenArticleId]);


    useEffect(() => {
        async function getAndSetArticle(articleId) {
            const fileDetails = await FileService.getFilesDetails([articleId]);

            if (fileDetails.find((file) => file.id === articleId)) {
                setCurrentOpenArticleId(articleId);
                setSelectedArticles(fileDetails);
            } else {
                new ClientError(
                    new Error('Article was not found or you do not have permission to access it.')
                ).toast();
            }
        }

        const getArticleIdFromUrl = () => {
            const params = new URLSearchParams(window.location.search);
            return params.get('id');
        };

        const urlArticleId = getArticleIdFromUrl();

        if (urlArticleId) {
            getAndSetArticle(urlArticleId);
        } 
    }, [setCurrentOpenArticleId, setSelectedArticles]);

    
    useEffect(() => {
        const checkReadingListStatus = async (urlArticleId) => {
            try {
                const result = await UserFileDataService.checkReadingListStatus(urlArticleId);
                setIsInReadingList(result);
            } catch (error) {
                new ClientError(error).toast();
            }
        }

        if (currentOpenArticleId !== '') {
            checkReadingListStatus(currentOpenArticleId);
        }
    }, [currentOpenArticleId])

    // We will have +-5 levels zoom in/out
    const zoomChange = (val: number) => {
        const newZoomLevel = zoomLevel + val;
        const boundedZoomLevel = Math.max(-3, Math.min(newZoomLevel, 2));
        setZoomLevel(boundedZoomLevel);
    }

    const fullScreen = () => {
        setFullScreenMode(true);
    }

    // const toggleScreenshot = () => {
    //     setScreenshotEnable(true);
    //     if (currentOpenArticleId !== '') {
    //         //fileOnSelect();
    //     }
    // }

    // const translateOnClick = (lang: string) => {
    //     try {
    //         TranslateService.translateFromString(selectionContext as '', lang);
    //     } catch (err) {
    //         console.log(err);
    //     }
    // }

    // const translateAll = async (event: React.ChangeEvent<HTMLSelectElement>) => {
    //     setTranslatedLang(event.target.value);
    //     if (event.target.value === 'en') {
    //         try {
    //             const html = await FileService.downloadHtmlFile(currentOpenArticleId);
    //             setHtmlContent(html);
    //             setTimeout(() => {
    //                 const targetSentence = document.querySelector(`div[id="page-container"]`);
    //                 if (targetSentence instanceof HTMLElement) {
    //                     targetSentence.style.transform = `scale(1)`;
    //                 } else {
    //                     console.error('"targetSentence" not found.');
    //                 }
    //             }, 100)
    //         } catch (err) {
    //             console.log('Fetching article error: ', err);
    //         }
    //     } else {
    //         try {
    //             const html = await FileService.downloadCNHtmlFile(currentOpenArticleId);
    //             setHtmlContent(html);
    //             setTimeout(() => {
    //                 const targetSentence = document.querySelector(`div[id="page-container"]`);
    //                 if (targetSentence instanceof HTMLElement) {
    //                     targetSentence.style.transform = `scale(1)`;
    //                 } else {
    //                     console.error('"targetSentence" not found.');
    //                 }
    //             }, 100)
    //         } catch (err) {
    //             console.log('Fetching article error: ', err);
    //         }
    //     }
    // };

    // const exitTranslationMode = () => {
    //     setIsTranslated(false);
    //     setTranslatedLang('en');
    //     const fileOnSelect = async () => {
    //         try {
    //             const html = await FileService.downloadHtmlFile(currentOpenArticleId);
    //             setHtmlContent(html);
    //             setTimeout(() => {
    //                 const targetSentence = document.querySelector(`div[id="page-container"]`);
    //                 if (targetSentence instanceof HTMLElement) {
    //                     targetSentence.style.transform = `scale(1)`;
    //                 } else {
    //                     console.error('"targetSentence" not found.');
    //                 }
    //             }, 100)
    //         } catch (err) {
    //             console.log('Fetching article error: ', err);
    //         }
    //     }
    //     if (currentOpenArticleId !== '') {
    //         fileOnSelect();
    //     }
    // }

    const toggleReadingList = async (fileId: string) => {
        const originalIsInReadingList = isInReadingList;
        try {
            setIsInReadingList(!isInReadingList);
            await UserFileDataService.toggleReadingList(fileId);
        } catch (error) {
            setIsInReadingList(originalIsInReadingList);
            new ClientError(error).toast();
        }
    }

    const contextOnSelection = () => {
        clearHighlights(HighlightType.Quotation);
        highlightSentences({
            [currentOpenArticleId]: selectionSentenceIndexes
        }, HighlightType.Quotation, true)
        setHasCompleteSelection(false);
        setQuoteSentenceIndexes(selectionSentenceIndexes);
    }

    const clearAllHighlights = () => {
        clearHighlights(HighlightType.Quotation);
        clearHighlights(HighlightType.TaskQA);
        clearHighlights(HighlightType.TaskReading);
        setSelectedMessageId('');
    }

    return (
        <>
            <Flex w={'100%'} h={'100%'} flexDir={'row'} >
                <Box flex={'1 auto'} pt={5} borderRight={'1px solid #ccc'} pos={'relative'}>
                    { currentOpenArticleId !== '' && <Flex flexDir={'column'} w='100%' h={'100%'}>
                        <Flex flexDir={'row'} justifyContent={'space-between'} alignItems={'center'} borderBottom={'1px solid #ccc'} h={'50px'} >
                            <Box flex={'1 auto'} ml={5}>
                                <Flex flexDir={'row'} alignItems={'center'}>
                                    <Box mr={2}>
                                        <Image
                                            w={'120px'}
                                            objectFit='cover'
                                            src='/static/doenba-logo.png'
                                            alt='Dooyeed'
                                        />
                                    </Box>
                                    {currentOpenArticleId !== '' && containsFeatures(currentlySelectedArticle, ArticleFeature.TopicHighlight) &&
                                        <TopicSearch
                                            fileId={currentOpenArticleId}
                                            isDisabled={!hasCurrentArticleProcessedSuccessfully}
                                        />
                                    }
                                </Flex>
                            </Box>
                            <Box mr={5}>
                                {currentOpenArticleId !== '' &&
                                    <Flex flexDir={'row'} alignItems={'center'}>
                                        <Tooltip label={`${isInReadingList ? t('remove-from') : t('add-to')} ${t('my-reading-list-postfix')}`}>
                                            <IconButton border={'none'} borderRadius={0} size={'sm'} variant='outline' onClick={() => toggleReadingList(currentOpenArticleId)} aria-label='Favorite' icon={isInReadingList ? <MdFavorite /> : <MdFavoriteBorder />} />
                                        </Tooltip>
                                        <Tooltip label={t('focus-mode')}>
                                            <IconButton border={'none'} borderRadius={0} size={'sm'} variant='outline' onClick={fullScreen} aria-label='zoom out' icon={<AiOutlineFullscreen />} />
                                        </Tooltip>
                                        <Tooltip label={t('zoom-in')}>
                                            <IconButton color={zoomLevel == 2 ? '#e9e9e9' : 'gray'} border={'none'} borderRadius={0} size={'sm'} variant='outline' onClick={() => { zoomChange(1) }} aria-label='zoom in' icon={<LuZoomIn />} />
                                        </Tooltip>
                                        {/* <Tooltip label='Zoom Reset'>
                                                <IconButton color={zoomLevel == 1 ? '#e9e9e9' : 'gray'} border={'none'} borderRadius={0} size={'sm'} variant='outline' onClick={() => { setZoomLevel(0) }} aria-label='zoom reset' icon={<TbZoomReplace />} />
                                            </Tooltip> */}
                                        <Tooltip label={t('zoom-out')}>
                                            <IconButton color={zoomLevel == -3 ? '#e9e9e9' : 'gray'} border={'none'} borderRadius={0} size={'sm'} variant='outline' onClick={() => { zoomChange(-1) }} aria-label='zoom out' icon={<LuZoomOut />} />
                                        </Tooltip>
                                        <Tooltip label={t('clear-highlights')}>
                                            <IconButton border={'none'} borderRadius={0} size={'sm'} variant='outline' onClick={clearAllHighlights} aria-label='clear highlights' icon={<TbEraserOff />} />
                                        </Tooltip>
                                        {/* <Tooltip label='Math f(x) explaination'>
                                                <IconButton border={'none'} borderRadius={0} size={'sm'} variant='outline' onClick={toggleScreenshot} aria-label='Math' icon={<TbMathFunction />} />
                                            </Tooltip> */}
                                        {/* <Select border={'none'} size={'xs'} onChange={translateAll} value={translatedLang}>
                                                <option value='en'>English</option>
                                                <option value='cn'>Chinese</option>
                                            </Select> */}
                                    </Flex>
                                }
                            </Box>
                            </Flex>
                            { currentOpenArticleId !== '' && 
                                <Box position={'relative'} w={'100%'} h={'100%'}>
                                    {/* {isTranslated &&
                                            <Box position={'absolute'} top={'10px'} right={'30px'} zIndex={100}>
                                                <Button size={'sm'} onClick={exitTranslationMode}>back to original</Button>
                                            </Box>
                                        } */}
                                    <Box overflow={'auto'}>
                                        {selectedArticles.length > 0 && <Box dangerouslySetInnerHTML={{ __html: htmlContent }} height="100%" width="100%" onMouseUp={handleMouseUp} />}
                                    </Box>
                                </Box>
                            }
                        </Flex >
                    }
                    { currentOpenArticleId === '' &&
                        <FeatureWrapper />
                    }
                </Box >
                <Box flex={'1 auto'} w={'50%'} maxW={'50%'} h={'100%'} overflow={'hidden'} pt={5} pl={5} pr={5}>
                    <Flex flexDir={'column'}>
                        <MiniNav />
                        <Box>
                            <Outlet />
                        </Box>
                    </Flex>
                </Box>
            </Flex >

            {/* <CustomModal
                showModalButtonText="Edit"
                modalBody="Edit Modal"
            /> */}
            <Modal onClose={() => setFullScreenMode(false)} size={'full'} isOpen={fullScreenMode} scrollBehavior={'inside'}>
                <ModalOverlay />
                <ModalContent overflow={'hidden'} pt={12} >
                    <ModalCloseButton zIndex={99} />
                    <ModalBody transform={'scale(1.2)'} mt={12} >
                        {selectedArticles.length > 0 && <Box dangerouslySetInnerHTML={{ __html: htmlContent }} height="100%" width="100%" />}
                    </ModalBody>
                </ModalContent>
            </Modal>

            { hasCompleteSelection && selectionContext && floatingDivPosition
              && containsFeatures(currentlySelectedArticle, ArticleFeature.QAHighlight) 
              && (
                    // <Box
                    //     className="floating-div"
                    //     style={{
                    //         position: 'absolute',
                    //         top: floatingDivPosition.top + window.scrollY - 30, // Adjust the top position as needed
                    //         left: floatingDivPosition.left,
                    //         backgroundColor: 'rgba(255, 255, 255, 0.9)',
                    //         border: '1px solid #ccc',
                    //         padding: '5px',
                    //         zIndex: 9999,
                    //     }}
                    // >
                    //     <Menu isLazy>
                    //         <MenuButton>
                    //             {t('more-options-label')}
                    //         </MenuButton>
                    //         <MenuList>
                    //             <MenuItem onClick={() => NotesService.addNotes(selectionContext as '')}>Add to notes</MenuItem>
                    //             <MenuItem onClick={() => translateOnClick('Chinese')}>Translate to Chinese</MenuItem>
                    //             <MenuItem onClick={() => translateOnClick('English')}>Translate to English</MenuItem>
                    //             <MenuItem onClick={contextOnSelection}>{t('quotation-label')}</MenuItem>
                    //         </MenuList>
                    //     </Menu>
                    // </Box>
                    <Box className="floating-div"
                    style={{
                        position: 'absolute',
                        top: floatingDivPosition.top + window.scrollY - 30, // Adjust the top position as needed
                        left: floatingDivPosition.left,
                        backgroundColor: 'yellow.400',
                        zIndex: 9999,
                    }}>
                        <Button onClick={contextOnSelection}
                            colorScheme='yellow' 
                            size='xs' 
                            leftIcon={<FaQuoteLeft fontSize={8}/>}>
                            {t('quotation-label')}
                        </Button>
                    </Box>
                )
            }
        </>
    )
}

export default OracleReaderComponent;
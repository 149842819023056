import { HamburgerIcon } from '@chakra-ui/icons';
import { Box, Checkbox, Flex, Image, IconButton, Menu, MenuButton, MenuItem, MenuList, Tag } from '@chakra-ui/react';
import React from 'react';
import { BibContent } from '../../types/Writer/BibContent';

type CitationItemRowProps = {
    resource: BibContent,
    count: number,
    resourceOnDelete: () => void,
    resourceOnSelection: (resource: BibContent) => void
}

const CitationItemRow: React.FC<CitationItemRowProps> = ({ resource, count, resourceOnDelete, resourceOnSelection }) => {
    return (
        <Flex flexDir={'row'} justifyContent={'space-between'} py={2}>
            <Flex flexDir={'row'} alignItems={'center'}>
                <Checkbox style={{
                    textDecoration: 'underline',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    width: '260px',
                }} onChange={() => resourceOnSelection(resource)} mr={2}>
                    {resource.json_info.title}
                </Checkbox>
                <Tag size={'sm'} variant={'outline'} colorScheme='brand'>{count}</Tag>
            </Flex>
            <Box>
                <Menu>
                    <MenuButton
                        as={IconButton}
                        aria-label='Options'
                        icon={<HamburgerIcon />}
                        variant='outline'
                        size={'xs'}
                    />
                    <MenuList w={'113px'}>
                        {/* <MenuItem icon={
                            <Image w="12px" src="/editor/citation_rename.svg" alt="Refer button" />
                        }>
                            Rename
                        </MenuItem> */}
                        <MenuItem 
                            onClick={resourceOnDelete}
                            icon={<Image w="12px" src="/static/editor/citation_delete.svg" alt="Refer button" />}
                        >
                            Delete
                        </MenuItem>
                    </MenuList>
                </Menu>
            </Box>
        </Flex>
    )
}

export default CitationItemRow;

import {
  Flex,
  Box,
  Card,
  CardHeader,
  Heading,
  CardBody,
  Image,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  IconButton,
} from "@chakra-ui/react";
import PaperEditorConversationPanel from "../components/PaperEditorConversationPanel.tsx/PaperEditorConversationPanel";
import { OutlineNode, PaperOutline } from "../types/Writer/OutlineNode";
import CrossReferencePanel from "../components/PaperEditorComponents/CrossReferencePanel";
import CitationPanel from "../components/PaperEditorComponents/CitationPanel";
import { MinusIcon } from "@chakra-ui/icons";
import { useState } from "react";
import { Function1 } from "lodash";
import { useRecoilValue } from "recoil";
import { selectedOutlineNodeId } from "../atoms/rootAtom";

type EditorModelRightProps = {
  outline: PaperOutline;
  onAddLabel: Function1<OutlineNode[], void>;
}

const EditorModelRight: React.FC<EditorModelRightProps> = ({ outline }) => {
  const [isLibraryOpen, setIsLibraryOpen] = useState<boolean>(true);
  const selectedNodeId = useRecoilValue(selectedOutlineNodeId);

  const togglePanelCollapse = () => {
    setIsLibraryOpen(!isLibraryOpen);
  };

  return (
    <Flex
      flexDir={"column"}
      w={"425px"}
      h={"100%"}
      position={"relative"}
      overflow={"hidden"}
      p={5}
      transition={"all 0.5s ease"}
    >
      <Flex flexDir={"row"} justifyContent={"flex-end"}>
        <Box pl="2" mr="2"></Box>
      </Flex>
      <Card
        mt={2}
        mb={2}
        bg={"#f9f9f9"}
        border={"1px solid #ccc"}
        borderRadius={"25px"}
        h={isLibraryOpen ? "320px" : "60px"}
        transition={"all 0.5s ease"}
      >
        <CardHeader px={4} pt={3} pb={2}>
          <Flex flexDir={"row"} justifyContent={"space-between"}>
            <Flex flexDir={"row"} alignItems={"center"}>
              <Image
                w={"20px"}
                src="/static/editor/note-2.svg"
                alt="Doenba"
                mr={2}
              />
              <Heading size="md">Library</Heading>
            </Flex>
            <Box>
              <IconButton
                onClick={togglePanelCollapse}
                aria-label="library-collapse"
                color={"#8391BC"}
                icon={<MinusIcon />}
                size={"sm"}
                variant={"ghost"}
              />
            </Box>
          </Flex>
        </CardHeader>
        <CardBody overflow={"auto"} maxH={"35vh"} px={3} py={0}>
          <Tabs variant="soft-rounded" bg={"brand"} size={"xs"} mt={2}>
            <TabList border={"1px solid #ccc"} borderRadius={"10px"} p={"2px"}>
              <Tab
                fontSize={"16px"}
                w="50%"
                color={"black"}
                borderRadius={"10px"}
              >
                Cross Reference
              </Tab>
              <Tab
                fontSize={"16px"}
                w="50%"
                color={"black"}
                borderRadius={"10px"}
              >
                Citation
              </Tab>
            </TabList>
            <TabPanels>
              <TabPanel py={1} px={2}>
                <CrossReferencePanel outline={outline}/>
              </TabPanel>
              <TabPanel py={1}>
                <CitationPanel />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </CardBody>
      </Card>
      <Card
        bg={"#f9f9f9"}
        border={"1px solid #ccc"}
        borderRadius={"25px"}
        h={isLibraryOpen ? "calc(100% - 330px)" : "calc(100% - 70px)"}
        transition={"all 0.5s ease"}
      >
        <CardBody overflow={'hidden'}>
          <PaperEditorConversationPanel
            selectedNodeId={selectedNodeId}
            setSelectedNodeId={undefined}
            outlineNodes={undefined}
          />
        </CardBody>
      </Card>
    </Flex>
  );
};
export default EditorModelRight;



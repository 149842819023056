import { AddIcon } from '@chakra-ui/icons';
import { Box, Button, Flex, Modal, ModalBody, ModalCloseButton, ModalContent, ModalOverlay, useDisclosure } from '@chakra-ui/react';
import React, { useRef, useState } from 'react';
import { FaQuoteLeft } from 'react-icons/fa';
import CitationItemRow from './CitationItemRow';
import ResourcesUploadCard from './ResourcesUploadCard';
import { PaperService } from '../../services/paperService';
import { useRecoilState, useRecoilValue } from 'recoil';
import { citationCount, paperResources } from '../../atoms/rootAtom';
import { BibContent } from '../../types/Writer/BibContent';

const CitationPanel: React.FC = () => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [isResourcesUploading, setIsResourcesUploading] = useState<boolean>(false);
    const inputResourcesRef = useRef<HTMLInputElement | null>(null);
    const [res, setRes] = useRecoilState(paperResources);
    const [selectedResource, setSelectedResource] = useState<BibContent[]>([]);
    const citationsCount = useRecoilValue(citationCount);

    const removeResource = (index: number) => {
        setRes(prevResources => {
            return prevResources.filter((_, i) => i !== index);
        });
    };

    const resourcesOnClick = () => {
        if (inputResourcesRef.current) {
            inputResourcesRef.current.click();
        }
    };

    const handleResourcesUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file && file.type === 'text/plain') {
            setIsResourcesUploading(true);
            const reader = new FileReader();
            reader.onload = (e: ProgressEvent<FileReader>) => {
                const content = e.target?.result;
                if (typeof content === 'string') {
                    PaperService.extractBibContent(content).then(
                        results => {
                            setRes([...res, ...results]);
                            setIsResourcesUploading(false)
                        }
                    )
                }
            };
            reader.readAsText(file);
        } else {
            alert('Please upload a valid .txt file');
        }
    };
    
    const addResources = () => {
        onOpen();
    }

    const resourceOnSelection = (resource: BibContent) => {
        setSelectedResource((prevSelected) => {
            const exists = prevSelected.some((ref) => ref === resource);
            if (exists) {
                return prevSelected.filter((ref) => ref !== resource);
            } else {
                return [...prevSelected, resource];
            }
        });
    }

    const citeResource = () => {
        const selection = window.getSelection();
        if (!selection || selection.rangeCount === 0) return;

        const range = selection.getRangeAt(0);
        range.deleteContents();

        selectedResource.forEach((ref) => {
            const span = document.createElement("span");
            span.textContent = `<cite id="${ref.id}" />`;
            range.insertNode(span);
            const newRange = document.createRange();
            newRange.setStartAfter(span);
            newRange.collapse(true);
            selection.removeAllRanges();
            selection.addRange(newRange);
        });

        const activeElement = document.activeElement;
        if (activeElement && activeElement instanceof HTMLElement) {
            activeElement.focus();
        }

        
    }

    const getCitationCount = (id: string) => {
        const targetCitation = citationsCount.find(citation => citation.id == id);
        return targetCitation.count;
    }

    return (
        <Flex flexDir={'column'}>
            <Flex flexDir={'column'} overflow={'scroll'} h={'150px'} css={{
                "&::-webkit-scrollbar": {
                    width: "4px",
                },
                "&::-webkit-scrollbar-thumb": {
                    background: "#9EC3E8",
                    borderRadius: "2px",
                },
                "&::-webkit-scrollbar-thumb:hover": {
                    background: "#2b6cb0",
                }
            }}>
                {res.map((ref, index) => (
                    <CitationItemRow 
                        key={ref.json_info.title + index} 
                        resource={ref}
                        count={getCitationCount(ref.id)}
                        resourceOnSelection={resourceOnSelection} 
                        resourceOnDelete={() => removeResource(index)} />
                ))}
            </Flex>
            <Flex flexDir={'row'} justifyContent={'space-between'} pt={1} pb={2}>
                <Box>
                    <Button w={'99px'} h={'30px'}
                        size={"xs"}
                        variant={'outline'}
                        leftIcon={
                            <FaQuoteLeft />
                        }
                        onClick={citeResource}
                    >
                        Cite
                    </Button>
                </Box>
                <Box>
                    <Button w={'99px'} h={'30px'}
                        size={"sm"}
                        variant={'solid'}
                        colorScheme='brand'
                        leftIcon={
                            <AddIcon />
                        }
                        onClick={addResources}
                    >
                        Add
                    </Button>
                </Box>
            </Flex>

            <Modal isOpen={isOpen} onClose={onClose} size={'lg'} closeOnOverlayClick={false}>
                <ModalOverlay bg={'whiteAlpha.700'} />
                <ModalContent minW={'730px'} bg={'#fbfcff'} top={'20%'}>
                    <ModalCloseButton />
                    <ModalBody p={8} bg={'#FBFCFF'}>
                        <ResourcesUploadCard
                            handleFileUploadInputElementForResourcesOnClick={resourcesOnClick}
                            resources={res}
                            isResourcesUploading={isResourcesUploading}
                            inputResourcesRef={inputResourcesRef}
                            handleResourcesUpload={handleResourcesUpload}
                            removeResource={removeResource}
                        />
                        <input
                            ref={inputResourcesRef}
                            type="file"
                            onChange={handleResourcesUpload}
                            style={{ display: "none" }}
                        />
                    </ModalBody>
                </ModalContent>
            </Modal>
        </Flex>
    )
}

export default CitationPanel;



import axios from "axios";
import { BASE_URL } from ".";
import {
	UpdatePaperRequest,
	CreatePaperRequest,
	Paper,
	SectionSuggestionRequest,
	RephraseRequest,
} from "../types/Writer/Paper";
import {
	ReorganizedStructureNode,
	ReorganizeSectionResult
} from "../types/Writer/ReorganizeSectionResult";
import { PaperOutline, PaperOutlineNode } from "../types/Writer/OutlineNode";
import { RephraseSentencesResult } from "../types/Writer/RephraseSentencesResult";
import { RewriteSectionResult } from "../types/Writer/RewriteSectionResult";
import { BibContent } from "../types/Writer/BibContent";
import { PaperNodeSuggestion, PaperSuggestions } from "../types/Writer/PaperNodeSuggestion";
export class PaperService {
    public static async getPapers() {
		const response = await axios.get<Paper[]>(BASE_URL + "/paper");
		return response.data;
	}

	public static async getPaperById(paperId: string) {
		const response = await axios.get<Paper>(BASE_URL + "/paper/" + paperId);
		return response.data;
	}

	public static async createPaper(data: CreatePaperRequest) {
		const response = await axios.post<Paper>(BASE_URL + "/paper", data);
		return response.data;
	}

	public static async updatePaper(paperId: string, newPaper: UpdatePaperRequest) {
		const response = await axios.put<Paper>(BASE_URL + "/paper/" + paperId, newPaper);
		return response.data;
	}

	public static async deletePaper(paperId: string): Promise<void> {
		await axios.delete(BASE_URL + "/paper/" + paperId);
	}

	public static async rephraseSentences(data: RephraseRequest) {
		const response = await axios.post<RephraseSentencesResult>(BASE_URL + "/paper/rephrase-sentences", data);
		return response.data;
	}


	public static async sectionSuggestion({ sectionNode, suggestion_data, suggestion_level }: SectionSuggestionRequest) {
		const response = await axios.post<PaperNodeSuggestion>(BASE_URL + "/paper/rescore-suggestion", { sectionNode, suggestion_data,suggestion_level });
		return response.data;
	}

	public static async suggestion(sectionNode: PaperOutlineNode, suggestion_level: string) {
		const response = await axios.post<PaperSuggestions>(BASE_URL + "/paper/suggestion", { sectionNode, suggestion_level });
		return response.data;
	}
	
	public static async rewriteSection(outline: PaperOutline, reorganizedStructure: ReorganizedStructureNode, writingLevel?: string, writingLength?: string, currentIndex?: number) {
		const response = await axios.post<RewriteSectionResult>(BASE_URL + "/paper/rewrite-section", { outline, reorganizedStructure, writingLevel, writingLength, currentIndex });
		return response.data;
	}

	public static async reorganizeSection(sectionNode: PaperOutlineNode, writingLevel?: string, writingLength?: string) {
		const response = await axios.post<ReorganizeSectionResult>(BASE_URL + "/paper/reorganize-section", { sectionNode, writingLevel, writingLength });
		return response.data;
	}

	public static async extractBibContent(bib_content: string) {
		const response = await axios.post<BibContent[]>(BASE_URL + "/paper/extract-bib", { bib_content });
		return response.data;
	}
}
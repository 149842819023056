
export interface SectionData{
  isCalculated:boolean,
  isError:boolean,
  data: any[],
  count:number
}

export interface AIData{
  [key:number]:SectionData[]
}

export enum AIDataType{
  None,
  rephrase,
  suggestion,
  reorganize
}
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App.tsx";
import * as Sentry from "@sentry/react";
import "./index.css";
import "./i18n";
import { BrowserRouter, useNavigate } from "react-router-dom";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import { AppState, Auth0Provider, Auth0ProviderOptions } from "@auth0/auth0-react";
import authConfig from "./config/auth0Config";
import { RecoilRoot } from "recoil";
import Analytics from "analytics";
import googleAnalytics from "@analytics/google-analytics";
import { AnalyticsProvider } from "use-analytics";
import { auth0Plugin } from "./utils/auth0Analytics.ts";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from 'react-dnd-html5-backend';
import { MathJaxContext } from 'better-react-mathjax';

const theme = extendTheme({
	fonts: {
		// heading: `'Oswald', sans-serif`,
		// body: `'Raleway', sans-serif`,
	},
	colors: {
		brand: {
			50: "#ebf8ff",
			100: "#bee3f8",
			200: "#90cdf4",
			300: "#1d8aff",
			400: "#1d8aff",
			500: "#1d8aff",
			600: "#2b6cb0",
			700: "#2c5282",
			800: "#2a4365",
			900: "#1A365D"
		  }
	}
});

const analytics = Analytics({
	plugins: [
		auth0Plugin,
		googleAnalytics({
			measurementIds: [process.env.GOOGLE_ANALYTICS_ID],
		}),
	],
});

const sentryDsn = import.meta.env.MODE !== 'localhost' ? "https://a1e70e3ff496f810579d80b0eb57380b@o4506890398531584.ingest.us.sentry.io/4507535320219648" : undefined;

Sentry.init({
	dsn: sentryDsn,
	integrations: [],
	environment: process.env.SENTRY_ENVIRONMENT,
});

function AppProviderWrapper() {
	const navigate = useNavigate();

	const onRedirectCallback = (appState?: AppState) => {
		navigate(appState?.returnTo ?? window.location.pathname);
	};

	const providerConfig: Auth0ProviderOptions = {
		domain: authConfig.AUTH0_BASE_URL,
		clientId: authConfig.AUTH0_CLIENT_ID,
		onRedirectCallback,
		authorizationParams: {
			redirect_uri: window.location.origin,
			audience: authConfig.AUTH0_AUDIENCE,
			scope: authConfig.AUTH0_SCOPE,
		},
	};

	return (
		<Auth0Provider {...providerConfig}>
			<AnalyticsProvider instance={analytics}>
				<ChakraProvider theme={theme}>
					<RecoilRoot>
						<DndProvider backend={HTML5Backend}>
							<MathJaxContext>
								<App />
							</MathJaxContext>
						</DndProvider>
					</RecoilRoot>
				</ChakraProvider>
			</AnalyticsProvider>
		</Auth0Provider>
	);
}

export default function RootComponent() {
	return (
		<React.StrictMode>
			<BrowserRouter>
				<AppProviderWrapper />
			</BrowserRouter>
		</React.StrictMode>
	);
}

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(<RootComponent />);

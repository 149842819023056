import { OutlineNodeType, PaperOutlineNode } from "./OutlineNode";

const getTreeNodesToList = (
  data: PaperOutlineNode[],
  level: number,
  filter?: OutlineNodeType | undefined
): PaperOutlineNode[] => {
  if (!data) return;
  let nodeList: PaperOutlineNode[] = [];
  for (let i = 0; i < data?.length; i++) {
    const node = data[i];
    if (!node) continue;
    if (filter == undefined || node?.type === filter) {
      node.level = level;
      nodeList.push(node);
    }
    if (node?.children?.length > 0) {
      nodeList = nodeList.concat(getTreeNodesToList(node.children,node.level+1, filter));
    }
  }
  return nodeList;
};

const getNodeRootInList = (
  data: PaperOutlineNode[],
  node: PaperOutlineNode
): PaperOutlineNode => {
  if (!data || !node) return;
  for (let i = 0; i < data.length; i++) {
    if (data[i]?.id == node.id) {
      return data[i];
    }
    const nodeFind = getNodeRootInList(data[i]?.children, node);
    if (nodeFind) {
      return data[i].level == 1 ? data[i] : nodeFind;
    }
  }
  return undefined;
};

const formatString = (template: string, ...args: any[]): string => {
  return template.replace(/{(\d+)}/g, (match, index) => {
    return typeof args[index] !== "undefined" ? args[index] : match;
  });
};
function alignStringLen(text: string, targetLength: number): string {
  if(!text) return
  let length = 0;
  for (let i = 0; i < text.length; i++) {
    if (/[\u4e00-\u9fa5]/.test(text[i])) {
      length += 2;
    } else {
      length += 1;
    }
    if (length >= targetLength - 1) {
      return text.slice(0, targetLength - 1) + "…";
    }
  }
  const spaceCount = Math.floor((targetLength - text.length) / 2);
  return " ".repeat(spaceCount) + text + " ".repeat(spaceCount);
}

interface DragInfo {
  rectTop: number;
  rectButton: number;
}

export {
  getTreeNodesToList as getTreeNodes,
  formatString,
  getNodeRootInList as getNodeRoot,
  alignStringLen,
};
export type { DragInfo };

import {
    Avatar,
    Box,
    Flex,
    Heading,
    IconButton,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Text,
    Tooltip,
    useToast,
} from "@chakra-ui/react";
import { ConversationHistory } from "../../types/Conversation";
import { useAuth0 } from "@auth0/auth0-react";
import { formatDate } from "../../utils/dateTimeConversion";
import ConversationMarkdown from "./ConversationMarkdown";
import { FaHighlighter, FaQuoteLeft, FaRegCopy } from "react-icons/fa";
import { IoIosMore } from "react-icons/io";
import { SlNotebook } from "react-icons/sl";
import useHighlights, { HighlightType } from "../../hooks/useHighlights";
import { DeleteIcon } from "@chakra-ui/icons";
import { useTranslation } from "react-i18next";
import { containsFeatures } from "../../services/articleFeatureService";
import { ArticleFeature } from "../../types/ArticleFeature";
import { FileDetails } from "../../types/FileDetails";
import { NotesService } from "../../services/addContextToNotes";
import { Sentences } from "../../types/Sentence";
import { useMemo } from "react";
// import LatexSupportedText from "../LatexSupportedText";
import { Highlight } from "../../types/Highlight";

interface Props {
    selectedFile: FileDetails;
    message: ConversationHistory;
    isSelected: boolean;
    isQuoteSelected: boolean;
    setSelectedMessageId: (id: string) => void;
    setSelectedQuoteMessageId: (id: string) => void;
    onDeleteMessageClick: (id: string) => void;
    articleSentences: Sentences;
}

const Message = ({
    message,
    isSelected,
    isQuoteSelected,
    setSelectedMessageId,
    setSelectedQuoteMessageId,
    selectedFile,
    onDeleteMessageClick,
    articleSentences,
}: Props) => {
    const { user } = useAuth0();
    const { t } = useTranslation();
    const toast = useToast();
    const { clearHighlights, highlightSentences } = useHighlights();

    const isQaHighlightEnabled = containsFeatures(selectedFile, ArticleFeature.QAHighlight);

    const copyToClipboard = async (sentence: string) => {
        try {
            await navigator.clipboard.writeText(sentence);
            toast({
                title: t("success-label"),
                description: t("copy-successful"),
                status: "success",
                duration: 2000,
            });
        } catch (error) {
            console.error("Failed to copy: ", error);
            toast({
                title: t("failed-label"),
                description: t("copy-failed"),
                status: "error",
                duration: 2000,
            });
        }
    };

    const selectMessageAndHighlight = () => {
        highlightSentences(message.highlight, HighlightType.TaskQA);
    };

    const conversationBubbleOnClick = () => {
        if (!isQaHighlightEnabled) {
            return;
        }

        if (isSelected) {
            clearHighlights(HighlightType.TaskQA);
            setSelectedMessageId("");
        } else {
            selectMessageAndHighlight();
            setSelectedMessageId(message.id);
        }
    };

    const messageQuoteOnClick = () => {
        if (!isQaHighlightEnabled) {
            return;
        }

        if (isQuoteSelected) {
            clearHighlights(HighlightType.Quotation);
            setSelectedQuoteMessageId("");
        } else {
            const highlights: Highlight = {
                [selectedFile.id]: message.quoteSentenceIndexes,
            };
            highlightSentences(highlights, HighlightType.Quotation);
            setSelectedQuoteMessageId(message.id);
        }
    };

    const addNotes = async (note: string) => {
        NotesService.addNotes(note);
    };

    const quoteSentences = useMemo(() => {
        if (message.quoteSentenceIndexes?.length > 0) {
            return message.quoteSentenceIndexes
                .map((index) => articleSentences[index])
                .map((sentence) => sentence?.content)
        }

        return [];
    }, [articleSentences, message.quoteSentenceIndexes]);

    return (
        <Box
            py={message.sender === "user" ? "1" : "2"}
            px={5}
            borderRadius={5}
            bg={isSelected ? "#ebffed" : "null"}
        >
            <Flex flexDir={"row"} alignItems={"flex-start"} w={"full"}>
                <Flex flexDir={"row"} alignItems={"flex-start"}>
                    {message.sender === "user" ? (
                        <Avatar name={user.name} src={user.picture} size={"sm"} mr={2} />
                    ) : (
                        <Avatar name="RA profile" src={"/static/doenba-ico.png"} size={"sm"} mr={2} />
                    )}
                </Flex>
                <Box flex={"1 auto"} w={"95%"}>
                    <Flex
                        flexDir={"row"}
                        justifyContent={"space-between"}
                        alignItems={"center"}
                        mb={2}
                    >
                        <Flex
                            flexDir={"row"}
                            alignItems={"center"}
                            h={"32px"}
                            justifyContent={"space-between"}
                            w={"full"}
                        >
                            <Heading size="sm">
                                {message.sender === "user" ? "You" : "Doenba"}
                            </Heading>
                            <Flex flexDir={'row'} alignItems={'center'}>
                                {message.sender !== 'user' &&
                                    <Text textAlign={"right"} fontSize={"12px"} mr={2} as={'b'}>
                                        {`(Double-click to ${ isSelected ? 'unhighlight' : 'highlight'} the text on the article.)`}
                                    </Text>

                                }
                                <Text textAlign={"right"} color={"gray.500"} fontSize={"12px"}>
                                    {formatDate(message.createdAt)}
                                </Text>
                            </Flex>
                        </Flex>
                    </Flex>
                    <Box
                        onDoubleClick={
                            message.sender !== "user"
                                ? () => conversationBubbleOnClick()
                                : () => { }
                        }
                        _hover={
                            message.sender !== "user"
                                ? {
                                    backgroundColor: "#dff0e1",
                                    cursor: "pointer"
                                }
                                : {}
                        }
                        pl={8}
                        pr={2}
                        mb={4}
                        borderLeft={
                            message.sender == "user" ? "5px solid #f7ba7c" : "5px solid #346136"
                        }
                    >
                        {message.sender === "bot" ? (
                            <ConversationMarkdown content={message.message} />
                        ) : (
                            // <span>{message.message}</span>
                            <ConversationMarkdown content={message.message} />
                        )}
                        {message.messageContext === "inspiration" && (
                            <span>{" " + t("from-doenba-inspiration")}</span>
                        )}
                    </Box>
                    <Flex flexDir={"row"} justifyContent={"space-between"} alignItems={"center"}>
                        <Flex flexDir={"row"} justifyContent={"flex-start"} alignItems={"center"}>
                            {message.sender !== "user" && (
                                <Flex flexDir={"row"} alignItems={"center"}>
                                    <Tooltip label={t("copy")}>
                                        <IconButton
                                            size={"xs"}
                                            color={"gray.500"}
                                            variant="ghost"
                                            icon={<FaRegCopy />}
                                            aria-label="speaker"
                                            onClick={() => copyToClipboard(message.message)}
                                        />
                                    </Tooltip>
                                    {message.sender !== "user" && (
                                        <Box>
                                            <Menu>
                                                <MenuButton
                                                    as={IconButton}
                                                    aria-label="Options"
                                                    icon={<IoIosMore />}
                                                    variant="ghost"
                                                    size={"sm"}
                                                />
                                                <MenuList>
                                                    <MenuItem
                                                        isDisabled
                                                        icon={<SlNotebook fontSize={"16px"} />}
                                                        onClick={() => addNotes(message.message)}
                                                    >
                                                        {t("add-notes")} ({t("coming-soon")})
                                                    </MenuItem>
                                                    {isQaHighlightEnabled && (
                                                        <>
                                                            {!isSelected && (
                                                                <MenuItem
                                                                    icon={
                                                                        <FaHighlighter
                                                                            fontSize={"16px"}
                                                                        />
                                                                    }
                                                                    onClick={() => {
                                                                        selectMessageAndHighlight();
                                                                        setSelectedMessageId(
                                                                            message.id
                                                                        );
                                                                    }}
                                                                >
                                                                    {t("highlight")}
                                                                </MenuItem>
                                                            )}
                                                            {isSelected && (
                                                                <MenuItem
                                                                    icon={
                                                                        <FaHighlighter
                                                                            fontSize={"16px"}
                                                                        />
                                                                    }
                                                                    onClick={() => {
                                                                        clearHighlights(
                                                                            HighlightType.TaskQA
                                                                        );
                                                                        setSelectedMessageId(
                                                                            ""
                                                                        );
                                                                    }}
                                                                >
                                                                    {t("clear-highlight")}
                                                                </MenuItem>
                                                            )}
                                                        </>
                                                    )}
                                                    {!message.isTempMessage && (
                                                        <MenuItem
                                                            icon={<DeleteIcon fontSize={"16px"} />}
                                                            onClick={() =>
                                                                onDeleteMessageClick(message.id)
                                                            }
                                                        >
                                                            {t("delete-conversation-label")}
                                                        </MenuItem>
                                                    )}
                                                </MenuList>
                                            </Menu>
                                        </Box>
                                    )}
                                </Flex>
                            )}
                            {message.sender === "user" && !message.isTempMessage && (
                                <Flex flexDir={"row"} alignItems={"center"}>
                                    <Box>
                                        <Menu>
                                            <MenuButton
                                                as={IconButton}
                                                aria-label="Options"
                                                icon={<IoIosMore />}
                                                variant="ghost"
                                                size={"sm"}
                                            />
                                            <MenuList>
                                                <MenuItem
                                                    icon={<DeleteIcon fontSize={"16px"} />}
                                                    onClick={() => onDeleteMessageClick(message.id)}
                                                >
                                                    {t("delete-conversation-label")}
                                                </MenuItem>
                                            </MenuList>
                                        </Menu>
                                    </Box>
                                </Flex>
                            )}
                        </Flex>
                        {/* <Text textAlign={'right'} color={'gray.500'} fontSize={'12px'}>{formatDate(message.createdAt)}</Text> */}
                    </Flex>
                    {quoteSentences.length > 0 && (
                        <Flex
                            flexDir={'row'}
                            // bg={isQuoteSelected ? 'green.200' : 'gray.100'}
                            bg={'gray.100'}
                            p={2}
                            borderRadius={5}
                            justifyContent={'space-between'}
                            alignItems={'flex-start'}
                            onClick={() => messageQuoteOnClick()}
                            cursor='pointer'
                            role='button'
                        >
                            <Flex flexDir={'row'} flex={'1 auto'}>
                                <Box mr={2}>
                                    <FaQuoteLeft color="gray" width={'15px'} />
                                </Box>
                                <Text
                                    fontSize={'sm'}
                                    fontStyle={'italic'}
                                    color={'gray.500'}
                                    display="-webkit-box"
                                    overflow="hidden"
                                    textOverflow="ellipsis"
                                    style={{
                                        WebkitBoxOrient: "vertical",
                                        WebkitLineClamp: 3,
                                    }}
                                >
                                    {/* <LatexSupportedText>
                                        {quoteSentences.join("")}
                                    </LatexSupportedText> */}
                                    Click here to view your quotation
                                    {/* {isQuoteSelected ? 'Please review your quotation from the article.' : 'Click here to view your quotation.'} */}
                                </Text>
                            </Flex>
                        </Flex>
                    )}
                </Box>
            </Flex>
        </Box>
    );
};

export default Message;

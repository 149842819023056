export function copyToClipboard(text: string): void {
    const textArea = document.createElement('textarea');
    textArea.value = text;
    textArea.style.position = 'absolute';
    textArea.style.left = '-9999px';
    document.body.appendChild(textArea);
    textArea.select();

    try {
        const successful = document.execCommand('copy');
        if (successful) {
            console.log('Text copied to clipboard successfully!');
        } else {
            console.error('Failed to copy text to clipboard.');
        }
    } catch (error) {
        console.error('Error copying text to clipboard:', error);
    }
    
    document.body.removeChild(textArea);
}



import {OutlineNodeType, PaperOutlineNode} from "../../types/Writer/OutlineNode.ts";
import {isEditingSection} from "./useEditorKeyboard.hook.ts";
import {getSelectNodeIndex} from "../../utils/editor.ts";

/**
 * editor related hook
 */
export const useUpdateEditorHook = () => {

  /**
   * edit content update
   */
  const updateContent = (nodeList: PaperOutlineNode[]) => {
    const selection = window.getSelection();
    const range = selection.rangeCount > 0 ? selection.getRangeAt(0) : null;
    let hasUpdate = false
    if (range) {
      const {startContainer} = range;
      const currentSectionEdit = isEditingSection(startContainer, OutlineNodeType.Section)
      const currentParaEdit = isEditingSection(startContainer, OutlineNodeType.Paragraph)

      if (currentSectionEdit?.type === OutlineNodeType.Section) {
        const index = getSelectNodeIndex(nodeList, currentSectionEdit.node)
        if (nodeList[index]) {
          const newTitle = startContainer.textContent
          if (newTitle !== nodeList[index].name) {
            let newLabel = startContainer.textContent?.toLowerCase()
            const sameLen = sameNameNodes(newTitle, nodeList)
            sameLen && (newLabel += `_${sameLen}`)
            nodeList[index].name = newTitle;
            nodeList[index].label = newLabel;
            hasUpdate = true
          }
        }
      }

      if (currentParaEdit?.type === OutlineNodeType.Paragraph) {
        const index = getSelectNodeIndex(nodeList, currentParaEdit.node)
        if (nodeList[index]) {
          const content = startContainer.textContent
          if (content !== nodeList[index].content) {
            nodeList[index].content = content;
            hasUpdate = true
          }
        }
      }

      return hasUpdate
    }
  }


  /**
   * check if the node label is unique
   */
  const sameNameNodes = (name: string, nodeList: PaperOutlineNode[]) => {
    const fileNodes = nodeList.filter((node) => node.name === name)
    let maxNum = 0
    fileNodes.forEach(node => {
      if (node.label) {
        const splits = node.label.split('_')
        const num = parseInt(splits[splits.length - 1])
        if (!isNaN(num) && maxNum < num) {
          maxNum = num
        }
      }
    })
    return fileNodes?.length === 0 ? 0 : (maxNum + 1)
  }
  return {
    updateContent,
    sameNameNodes
  }
}
